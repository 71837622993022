// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Checkbox from "@mui/material/Checkbox"
import IconButton from '@material-ui/core/IconButton'
import Anchor from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconArrowBack from '@material-ui/icons/ArrowBack'
import dayjs from 'dayjs';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import { messageShow } from '../../common/api/actions'
import EmptyMessage from '../../common/EmptyMessage'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { publishToggleArea, removeArea } from '../api/actions/mutation'
import { arealist, detail } from '../api/actions/query'
import routes from '../api/routes'
import styles from './styles'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from "@mui/icons-material/Add";

// Component
class AreaList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pincode: null,
        }
    }

    componentDidMount() {
        this.refresh()
    }

    refresh = () => {
        const { arealist, match: { params: { pincodeId } } } = this.props

        this.getPincode(pincodeId)

        arealist(pincodeId)
    }


    getPincode = async (pincodeId) => {
        const { detail } = this.props

        try {
            const { data } = await detail({ pincodeId })

            if (data.success) {
                this.setState({
                    pincode: data.data
                })
            }
        } catch (error) {
        }
    }


    onDelete = (areaId) => async () => {
        let check = window.confirm('Are you sure you want to delete this area?')

        if (check) {
            const { removeArea, messageShow } = this.props

            try {
                const { data } = await removeArea({ areaId })

                messageShow(data.message)

                if (data.success) {
                    this.refresh()
                }
            } catch (error) {
                messageShow('Some error occurred. Please try again.')
            }
        }
    }


    onPublishToggle = areaId => async event => {
        const isPublished = event.target.checked

        let check = window.confirm(`Are you sure you want to ${isPublished ? 'publish' : 'unpublish'} this area?`)

        if (check) {
            const { publishToggleArea, messageShow } = this.props

            try {
                const { data } = await publishToggleArea({ areaId, isPublished })

                messageShow(data.message)

                if (data.success) {
                    this.refresh()
                }
            } catch (error) {
                messageShow('Some error occurred. Please try again.')
            }
        }
    }

    back = () => {
        const { history } = this.props

        if (history.length > 2) {
            history.goBack()
        } else {
            history.push(routes.pincodeList.path)
        }
    }

    render() {
        const { areas: { isLoading, arealist }, classes } = this.props
        const { match: { params: { pincodeId } } } = this.props
        const { pincode } = this.state

        return (
            <div>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        onClick={this.back}
                    >
                        <IconArrowBack />
                    </IconButton>

                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        Manage Pincode {pincode && `- ${pincode.pincode}`}
                    </Typography>

                    <Link to={routes.areaCreate.path(pincodeId)}>
                      <Tooltip title="Create Pincode" arrow>
                        <Button size='big' color="inherit" startIcon={<AddIcon />}></Button>
                      </Tooltip>
                    </Link>
                </Toolbar>

                <SectionPaper>
                    {
                        isLoading
                            ? <Loading />
                            : arealist.length === 0
                                ? <EmptyMessage message={'You have not added any areas yet.'} />
                                : <Table padding={"normal"}>
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell width={85}>Image</TableCell> */}
                                            <TableCell>Name</TableCell>
                                            <TableCell>Slug</TableCell>
                                            <TableCell width={180}>Updated</TableCell>
                                            <TableCell width={80} align="center">Published</TableCell>
                                            <TableCell width={180} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            arealist.map(({ _id, name, slug, isPublished, updatedAt }) =>
                                                <TableRow key={_id}>
                                                    <TableCell>
                                                        <Link to={routes.areaEdit.path(_id)}>
                                                            <Anchor component="span" style={{ fontWeight: 500 }} >{name.toUpperCase()}</Anchor>
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>{slug}</TableCell>
                                                    <TableCell>{dayjs(updatedAt).format('DD-MM-YYYY, hh:mm A')}</TableCell>
                                                    <TableCell align="center">
                                                        <Checkbox
                                                            checked={isPublished}
                                                            onChange={this.onPublishToggle(_id)}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      <Tooltip title="Edit" arrow>
                                                        <Button color="primary" component={Link} to={routes.areaEdit.path(_id)}><EditIcon /></Button>
                                                      </Tooltip>
                                                      <Tooltip title="Delete" arrow>
                                                        <Button color="error" onClick={this.onDelete(_id)}><DeleteIcon /></Button>
                                                      </Tooltip> 
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                    }
                </SectionPaper>
            </div>
        )
    }
}

// Component Properties
AreaList.propTypes = {
    areas: PropTypes.object.isRequired,
    arealist: PropTypes.func.isRequired,
    detail: PropTypes.func.isRequired,
    removeArea: PropTypes.func.isRequired,
    publishToggleArea: PropTypes.func.isRequired,
    messageShow: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}

// Component State
function areaListState(state) {
    return {
        areas: state.areas
    }
}

export default connect(areaListState, { arealist, detail, removeArea, publishToggleArea, messageShow })(withStyles(styles)(AreaList))
