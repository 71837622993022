// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Checkbox from "@mui/material/Checkbox"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import dayjs from 'dayjs';
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import { messageShow } from '../../common/api/actions'
import EmptyMessage from '../../common/EmptyMessage'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { publishToggle, remove } from '../api/actions/mutation'
import { listParent } from '../api/actions/query'
import routes from '../api/routes'
import styles from './styles'



// Component
class List extends PureComponent {

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    const { listParent } = this.props

    listParent()
  }

  onDelete = (deliveryBoyId) => async () => {
    let check = window.confirm('Are you sure you want to delete this deliveryBoy?')

    if(check) {
      const { remove, messageShow } = this.props

      try {
        const { data } = await remove({ deliveryBoyId })

        messageShow(data.message)

        if(data.success) {
          this.refresh()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  onPublishToggle = deliveryBoyId => async event => {
    const isPublished = event.target.checked

    let check = window.confirm(`Are you sure you want to ${ isPublished ? 'publish' : 'unpublish' } this deliveryBoy?`)

    if(check) {
      const { publishToggle, messageShow } = this.props

      try {
        const { data } = await publishToggle({ deliveryBoyId, isPublished })

        messageShow(data.message)

        if(data.success) {
          this.refresh()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  render() {
    const { deliveriesBoyParent: { isLoading, list }, classes } = this.props

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Delivery Boys
          </Typography>

          <Link to={routes.deliveryBoyCreate.path}>
            <Button color="inherit">Create</Button>
          </Link>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : list.length === 0
                ? <EmptyMessage message={'You have not added any deliveriesBoy yet.'} />
                : <Table padding={"normal"}>
                    <TableHead>
                      <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                        <TableCell width={180}>Updated</TableCell>
                        <TableCell width={80} align="center">Published</TableCell>
                        <TableCell width={180} align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                        list.map(({ _id, name, number, isPublished, updatedAt }) =>
                          <TableRow key={_id}>
                            <TableCell>{name}</TableCell>
                            <TableCell>{ number }</TableCell>
                            <TableCell>{dayjs(updatedAt).format('DD-MM-YYYY, hh:mm A')}</TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={isPublished}
                                onChange={this.onPublishToggle(_id)}
                                color="primary"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Button component={Link} to={routes.deliveryBoyEdit.path(_id)}>Edit</Button>
                              <Button onClick={this.onDelete(_id)}>Delete</Button>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  deliveriesBoyParent: PropTypes.object.isRequired,
  listParent: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  publishToggle: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function listState(state) {
  return {
    deliveriesBoyParent: state.deliveriesBoyParent
  }
}

export default connect(listState, { listParent, remove, publishToggle, messageShow })(withStyles(styles)(List))
