// Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// UI Imports
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconClose from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import FileUpload from '@mui/icons-material/FileUpload';
// App Imports
import { nullToEmptyString, capitalizeFirstLetter } from '../../../setup/helpers';
import { upload, messageShow } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import Loading from '../../common/Loading';
import ImageComp from '../../common/Image';
import { detail } from '../api/actions/query'
import { update } from '../api/actions/mutation'
import { updateAddress } from '../api/actions/mutation'
import { getImageSource } from '../../user/api/routes';

// Component
class Update extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      vendor: {
        name: '',
        email: '',
        mobile: '',
        image: ''
      },
      address: {
        _id:'',
        userId: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: '',
        pincode: ''
      },
      previewImage:null
    }
  }

  componentDidMount() {
    this.getDetails()
  }

  getDetails = async () => {
    const { match: { params: { vendorId } }, detail } = this.props
    this.isLoadingToggle(true)

    try {
      const { data } = await detail({ userId: vendorId })

      if (data.success) {
        this.setState({ vendor: data.data.user })
        this.setState({ address: data.data.address.length > 0 ? data.data.address[0] : '' });
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('Some error occurred. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading
    })
  }

  onType = ({ target: { name, value } }) => {
    const { vendor } = this.state

    if (name === 'email') {
      vendor[name] = value
    } else {
      vendor[name] = capitalizeFirstLetter(value)
    }

    this.setState({ vendor });
  }

  onTypeAddress = ({ target: { name, value } }) => {
    const { address } = this.state;
    address[name] = value;
    this.setState({ address });
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props

    messageShow('Uploading file, please wait...')
    const imgFile = event.target.files[0];
    let file = new FormData()
    file.append("type", "user")
    file.append("file",imgFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);
    // Upload image
    try {
      const { data } = await upload(file)

      if (data.success) {
        messageShow('File uploaded successfully.')

        const { vendor } = this.state

        setTimeout(() => {
          vendor.image = data.file
          this.setState({
            vendor
          })
        }, 1500)

      } else {
        messageShow('There was some error. Please try again.')
      }
    } catch (error) {
      messageShow('There was some error. Please try again.',error)
    }
  }

  submit = async event => {
    event.preventDefault()

    const { vendor, address } = this.state
    const { update, updateAddress, messageShow, history } = this.props;

    this.isLoadingSubmitToggle(true);

    try {
      const { data: vendorData } = await update({ vendor });
      const { data: addressData } = await updateAddress({ address });

      this.isLoadingSubmitToggle(false)

      if (vendorData.success && addressData.success ) {
        messageShow('Vendor Details updated Successfully');
        history.goBack()
      } else {
        messageShow('Something went wrong !!!');
      }
    } catch (error) {
      messageShow('Something went wrong !!!')
      this.isLoadingSubmitToggle(false)
    }
  }

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({ isLoadingSubmit });
  }
  
  back = () => {
    const { history } = this.props

    history.goBack()
    
  }

  render() {
    const { isLoading, vendor, address, isLoadingSubmit } = this.state
    return (
        <div>
          <Toolbar className={styles.toolbar}>
            <IconButton color="inherit" onClick={this.back}>
              <IconArrowBack />
            </IconButton>

            <Typography variant="h6" color="inherit" >
              Edit Profile
          </Typography>
          </Toolbar>
          <Grid item xs={12} lg={6}>
            <SectionPaper padding>
              {
                isLoading 
                ? (
                <Loading />
              ) : (
                <form onSubmit={this.submit}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      value={nullToEmptyString(vendor.name)}
                      onChange={this.onType}
                      label="Name"
                      placeholder="Enter vendor name"
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        name="email"
                        value={nullToEmptyString(vendor.email.toLowerCase())}
                        onChange={this.onType}
                        label="Email"
                        placeholder="Enter Email"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="mobile"
                        type="number"
                        value={nullToEmptyString(vendor.mobile)}
                        onChange={this.onType}
                        label="Mobile"
                        placeholder="Enter Mobile"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        autoFocus
                      />
                    </Grid>

                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      name="street1"
                      value={nullToEmptyString(address.street1)}
                      onChange={this.onTypeAddress}
                      label="Street 1"
                      placeholder="Enter Street 1"
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="street2"
                      value={nullToEmptyString(address.street2)}
                      onChange={this.onTypeAddress}
                      label="Street 2"
                      placeholder="Enter Street 2"
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      name="city"
                      value={nullToEmptyString(address.city)}
                      onChange={this.onTypeAddress}
                      label="City"
                      placeholder="Enter City"
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="state"
                      value={nullToEmptyString(address.state)}
                      onChange={this.onTypeAddress}
                      label="State"
                      placeholder="Enter State"
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      name="country"
                      value={nullToEmptyString(address.country)}
                      onChange={this.onTypeAddress}
                      label="Country"
                      placeholder="Enter Country"
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="pincode"
                      value={nullToEmptyString(address.pincode)}
                      onChange={this.onTypeAddress}
                      label="Pincode"
                      placeholder="Enter Pincode"
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>
                    <Grid item sm={6} style={{ paddingTop: '30px' }}>
                      <div style={{ marginBottom: 20 }}>
                        <input
                          accept="image/png,image/jpeg"
                          style={{ display: 'none' }}
                          id="contained-button-file"
                          type="file"
                          onChange={this.onUpload}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="outlined"
                            component="span"
                            type="file"
                            fullWidth
                          >
                            <FileUpload
                            fontSize="16"
                            style={{ marginInlineEnd: 16 }}
                            />
                            Upload Image
                        </Button>
                        </label>
                      </div>
                      {!this.state.previewImage ? (
                        <ImageComp
                          src={getImageSource(`${vendor.image}`)}
                          defaultSrc={getImageSource()}
                          size={150}
                        />
                      ) : (
                        <img
                          height="auto"
                          width="100%"
                          src={this.state.previewImage}
                          alt="preview_user"
                        ></img>
                      )}
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'right', marginTop: '20px' }}>
                      <IconButton
                        type="button"
                        aria-label="Close"
                        onClick={this.back}
                      >
                        <IconClose />
                      </IconButton>
                      <IconButton
                        type="v"
                        aria-label="Save"
                        color="primary"
                        disabled={isLoadingSubmit}
                      >
                        <CheckIcon/>
                      </IconButton>
                    </Grid>
                  </form>
              )}
            </SectionPaper>
          </Grid>
        </div>
    )
  }
}

Update.propTypes = {
  detail: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default connect(null, { detail, upload, messageShow, update, updateAddress })(withStyles(styles)(Update));
