// App Imports
import auth from './auth'
import dashboardCounts from './dashboardCounts'

const modules = {
  auth,
  dashboardCounts,
};

export default modules;
