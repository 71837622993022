const styles = {
  sidenavWrapper: {
    backgroundColor: '#ffffff',
    transition: 'width 0.3s ease',
    display: 'flex',
    borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
    marginTop: 1,
    width: '60px',
    //overflow: 'hidden',

  },
  sidenav: {
    width: '100%',
    overflowY: 'auto', 
    transition: 'width 0.3s ease',
    maxHeight: 'calc(100vh - 60px)', 
    
    '&::-webkit-scrollbar': {
      display: 'none', 
    },
    
    scrollbarWidth: 'none', 
    
    '-ms-overflow-style': 'none', 
  },

  inactive: {
    color: '#999',
  },
  menuActive: {
    backgroundColor: `rgba(0, 0, 0, 0.04)`,
  },

}
  


export default styles;
