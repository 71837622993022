// App Imports
import params from '../../../setup/config/params'
import CreateOrUpdate from '../CreateOrUpdate'
import CreateOrUpdateArea from '../CreateOrUpdateArea'
import List from '../List'
import Manage from '../Manage'


// Pages routes
const routes = {
  pincodeList: {
    path: '/pincode',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },

  pincodeCreate: {
    path: '/pincode/create',
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  },

  areaCreate: {
    path: (pincodeId = ':pincodeId') => (`/area/create/${pincodeId}`),
    component: CreateOrUpdateArea,
    auth: true,
    role: params.user.roles.admin.key
  },

  pincodeEdit: {
    path: (pincodeId = ':pincodeId') => (`/pincode/edit/${pincodeId}`),
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  },

  areaEdit: {
    path: (areaId = ':areaId') => (`/area/edit/${areaId}`),
    component: CreateOrUpdateArea,
    auth: true,
    role: params.user.roles.admin.key
  },

  pincodeManage: {
    path: (pincodeId = ':pincodeId') => (`/pincode/manage/${pincodeId}`),
    component: Manage,
    auth: true,
    role: params.user.roles.admin.key
  }
}

export default routes