// App Imports
import { MESSAGE_SHOW, MESSAGE_HIDE } from './actions'

// Initial State
export const commonInitialState = {
  message: {
    text: [],
    open: false,
    duration: 5000
  }
}

// State
const state = (state = commonInitialState, action) => {
  switch (action.type) {
    case MESSAGE_SHOW:
      return {
        ...state,
        message: {
          text: action.message?.text ? action.message.text : action.message,
          duration: (action.message?.duration === null) ? null : 5000,
          open: true
        }
      }

    case MESSAGE_HIDE:
      return {
        ...state,
        message: {
          text: [],
          open: false
        }
      }

    default:
      return state
  }
}
export default state;