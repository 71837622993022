import dayjs from 'dayjs';
import React, { Component } from "react";
import { connect } from "react-redux";
// App Imports
// import params from "../../../setup/config/params";
import { priceFormat } from "../../../setup/helpers";
import { messageShow } from "../../common/api/actions";
import Loading from "../../common/Loading";
import SectionPaper from "../../common/SectionPaper";
import { detail } from "../api/actions/query";
import "./styles.css";
import QRCode from "qrcode.react";
import routes from "../api/routes";

class Print extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      QRImg: "",
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const {
      detail,
      match: {
        params: { orderId },
      },
      messageShow,
    } = this.props;
    try {
      const { data } = await detail({ orderId });
      if (data.success) {
        this.setState({
          detail: data.data,
          currencySymbol: data.data.currencydata.symbol,
        });
        document.getElementById("btn").click();
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("Some error occurred. Please try again.");
    }
  };
  imprimir = () => {
    var divToPrint = document.getElementById("ConsutaBPM");
    var newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
    
    this.back()
  };
  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.orderList.path(1)); // Default to first page if no history
    }
  };
  handleCancel = () => {
    this.props.history.push(routes.orderList.path(1)); // Navigate to the order list page
  };
  render() {
    const fullPath = window.location.href;
    const { isLoading, detail, currencySymbol } = this.state;

    return (
      <>
        {isLoading ? (
          <Loading />
        ) : (
          detail &&
          detail.order &&
          detail.order._id && (
            <>
              <div id="ConsutaBPM">
                {detail.order.isCancelled && (
                  <SectionPaper
                    padding
                    style={{ color: "red", paddingBottom: 0 }}
                  >
                    This order has been cancelled by the customer.
                  </SectionPaper>
                )}

                <div
                  className="printView px-2"
                  style={{ width: "99%", margin: "0 auto" }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "30%" }}>Order ID</div>
                      <div style={{ width: "70%", textAlign: "right" }}>
                        {detail.order.id.toUpperCase()}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "25%" }}>Name</div>
                      <div style={{ width: "75%", textAlign: "right" }}>
                        {detail.order.userId.name}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "25%" }}>Mobile</div>
                      <div style={{ width: "75%", textAlign: "right" }}>
                        {detail.order.userId.mobile}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "30%" }}>Address</div>
                      <div style={{ width: "70%", textAlign: "right" }}>
                        {detail.order?.addressId?.street1}{" "}
                        {detail.order?.addressId?.street2}-
                        {detail.order?.pincodeId?.pincode}
                      </div>
                    </div>
                  </div>

                  <table
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      marginTop: 20,
                      marginBottom: 20,
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr style={{ textAlign: "center", width: "100%" }}>
                        <th style={{ width: "60%", border: "1px solid black" }}>
                          Item
                        </th>
                        <th style={{ width: "20%", border: "1px solid black" }}>
                          Qty
                        </th>
                        <th style={{ width: "20%", border: "1px solid black" }}>
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail.orderItems.map(
                        ({ _id, productId, amount, quantity }, index) => (
                          <tr key={_id} style={{ textAlign: "center" }}>
                            <td style={{ border: "1px solid black" }}>
                              {productId.name}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {quantity}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {currencySymbol} {amount}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <div
                    style={{
                      marginTop: 10,
                      marginLeft: "5px",
                      marginRight: "5px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "40%" }}>Sub total</div>
                      <div style={{ textAlign: "right", width: "60%" }}>
                        {currencySymbol}
                        {detail.order.cartrequest.amountTotal}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "25%" }}>Tax</div>
                      <div style={{ textAlign: "right", width: "75%" }}>
                        {currencySymbol}
                        {priceFormat(detail.order.cartrequest.taxAmount)}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "50%" }}>Grand Total</div>
                      <div style={{ textAlign: "right", width: "50%" }}>
                        {currencySymbol}
                        {detail.order.cartrequest.grandTotal}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "25%" }}>Date</div>
                      <div style={{ textAlign: "right", width: "75%" }}>
                        {dayjs(detail.order.createdAt).format('DD-MM-YYYY')}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                      }}
                    >
                      <div style={{width: "50%"}}>Restaurant address</div>
                      <div style={{width: "50%", textAlign: "right"}}>{detail.order?.pincodeId?.description}</div>
                    </div>
                    <div
                      className="QRcode"
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      <QRCode
                        value={fullPath}
                        style={{ margin: "0 auto" }}
                        id="QRCanvas"
                        renderAs="svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <button id="btn" onClick={this.imprimir}>
                  Print
                </button>
                <button onClick={this.handleCancel}>
                  Cancel
                </button>
              </div>
              
            </>
          )
        )}
      </>
    );
  }
}

// Component State
function detailState(state) {
  return {
    auth: state.auth,
  };
}

export default connect(detailState, { detail, messageShow })(Print);
