import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { getAllEarnedPoints } from "../api/actions/query";
import { messageShow } from '../../../common/api/actions';
import { FormControl, RadioGroup, FormControlLabel, Radio, Pagination, Paper } from '@mui/material';

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeFrame: 'monthly',
      currentPage: 1, 
      itemsPerPage: 5, 
      options: {
        chart: {
          type: 'bar',
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Number of Goals', 
          },
        },
        dataLabels: {
          enabled: false, 
        },
        tooltip: {
          y: {
            formatter: (value) => Math.round(value),
          },
        },
      },
      series: [
        {
          name: 'Number of Goals',
          data: [],
        },
      ],
    };
  }
  
  componentDidMount() {
    this.updateChartData(this.props.data, this.state.timeFrame);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data !== this.props.data ||
      prevState.timeFrame !== this.state.timeFrame ||
      prevState.currentPage !== this.state.currentPage
    ) {
      this.updateChartData(this.props.data, this.state.timeFrame, this.state.currentPage);
    }
  }
 
  updateChartData = (data, timeFrame, currentPage = 1) => {
    const { itemsPerPage } = this.state;
    const categories = [];
    const goalsMap = new Map();

    data.forEach((item) => {
      item.goals.forEach((goal) => {
        const usedAt = new Date(goal.usedAt);
        let category;

        if (timeFrame === 'monthly') {
          const month = usedAt.toLocaleString('default', { month: 'short' });
          category = `${month} ${usedAt.getFullYear()}`;
        } else if (timeFrame === 'weekly') {
          const weekNumber = Math.ceil((usedAt.getDate() - usedAt.getDay() + 1) / 7);
          category = `W${weekNumber}-${usedAt.getFullYear()}`;
        }

        if (!goalsMap.has(category)) {
          goalsMap.set(category, {});
        }

        const userGoals = goalsMap.get(category);
        userGoals[item.user.name] = (userGoals[item.user.name] || 0) + 1;
      });
    });

    goalsMap.forEach((userGoals, category) => {
      if (!categories.includes(category)) {
        categories.push(category);
      }
    });

    const totalPages = Math.ceil(categories.length / itemsPerPage);
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const paginatedCategories = categories.slice(startIdx, endIdx);

    const series = Object.keys(goalsMap.values().next()?.value || {}).map((name) => ({
      name,
      data: paginatedCategories.map((category) => goalsMap.get(category)?.[name] || 0),
    }));

    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          categories: paginatedCategories,
        },
      },
      series,
      totalPages,
    });
  };

  handleTimeFrameChange = (event) => {
    this.setState({ timeFrame: event.target.value, currentPage: 1 });
  };

  handlePageChange = (event, page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { timeFrame, currentPage, totalPages } = this.state;

    return (
      <Paper elevation={4} style={{ padding: '20px' }}>
        <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
          <RadioGroup row value={timeFrame} onChange={this.handleTimeFrameChange}>
            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
            <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
          </RadioGroup>
        </FormControl>
        <Chart options={this.state.options} series={this.state.series} type="bar" height={350}/>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={this.handlePageChange}
          color="primary"
          style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}
        />
      </Paper>
    );
  }
}

BarChart.propTypes = {
  getAllEarnedPoints: PropTypes.func,
  messageShow: PropTypes.func,
  isLoading: PropTypes.bool, // Add isLoading if it's part of props
  classes: PropTypes.object,
};

const mapDispatchToProps = {
  messageShow,
  getAllEarnedPoints,
};

export default connect(null, mapDispatchToProps)((BarChart));


