// Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { messageShow, upload } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import routes from '../api/routes';
import styles from './styles';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid/Grid';
import {
  nullToEmptyString,
} from "../../../setup/helpers";
import PropTypes from "prop-types";
import { createEvents } from "../apiEvents/actions/mutation";

// Component
class CreateEvents extends Component {
  constructor(props) {
    super(props);

    this.events = {
        eventName:"",
        newApiEndpoint:"",
        oldApiOperationName:""
    };

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      events: this.events,
      previewImage: null,
    };
  }

  validateForm = () => {
    const { events } = this.state;

    // Validate all fields
    const isEventNameValid = events.eventName.length > 0;
    const isAtLeastOneApiFieldValid = events.newApiEndpoint.length > 0 || events.oldApiOperationName.length > 0;

    return isEventNameValid && isAtLeastOneApiFieldValid;
  }

  onCreate = async (event) => {
    event.preventDefault();

    const { createEvents, messageShow } = this.props;
    const { events } = this.state;

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await createEvents(events);
      this.isLoadingSubmitToggle(false);
      messageShow(data.message);

      if (data.success) {
        this.props.history.push(routes.listAllTabs.path, { activeTab: 'Points' });
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);
      messageShow("Some error occurred. Please try again.");
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };


  onType = (event) => {
    const { events } = this.state;
    const { name, value } = event.target;
    events[name] = value;
    this.setState({ events });
  };


  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      this.props.history.push(routes.listAllTabs.path, { activeTab: 'Points' });
    } else {
      history.goBack();
    }
  };

  render() {
    const { classes } = this.props;
    const { events, isLoadingSubmit } =
      this.state;
    return (
      <SectionPaper>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" >
            Create Events
          </Typography>
        </Toolbar>

        <Toolbar className={classes.toolbar}>
          <Grid item xs={12} lg={6}>
            <SectionPaper padding>
              <form onSubmit={this.onCreate}>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"eventName"}
                    value={nullToEmptyString(events.eventName)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Event Name"}
                    placeholder={"Enter Event Name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"newApiEndpoint"}
                    value={nullToEmptyString(events.newApiEndpoint)}
                    variant="standard"
                    onChange={this.onType}
                    label={"New API Endpoint"}
                    placeholder={"Enter New API Endpoint"}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"oldApiOperationName"}
                      value={nullToEmptyString(events.oldApiOperationName)}
                      variant="standard"
                      onChange={this.onType}
                      label={"Old API Operation Name"}
                      placeholder={"Enter Old API Operation Name"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Button
                    type={"button"}
                    aria-label={"Close"}
                    variant="outlined"
                    color="secondary"
                    onClick={this.back}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginInlineStart: 12 }}
                    type={"submit"}
                    aria-label={"Save"}
                    color={"primary"}
                    variant="contained"
                    disabled={isLoadingSubmit || !this.validateForm()} // Disable based on validation

                  >
                    Save
                  </Button>
                </Grid>
              </form>

            </SectionPaper>
          </Grid>
        </Toolbar>

      </SectionPaper>
    )
  }
}
CreateEvents.propTypes = {
  createEvents: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
function createOrEditState(state) {
  return {
    categories: state.categories,
  };
}

export default connect(createOrEditState, {
  createEvents,
  messageShow,
  upload
})(withStyles(styles)(CreateEvents));


