// App Imports
import { API_URL, IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import CreateOrUpdate from '../CreateOrUpdate'
import List from '../List'
import Manage from '../Manage'
import { defaultImage } from '../../common/Sidebar';
// Image
export function imageCategory(image = defaultImage) {
  return `${ API_URL }/${ params.category.image.path }/${ image }`
}

export function getImageSource(image = defaultImage) {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
const routes = {
  categoryList: {
    path: '/category',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },

  categoryCreate: {
    path: '/category/create',
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  },

  categoryEdit: {
    path: (categoryId = ':categoryId') => (`/category/edit/${ categoryId }`),
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  },

  categoryManage: {
    path: (categoryId = ':categoryId') => (`/category/manage/${ categoryId }`),
    component: Manage,
    auth: true,
    role: params.user.roles.admin.key
  }
}
export default routes;