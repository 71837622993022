import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'


//create gamified events
export function createEvents(events) {
    return dispatch => {
      return axios.post(API_URL+"/api/events/create", {...events})
    }
  }
  
  // update  gamified events
  export function updateEvents(events) {
    return dispatch => {
      return axios.post(API_URL+"/api/events/update", {...events})
    }
  }
  
  //delete gamified events
  export function removeEvents({ eventId }) {
    return dispatch => {
      return axios.post(API_URL+"/api/events/delete", {"_id":eventId})
    }
  }