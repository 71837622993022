// Imports
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
// UI Imports
import Typography from '@material-ui/core/Typography'
import IconArrowBack from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// App Imports
import { messageShow } from '../../common/api/actions'
import EmptyMessage from '../../common/EmptyMessage'
import Image from '../../common/Image'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { detail } from '../../user/api/actions/query'
import routes from '../api/routes'
import styles from './styles'
import { getImageSource } from '../../user/api/routes'



// Component
class Detail extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,

      open: false,

      detail: {
        user: null,
        address:[],
        wallet: [],
        walletBalance: 0,
        kyc: [],
        mobile: '',
      }
    }
  }

  componentDidMount() {

    this.refresh()
  }

  refresh = async () => {
    const { detail, match: { params: { userId } }, messageShow } = this.props

    this.isLoadingToggle(true)
    try {
      const { data } = await detail({ userId })

      if(data.success) {
        this.setState({
          detail: data.data
        })
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('Some error occurred. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading
    })
  }

  back = () => {
    const { history } = this.props

    // if(history.length > 2) {
    //   history.goBack()
    // } else {
    // TODO: Solve push issue
      history.push(routes.customerList.path(1))
    // }
  }

  render() {
    const { classes } = this.props
    const { isLoading, detail } = this.state
    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            Customer Details
          </Typography>
        </Toolbar>

        {
          isLoading
            ? <Loading />
            : detail && detail.user && detail.user._id &&
              <div>
                {/* User details */}
                <SectionPaper padding>
                  <Grid container spacing={10}>
                    <Grid item sm={2}>
                      <Image
                        src={getImageSource(detail.user.image)}
                        defaultSrc={getImageSource()}
                        size={110}
                      />
                      {/* <Image
                                    src={getImageSource(image)}
                                    defaultSrc={getImageSource()}
                                  /> */}
                    </Grid>

                    <Grid item sm={10}>
                      <Grid container spacing={10}>
                        <Grid item sm>
                          <Typography variant="overline">Name:</Typography>
                          <Typography variant="subtitle2">
                            { detail.user.name }
                          </Typography>
                        </Grid>

                        <Grid item sm>
                          <Typography variant="overline">Email:</Typography>
                        <Typography variant="subtitle2"><a href={`mailto:${detail.user.email}`} target='_blank' rel='noopener noreferrer'>{detail.user.email.toLowerCase() }</a></Typography>
                        </Grid>

                        <Grid item sm>
                      <Typography variant="overline">Pincode:</Typography>
                      <Typography variant="subtitle2">
                        {
                          // If address exists and has pincode, display it
                          detail.address && detail.address.length > 0
                            ? (detail.address.find(addr => addr.isDefault) ? detail.address.find(addr => addr.isDefault).pincode : 'N/A')
                            : 'N/A'
                        }
                      </Typography>
                    </Grid>                    
                        <Grid item sm>
                          <Typography variant="overline">Mobile:</Typography>
                          <Typography variant="subtitle2">
                            { detail.user.mobile || '-' }
                          </Typography>
                        </Grid> 
                      </Grid>

                      {/* <Grid container spacing={10}>
                        <Grid item sm>
                          <Typography variant="overline">Gender:</Typography>
                          <Typography variant="subtitle2">{ (detail.user.gender && detail.user.gender.toUpperCase()) || '-' }</Typography>
                        </Grid>

                        <Grid item sm>
                          <Typography variant="overline">Date of Birth:</Typography>
                          <Typography variant="subtitle2">{ detail.user.dateOfBirth || '-' }</Typography>
                        </Grid>

                        <Grid item sm>
                          <Typography variant="overline">Joined:</Typography>
                          <Typography variant="subtitle2">{ dayjs(detail.user.createdAt).format('DD-MM-YYYY, hh:mm A') }</Typography>
                        </Grid>
                      </Grid> */}

                      <Grid container spacing={10}>
                        {/* <Grid item sm>
                          <Typography variant="overline">Wallet Balance:</Typography>
                          <Typography variant="subtitle2">{ priceFormat(detail.walletBalance) }</Typography>
                        </Grid>

                        <Grid item sm>
                          <Typography variant="overline">Simpl Account:</Typography>
                          <Typography variant="subtitle2">
                            { detail.user && detail.user.simpl && detail.user.simpl.isApproved ? 'Connected' : '-' }
                          </Typography>
                        </Grid> */}

                        <Grid item sm>
                          <Typography variant="overline">Verified</Typography>
                          <Typography variant="subtitle2">
                            { detail.user.isVerified ? 'Verified' : 'Not Verified' }
                          </Typography>
                        </Grid>

                        <Grid item sm />
                      </Grid>
                    </Grid>
                    {/* <Grid item sm={1}>
                      <Button
                        component={Link} to={routes.customerUpadte.path(detail.user._id)}
                        color="secondary" className={classes.button}>
                        Edit
                      </Button>
                    </Grid> */}
                  </Grid>
                </SectionPaper>

                {/* User recent wallet transactions */}
                {/* <div>
                  <Toolbar className={classes.toolbar} style={{ border: 'none' }}>
                    <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                      Recent Wallet Transactions
                    </Typography>
                  </Toolbar>

                  <SectionPaper style={{ paddingTop: 0 }}>
                    <Table padding={"normal"}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Amount</TableCell>
                          <TableCell width={100}>Type</TableCell>
                          <TableCell>Note</TableCell>
                          <TableCell width={150}>Payment Status</TableCell>
                          <TableCell width={175}>Date and time</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          detail.wallet &&
                            detail.wallet.length === 0
                              ? <TableRow>
                                  <TableCell colSpan={5}><EmptyMessage message={'No transactions to show.'} /></TableCell>
                                </TableRow>
                              : detail.wallet.map(({ _id, amount, transaction, paymentId, createdAt }) =>
                                  <TableRow key={_id}>
                                    <TableCell>{ `$${ priceFormat(Math.abs(amount)) }` }</TableCell>
                                    <TableCell>{ transaction.toUpperCase() }</TableCell>
                                    {paymentId ? <TableCell>{paymentId.note}</TableCell> : <TableCell></TableCell>}
                                    {paymentId ? <TableCell>{params.payment.status[paymentId.status].title}</TableCell> : <TableCell></TableCell>}
                                    <TableCell>{ dayjs(createdAt).format('DD-MM-YYYY, hh:mm A') }</TableCell>
                                  </TableRow>
                                )
                        }
                      </TableBody>
                    </Table>
                  </SectionPaper>
                </div> */}

                {/* Kyc */}
                <div>
                  <Toolbar className={classes.toolbar} style={{ border: 'none' }}>
                    <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                      Address
                    </Typography>
                  </Toolbar>

                  <SectionPaper style={{ paddingTop: 0 }}>
                    <Table padding={"normal"} style={{ width: '100%' }}>
                      <TableHead>
                        <TableRow>
                        <TableCell width={50}>Default address</TableCell>
                          <TableCell width={90}>Street 1</TableCell>
                          <TableCell width={90}>Street 2</TableCell>
                          <TableCell width={50}>City </TableCell>
                          <TableCell >Pincode </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          detail.address &&
                          detail.address.length === 0
                            ? <TableRow>
                              <TableCell colSpan={5}><EmptyMessage message={'No Address added by customer yet.'} /></TableCell>
                            </TableRow>
                            : detail.address.map(({ _id ,isDefault,street1 , street2 ,city ,pincode }) =>
                            <TableRow key={_id}>
                            <TableCell style={{ textAlign: 'center' }}>
                                <input
                                    type="radio"
                                    name="defaultOption"
                                    checked={isDefault}
                                    disabled
                                />
                            </TableCell>

                            <TableCell>{ street1 ? street1 : "-" }</TableCell>
                            <TableCell>{ street2 ? street2 : "-" }</TableCell>
                            <TableCell>{ city ? city : "-" }</TableCell>
                            <TableCell>{ pincode ? pincode : "-"  }</TableCell>
                            {/* <TableCell>{dayjs(detail.user.createdAt).format('DD-MM-YYYY, hh:mm A')}</TableCell> */}
                        </TableRow>
                        
                            )
                        }
                      </TableBody>
                    </Table>
                  </SectionPaper>
                </div>
              </div>
          }
      </div>
    )
  }
}

//Component Properties
Detail.propTypes = {
  detail: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default connect(null, { detail, messageShow })(withStyles(styles)(Detail))
