import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { getAllEarnedPoints } from "../api/actions/query"; 
import { messageShow } from '../../../common/api/actions';

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: 'pie',
        },
        labels: [],
      },
      series: [],
    };
  }

  componentDidMount() {
    this.updateChartData(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateChartData(this.props.data);
    }
  }

  updateChartData = (data) => {
    const labels = data.map(item => item.user.name);
    const series = data.map(item => item.totalRewardsUsed);
    
    this.setState({
      options: {
        ...this.state.options,
        labels: labels,
      },
      series: series,
    });
  }

  render() {
    return <Chart options={this.state.options} series={this.state.series} type="pie" height={350}/>;
  }
}

PieChart.propTypes = {
  getAllEarnedPoints: PropTypes.func,
  messageShow: PropTypes.func,
  isLoading: PropTypes.bool, 
  classes: PropTypes.object,
};

const mapDispatchToProps = {
  messageShow,
  getAllEarnedPoints,
};

export default connect(null, mapDispatchToProps)((PieChart));