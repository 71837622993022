// Imports
import Button from "@mui/material/Button";
import FormControl from "@material-ui/core/FormControl";
// UI Imports
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from '@material-ui/core/Tooltip'
import Typography from "@material-ui/core/Typography";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import FileUpload from "@mui/icons-material/FileUpload";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// App Imports
import {
  capitalizeFirstLetter,
  nullToEmptyString,
  slug,
} from "../../../setup/helpers";
import { messageShow, upload } from "../../common/api/actions";
import ImageComp from "../../common/Image";
import Loading from "../../common/Loading";
import SectionPaper from "../../common/SectionPaper";
import { createOrUpdate } from "../api/actions/mutation";
import { detail, listParent } from "../api/actions/query";
import routes, { getImageSource } from "../api/routes";
import styles from "./styles";

// Component
class CreateOrUpdate extends Component {
  constructor(props) {
    super(props);

    this.category = {
      _id: "",
      parentId: "",
      name: "",
      slug: "",
      description: "",
      image: "default.jpg",
    };

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,

      category: this.category,
      previewImage: null,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { categoryId },
      },
    } = this.props;

    this.getParentCategories();

    if (categoryId) {
      this.getCategory(categoryId);
    }
  }

  getParentCategories = () => {
    const { listParent } = this.props;

    listParent();
  };

  getCategory = async (categoryId) => {
    const { detail } = this.props;

    this.isLoadingToggle(true);

    try {
      const { data } = await detail({ categoryId });

      if (data.success) {
        this.setState({
          category: data.data,
        });
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  onCreateOrUpdate = async (event) => {
    event.preventDefault();

    const { createOrUpdate, messageShow, history } = this.props;

    const { category } = this.state;

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await createOrUpdate({ category });

      this.isLoadingSubmitToggle(false);

      messageShow(data.message);

      if (data.success) {
        history.push(routes.categoryList.path);
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);

      messageShow("Some error occurred. Please try again.");
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  onType = ({ target: { name, value } }) => {
    const { category } = this.state;

    if (name === "name") {
      category.slug = slug(value);
    }

    category[name] = name === "slug" ? value : capitalizeFirstLetter(value);

    this.setState({
      category,
    });
  };

  onSelect = ({ target: { name, value } }) => {
    const { category } = this.state;

    category[name] = value;

    this.setState({
      category,
    });
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;

    this.isUploadingFileToggle(true);

    const imgFile = event.target.files[0];
    const file = new FormData();
    file.append("type", "category");
    file.append("name", this.state.category.slug);
    file.append("file", event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);

    // Upload image
    try {
      const { data } = await upload(file);
      if (data.success) {
        this.props.messageShow("File uploaded successfully.");
        const { category } = this.state;
        setTimeout(() => {
          category.image = data.file;
          this.setState({
            category,
          });
        }, 1000);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.categoryList.path);
    }
  };

  render() {
    const {
      match: {
        params: { categoryId },
      },
      categoriesParent,
      classes,
    } = this.props;
    const { category, isLoading, isLoadingSubmit } =
      this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            {categoryId ? "Edit" : "Create"} Category
          </Typography>
        </Toolbar>

        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            {isLoading ? (
              <Loading />
            ) : (
              <form onSubmit={this.onCreateOrUpdate}>
                {/* Input - Name */}
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"name"}
                    variant="standard"
                    value={nullToEmptyString(category?.name)}
                    onChange={this.onType}
                    label={"Name"}
                    placeholder={"Enter category name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    autoFocus
                  />
                </Grid>

                {/* Input - Slug */}
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"slug"}
                    variant="standard"
                    value={nullToEmptyString(category.slug)}
                    onChange={this.onType}
                    label={"Slug"}
                    placeholder={"Enter slug"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>

                <FormControl
                  fullWidth
                  className={classes.select}
                  style={{ marginBottom: 24 }}
                >
                  <InputLabel htmlFor="parentId">Parent Category</InputLabel>
                  <Select
                    value={nullToEmptyString(category.parentId)}
                    onChange={this.onSelect}
                    inputProps={{
                      name: "parentId",
                      id: "parentId",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {categoriesParent.isLoading ? (
                      <MenuItem disabled>
                        <em>loading</em>
                      </MenuItem>
                    ) : (
                      categoriesParent.list.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>

                {/* Input - Description */}
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"description"}
                    variant="standard"
                    value={nullToEmptyString(category.description)}
                    onChange={this.onType}
                    label={"Description"}
                    placeholder={"Enter description"}
                    margin={"dense"}
                    autoComplete={"off"}
                    multiline
                    rows={3}
                    fullWidth
                  />
                </Grid>

                {/* Input - Image */}
                <Grid
                  container
                  spacing={8}
                  className={classes.buttonUpload}
                  alignItems="center"
                >
                  <Grid item md={6}>
                    {!this.state.previewImage ? (
                      <a
                        href={getImageSource(category.image)}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <ImageComp
                          src={getImageSource(`${category.image}`)}
                          defaultSrc={getImageSource()}
                          size={"100%"}
                        />
                      </a>
                    ) : (
                      <img
                        height="auto"
                        width="100%"
                        src={this.state.previewImage}
                        alt="preview_category"
                      ></img>
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <input
                      accept={"image/png,image/jpeg"}
                      style={{ display: "none" }}
                      id={"contained-button-file"}
                      type={"file"}
                      onChange={this.onUpload}
                    />

                    <label htmlFor={"contained-button-file"}>
                      <Button
                        component={"span"}
                        type={"file"}
                        fullWidth
                      >
                        <FileUpload
                          fontSize="16"
                          style={{ marginInlineEnd: 16 }}
                        />
                        Upload Image
                      </Button>
                    </label>
                  </Grid>
                </Grid>

                {/* Button - Save */}
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Link to={routes.categoryList.path}>
                    <Tooltip title="Cancel" arrow>
                      <Button
                        type={"button"}
                        aria-label={"Close"}
                        color="error"
                        startIcon={<CancelIcon />}
                      >
                      </Button>
                    </Tooltip>
                  </Link>
                  <Tooltip title="Save" arrow>
                    <Button
                      style={{ marginInlineStart: 12 }}
                      type={"submit"}
                      aria-label={"Save"}
                      color={"primary"}
                      disabled={isLoadingSubmit}
                      startIcon={<SaveIcon />}
                    >
                    </Button>
                  </Tooltip>
                </Grid>
              </form>
            )}
          </SectionPaper>
        </Grid>
      </div>
    );
  }
}

// Component Properties
CreateOrUpdate.propTypes = {
  categoriesParent: PropTypes.object.isRequired,
  listParent: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// Component State
function createOrEditState(state) {
  return {
    categoriesParent: state.categoriesParent,
  };
}

export default connect(createOrEditState, {
  listParent,
  detail,
  createOrUpdate,
  upload,
  messageShow,
})(withStyles(styles)(CreateOrUpdate));
