// App Imports
import params from '../../../setup/config/params'
import index from '../index'
import CreateFaq from '../CreateFaq'

// Pages routes
const routes = {
  faq: {
    path: "/faq",
    component: index,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  faqCreate: {
    path: '/faq/create',
    component: CreateFaq,
    auth: true,
    role: params.user.roles.admin.key
  }
}

export default routes