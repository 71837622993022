export const calculateCLTVData = (data, page, rowsPerPage) => {
    const counts = {
        Platinum: 0,
        Gold: 0,
        Silver: 0,
        Bronze: 0,
    };

    const tableData = data.slice((page - 1) * rowsPerPage, page * rowsPerPage).map(customer => {
        const segmentName = customer.RFM_Segment_Name;
        counts[segmentName.split(" ")[0]] += 1;

        return {
            customerName: customer.user_name,
            rfmSegment: customer.RFM_Segment,
            segment: segmentName,
        };
    });

    counts.Platinum = 0;
    counts.Gold = 0;
    counts.Silver = 0;
    counts.Bronze = 0;

    data.forEach(customer => {
        const segmentName = customer.RFM_Segment_Name;
        counts[segmentName.split(" ")[0]] += 1;
    });

    const chartOptions = {
        chart: {
            type: 'bar',
            height: 600,
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: []
                }
            },
        },
        // colors: ['#f3722c', '#f9c74f','#43aa8b', '#277da1'],
        xaxis: {
            categories: ['Platinum', 'Gold', 'Silver', 'Bronze'],
        },
        yaxis: {
            title: {
                text: 'Count',
            },
            labels: {
                formatter: function(val) { return Math.round(val) }
            },
            tickAmount: Math.ceil(Math.max(...Object.values(counts)) / 10),
            max: Math.ceil(Math.max(...Object.values(counts)) / 10) * 10
        },
        title: {
            text: 'Customer Segmentation',
            align: 'center',
        },
        legend: {
            position: 'top',
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            shared: true,
            intersect: false,
            enabled: true,
            y: {
                formatter: function(value) {
                    return value + " customers"
                }
            },
            marker: {
                show: true
            },
            onDatasetHover: {
                highlightDataSeries: true,
            },
            x: {
                show: true,
            },
            style: {
                fontSize: '12px',
                fontFamily: undefined
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '40%',
                distributed: true,
            },
        },
    };
    const chartSeries = [
        {
            name: 'Customers',
            data: [counts.Platinum, counts.Gold, counts.Silver, counts.Bronze],
        }
    ];

    return { chartOptions, chartSeries, tableData };
}