import axios from 'axios';
import { API_URL } from '../../../../setup/config/env';

export const updateProductPrice = async (productName, newPrice) => {
  try {
    const response = await axios.patch(`${API_URL}/api/analytics/update-price`,{productName,newPrice},
      {
        headers: {"Content-Type": "application/json"}
      }
    )
    return response.data;
  } catch (error) {
    // console.log(error.response.data.error);
    return {error: error.response?.data?.error || "An unknown error occurred"};
  }
};

export const itemRecommendation = async () => {
  try {
    const response = await axios.post(`${API_URL}/api/analytics/item-recommendation`);
    if (response?.data?.status === "success" && response?.status === 200) {
      return { output: response.data.output, status: "success" };
    } else {
      return { output: response.data.output, status: "error" };
    }
  } catch (error) {
    return { error: error.response?.data?.error || "An unknown error occurred" };
  }
};
export const runForecastOrCltvModel = async (type) => {
  let endpoint;
  switch (type) {
    case 1:
      endpoint = 'salesforecast';
      break;
      case 2:
        endpoint = 'orderforecast';
        break;
      case 3:
        endpoint = 'cltv-forecast';
        break;
    default:
      return { error: "Invalid type provided" };
  }

  try {
    const response = await axios.post(`${API_URL}/api/analytics/${endpoint}`);
    if (response?.data?.status === "success" && response?.status === 200) {
      return { status: "success" };
    } else {
      return { status: "error" };
    }
  } catch (error) {
    return { error: error.response?.data?.error || "An unknown error occurred" };
  }
};
