import { withStyles } from '@material-ui/core';
import Anchor from '@mui/material/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Download } from "@mui/icons-material";
import debounce from 'lodash/debounce'; 
import { messageShow } from '../../common/api/actions';
import EmptyMessage from '../../common/EmptyMessage';
import Image from '../../common/Image';
import Loading from '../../common/Loading';
import Pagination from '../../common/Pagination';
import SectionPaper from '../../common/SectionPaper';
import { listCustomers, listAllCustomers } from '../../user/api/actions/query';
import { update } from "../api/actions/mutation"
import { deleteUser } from '../../user/api/actions/mutation';
import { getImageSource } from '../../user/api/routes';
import routes from '../api/routes';
import styles from './styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Popover from '@mui/material/Popover';
import { IconButton } from '@mui/material';
import { list } from '../../pincode/api/actions/query'
import InputLabel from '@mui/material/InputLabel';
import Grid from '@material-ui/core/Grid'
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit"

class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      customers: [],
      address:[],
      count: 0,
      page: props.match.params.page || 1,
      name: '',
      email: '',
      mobile: '',
      pincode: '',
      headerMenuEl: null,
      sortField: 'name', 
      sortOrder: 'asc', 
      createdAtFilter: 'all',
      yearFilter: '',
      monthFilter: '',
      calendarAnchorEl: null,
      editUserId: null,
      editedData: {},
      isEditing: false,
      pincodeList: [],
      searchField: 'all',
      searchQuery: '',
};

    this.fetchCustomers = debounce(this.fetchCustomers.bind(this), 100);
  }

  componentDidMount() {
    const { match } = this.props;
    const page = parseInt(match.params.page, 10) || this.state.page;
    this.fetchCustomers(page);
    this.fetchPincodes()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = nextProps;
    this.setState(
      {
        page: match.params.page,
      },
      this.refresh
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, name, email, mobile, pincode, sortField, sortOrder, createdAtFilter } = this.state;
    if (prevState.page !== page || 
        prevState.name !== name || 
        prevState.email !== email || 
        prevState.mobile !== mobile || 
        prevState.pincode !== pincode || 
        prevState.sortField !== sortField || 
        prevState.sortOrder !== sortOrder || 
        prevState.createdAtFilter !== createdAtFilter) {
      this.fetchCustomers(page);
    }
  }

  fetchCustomers = async (page = 1) => {
    const { listCustomers, messageShow } = this.props;
    const { searchField, searchQuery, createdAtFilter, yearFilter, monthFilter, sortField, sortOrder } = this.state;

    this.setState({ isLoading: true });

    const filters = {};
    if (searchField && searchQuery) {
        filters[searchField] = searchQuery;
    }

    if (createdAtFilter !== 'all') {
        filters.createdAt = createdAtFilter;
    }

    if (yearFilter) {
        filters.year = yearFilter;
    }

    if (monthFilter) {
        filters.month = monthFilter;
    }
    try {
      const { data } = await listCustomers({
        page,
        filter: filters,
        sortField,
        sortOrder
      });
      if (data.success) {
        this.setState({
          customers: data.data.list,
          count: data.data.count,
          isLoading: false,
        });
      } else {
        messageShow(data.message);
        this.setState({ isLoading: false });
      }
    } catch (error) {
      messageShow('There was some error. Please try again.');
      this.setState({ isLoading: false });
    }
  };

  fetchAllCustomers = async () => {
    this.headerMenuClose();
    const { listAllCustomers, messageShow } = this.props;

    try {
      const { data } = await listAllCustomers();
      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    
    } catch (error) {
      messageShow('There was some error. Please try again.');
      this.setState({ isLoading: false });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.fetchCustomers();
    });
  };

  handlePageChange = (page) => {
    this.setState({ page });
    this.fetchCustomers(page);
  };
  

  headerMenuClick = (event) => {
    this.setState({ headerMenuEl: event.currentTarget });
  };

  headerMenuClose = () => {
    this.setState({ headerMenuEl: null });
  };
  handleCalendarClick = (event) => {
    this.setState({ calendarAnchorEl: event.currentTarget });
  };
  
  handleCalendarClose = () => {
    this.setState({ calendarAnchorEl: null });
  };

  onDelete = async (userId) => {
    this.headerMenuClose();
    if (window.confirm("Are you sure you want to delete this customer?")) {
      const { deleteUser, messageShow } = this.props;
  
      try {
        const { data } = await deleteUser({ userId });
  
        messageShow(data.message);
  
        if (data.success) {
          this.fetchCustomers();
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };
  
  handleSort = (field) => {
    this.setState((prevState) => ({
      sortField: field,
      sortOrder: prevState.sortField === field && prevState.sortOrder === 'asc' ? 'desc' : 'asc',
    }), () => this.fetchCustomers(this.state.page));
  };

  handleYearChange = (year) => {
    this.setState({
      yearFilter: year,
      page: 1
    }, () => this.fetchCustomers());
  };
  
  handleFilterClick = (filter) => {
    const { createdAtFilter } = this.state;
    this.setState({
      createdAtFilter: createdAtFilter === filter ? 'all' : filter,
      page: 1
    }, () => this.fetchCustomers());
  };
  handleEdit = (userId, userData) => {
    this.setState({
      editUserId: userId,
      editedData: userData,
      isEditing: true,
      selectedPincode: userData.address && userData.address.pincodeId ? userData.address.pincodeId._id : '',
    });
  };
  

  handleEditChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      editedData: {
        ...prevState.editedData,
        [name]: value,
      },
    }));
  };
  
  
  
  handleSave = async () => {
    const { update } = this.props;
    const { editUserId, editedData } = this.state;
  
    try {
      const updatedData = {
        ...editedData,
        pincodeId: this.state.selectedPincode ? this.state.pincodeList.find(p => p._id === this.state.selectedPincode) : null,
      };
  
      const response = await update({ customer: { ...updatedData, _id: editUserId } });
  
      if (response && response.data && response.data.success) {
        this.setState({ isEditing: false });
        this.fetchCustomers(); 
      } else {
        console.error('API Error:', response && response.data ? response.data.message : 'No response data');
      }
    } catch (error) {
      console.error("Failed to save edit", error);
    }
  };
  
  handleCancel = () => {
    this.setState({ isEditing: false });
  };
  fetchPincodes = async () => {
    const { list } = this.props
    await list();
    const { pincodes } = this.props
        
    const pincodeValues = pincodes.list.map(pincode => pincode.pincode);
        
    this.setState({ 
        pincodeList: pincodes.list,
        pincodeValues 
    });
  }        
  handlePincodeChange = (event) => {
    this.setState((prevState) => ({
      selectedPincode: event.target.value,
      editedData: {
        ...prevState.editedData,
        pincodeId: this.state.pincodeList.find(p => p._id === event.target.value),  
      },
    }));
  };
  
  handleSearchFieldChange = (event) => {
    const selectedField = event.target.value;
  
    if (this.state.searchQuery.trim() !== '') {
      this.setState({ 
        searchField: selectedField, 
        searchQuery: '', 
        page: 1 
      }, () => {
        this.fetchCustomers();
      });
    } else {
      this.setState({ 
        searchField: selectedField, 
        page: 1 
      });
    }
  };
  

  handleSearchQueryChange = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      if (searchQuery.trim() === '') {
        this.setState({ page: 1 }, () => {
          this.fetchCustomers();
        });
      }
    });
  };
  

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.fetchCustomers();
    });
  };
  
  isSearchValid = () => {
    const { searchField, searchQuery } = this.state;
  
    // Enable the search button only if the search field is selected and the corresponding query is not empty
    return searchField && searchQuery.trim() !== '';
  };
  
      
  render() {
    const { classes } = this.props;
    const { isLoading, customers, isEditing, editedData, editUserId, count, searchField, searchQuery, pincodeList, selectedPincode, createdAtFilter, yearFilter, monthFilter, calendarAnchorEl, sortField, sortOrder } = this.state;
    const open = Boolean(calendarAnchorEl);
    const id = open ? 'calendar-popover' : undefined;
    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(5), (val, index) => currentYear - index);
    
    const months = [
      { value: '', label: 'Months' },
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ];
    const isInputDisabled = !searchField || searchField === 'all';
    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Customers
          </Typography>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
          <div className={classes.searchFieldsAll}>
          <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="search-field-label">Search By</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchField}
                onChange={this.handleSearchFieldChange}
                label="Search By"
                style={{
                  width: '120px', 
                  height: '50px',
                  fontSize: '1rem',
                }}
              >
                <MenuItem variant="h3" value="all">All</MenuItem>
                <MenuItem variant="h3" value="name">Name</MenuItem>
                <MenuItem variant="h3" value="email">Email</MenuItem>
                <MenuItem variant="h6" value="mobile">Mobile</MenuItem>
                <MenuItem variant="h6" value="pincode">Pincode</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              placeholder="Search..."
              value={searchQuery}
              onChange={this.handleSearchQueryChange}
              className={classes.searchInput}
              disabled={isInputDisabled} 
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleSearch}
              disabled={isInputDisabled || !this.isSearchValid() }
              style={{ borderRadius: '5px',
                textTransform: 'none',  
                padding: '2px 13px',     
                fontSize: '0.9rem', 
                }}
            >
              Search
            </Button>
          </Grid>
          </Grid>
              <div>
                <Tooltip title="Download Customers" arrow>
                  <Button
                    color="primary"
                    startIcon={<Download />}
                    size="big"
                    onClick={this.fetchAllCustomers}
                    className={classes.downloadButton}
                  >
                  </Button>
                </Tooltip>
              </div>
          </div>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
          <div className={classes.joinedSearchAll}>
            <Typography variant="h6" color="inherit" className={classes.label}>Joined By</Typography>
            <div>
              <IconButton onClick={this.handleCalendarClick}>
                <CalendarMonthIcon />
              </IconButton>
              <Popover
                  id={id}
                  open={open}
                  anchorEl={calendarAnchorEl}
                  onClose={this.handleCalendarClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className={classes.calendarPopover}>
                    <Select
                      value={yearFilter || ''}
                      onChange={(event) => this.handleYearChange(event.target.value)}
                      displayEmpty
                      className={classes.select}
                    >
                      <MenuItem value='' className={classes.menuItem}>
                        <em>Years</em>
                      </MenuItem>
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                      ))}
                    </Select>
                    <Select
                      value={monthFilter || ''}
                      onChange={(event) => this.setState({ monthFilter: event.target.value, page: 1 }, () => this.fetchCustomers())}
                      displayEmpty
                      className={classes.select}
                    >
                      {months.map((month) => (
                        <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>
                      ))}
                    </Select>
                  </div>
              </Popover>
            </div>
            <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={createdAtFilter}
                    onChange={(event) => this.handleFilterClick(event.target.value)}
                    sx={{ display: 'flex', width: '350px' }}
                    >
                    <FormControlLabel
                      value="new"
                      control={<Radio size="small" />}
                      label="New(<30days)"
                      sx={{ '& .MuiTypography-root': { fontSize: '1rem' } }}
                      />
                    <FormControlLabel
                      value="old"
                      control={<Radio size="small" />}
                      label="Old(>30days)"
                      sx={{ '& .MuiTypography-root': { fontSize: '1rem' } }}
                      />
                    <FormControlLabel
                      value="all"
                      control={<Radio size="small" />}
                      label="All"
                      sx={{ '& .MuiTypography-root': { fontSize: '1rem' } }}
                      />
                  </RadioGroup>
                </FormControl>
            </div>
          </div>
        </Toolbar>

        <SectionPaper>
          {isLoading ? (
            <Loading />
          ) : customers.length === 0 ? (
            <EmptyMessage message={'No customers to show.'} />
          ) : (
            <>
              <div className={classes.tableContainer}>
                <Table padding={"normal"}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={85}>Image</TableCell>
                      <TableCell>
                        <Button onClick={() => this.handleSort('name')}>
                          Name
                          {sortField === 'name' ? (sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : null}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => this.handleSort('email')}>
                          Email ID
                          {sortField === 'email' ? (sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : null}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => this.handleSort('mobile')}>
                          Mobile
                          {sortField === 'mobile' ? (sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : null}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => this.handleSort('pincode')}>
                          Pincode
                          {sortField === 'pincode' ? (sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : null}
                        </Button>
                      </TableCell>
                      <TableCell width={180}>
                        <Button onClick={() => this.handleSort('createdAt')}>
                          Joined
                          {sortField === 'createdAt' ? (sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : null}
                        </Button>
                      </TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {customers.map((customer) => (
                    <TableRow key={customer._id}>

                      <TableCell>
                        <Link to={routes.customerDetail.path(customer._id)}>
                          <Image src={getImageSource(customer.image)} defaultSrc={getImageSource()} />
                        </Link>
                      </TableCell>
                      <TableCell>
                        {isEditing && editUserId === customer._id ? (
                          <input
                            type="text"
                            name="name"
                            value={editedData.name || ''}
                            onChange={this.handleEditChange}
                          />
                        ) : (
                          <Link to={routes.customerDetail.path(customer._id)}>
                            <Anchor component="span" style={{ fontWeight: 500 }} color={'primary'}>
                              {customer.name}
                            </Anchor>
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        {isEditing && editUserId === customer._id ? (
                          <input
                            type="text"
                            name="email"
                            value={editedData.email || ''}
                            onChange={this.handleEditChange}
                          />
                        ) : (
                          customer.email.toLowerCase()
                        )}
                      </TableCell>
                      <TableCell>
                        {isEditing && editUserId === customer._id ? (
                          <input
                            type="text"
                            name="mobile"
                            value={editedData.mobile || ''}
                            onChange={this.handleEditChange}
                          />
                        ) : (
                          customer.mobile
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {isEditing && editUserId === customer._id ? (
                          <Select
                            value={selectedPincode || ''}
                            onChange={this.handlePincodeChange}
                              style={{
                                width: '120px',   // You can reduce this if needed
                                height: '28px',   // Control the height of the Select dropdown
                                fontSize: '0.75rem',  // Reduce the font size
                                padding: '2px',
                                textAlign: 'center', // Align text properly inside the select box
                              }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: '200px', // Limit the dropdown height
                                    width: '120px', // Ensure the dropdown matches the select width
                                  },
                                },
                              }}
                            renderValue={(value) => {
                              const selectedPincodeObj = pincodeList.find(p => p._id === value);
                              return selectedPincodeObj ? selectedPincodeObj.pincode : '';
                            }}
                          >
                            {pincodeList.map((obj) => (
                              <MenuItem value={obj._id} key={obj._id}>
                                {obj.pincode}
                              </MenuItem>
                            ))}
                          </Select>
                          ) : (
                        customer.address && customer.address.pincodeId ? (
                          customer.address.pincodeId.pincode || 'N/A'
                        ) : (
                          'N/A'
                        )
                      )}
                      </TableCell>

                      <TableCell>
                        {dayjs(customer.createdAt).format('DD-MM-YYYY, hh:mm A')}
                      </TableCell>
                      <TableCell align="center">
                        {isEditing && editUserId === customer._id ? (
                          <>
                            <Button color="primary" onClick={this.handleSave} startIcon={<SaveIcon />}></Button>
                            <Button color="error" onClick={(e) => { e.stopPropagation(); this.handleCancel(customer._id) }} startIcon={<CancelIcon />}></Button>
                          </>
                        ) : (
                          <>
                            <Tooltip title="Edit" arrow>
                              <Button color="primary" onClick={() => this.handleEdit(customer._id, customer)}>
                               <EditIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip title="Delete" arrow>
                              <Button color="error" onClick={(e) => { e.stopPropagation(); this.onDelete(customer._id); }}>
                                <DeleteIcon /> 
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                </Table>
              </div>
              <Pagination
                count={count}
                route={routes.customerList}
                onPageChange={this.handlePageChange} 
              />
            </>
          )}
        </SectionPaper>
      </div>
    );
  }
}

List.propTypes = {
  listCustomers: PropTypes.func.isRequired,
  listAllCustomers: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  detail: PropTypes.object.isRequired,
  list: PropTypes.func.isRequired,
  pincodes: PropTypes.shape({
    list: PropTypes.array.isRequired, 
  }).isRequired,};
function listState(state) {
  return {
      pincodes: state.pincodes
  }
}

export default connect(listState, { listCustomers,list, listAllCustomers, deleteUser, update, messageShow })(withStyles(styles)(List));







