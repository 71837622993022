// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dayjs from 'dayjs';
import prettifyJSON from 'prettify-json'

// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core'
import styles from './styles'

// App Imports
import routes from '../api/routes'
// import params from '../../../setup/config/params'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import Loading from '../../common/Loading'
import EmptyMessage from '../../common/EmptyMessage'
import Pagination from '../../common/Pagination'
import { list } from '../api/actions/query'

// Component
class List extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      logs: [],
      count: 0,

      page: props.match.params.page || 1,
    }
  }

  componentDidMount() {
    this.refresh()
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps

    this.setState({
      page: match.params.page
    }, this.refresh)
  }

  refresh = async (isLoading = true) => {
    const { list, messageShow } = this.props
    const { page } = this.state

    this.isLoadingToggle(isLoading)

    try {
      const { data } = await list({ page })

      if(data.success) {
        this.setState({
          logs: data.data.list,
          count: data.data.count
        })
      } else {
        messageShow(data.message)
      }
    } catch(error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  render() {
    const { classes } = this.props
    const { isLoading, logs, count } = this.state

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            Logs
          </Typography>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : logs.length === 0
                ? <EmptyMessage message={'There are no logs to show.'} />
                : <>
                    <Table padding={"normal"}>
                      <TableHead>
                        <TableRow>
                          <TableCell width={120}>Service</TableCell>
                          <TableCell width={120}>Type</TableCell>
                          <TableCell>Payload</TableCell>
                          <TableCell>User</TableCell>
                          <TableCell width={180}>Date</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          logs.map(({ _id, service, type, payload, userId, createdAt }) =>
                            <TableRow key={_id}>
                              <TableCell>{ service }</TableCell>
                              <TableCell>{ type }</TableCell>
                              <TableCell><pre style={{ maxWidth: 500, whiteSpace: 'pre-wrap', overflowWrap: 'keep-all' }}>{ prettifyJSON(payload) }</pre></TableCell>
                              <TableCell>{ userId && userId.name && `${ userId.name } (${ userId.email })` }</TableCell>
                              <TableCell>{dayjs(createdAt).format("DD-MM-YYYY, hh:mm A")}</TableCell>
                            </TableRow>
                          )
                        }
                      </TableBody>
                    </Table>

                    <Pagination
                      count={count}
                      route={routes.logList}
                    />
                  </>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  auth: PropTypes.object.isRequired,
  list: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function listState(state) {
  return {
    auth: state.auth
  }
}

export default connect(listState, { list, messageShow })(withStyles(styles)(List))
