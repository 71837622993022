// App Imports
import params from '../../../setup/config/params'
import CreateOrUpdate from '../CreateOrUpdate'
import List from '../List'


// Pages routes
const routes = {
  deliveryBoyList: {
    path: '/deliveryBoy',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },

  deliveryBoyCreate: {
    path: '/deliveryBoy/create',
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  },

  deliveryBoyEdit: {
    path: (deliveryBoyId = ':deliveryBoyId') => (`/deliveryBoy/edit/${ deliveryBoyId }`),
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  }
}
export default routes;