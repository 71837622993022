import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Create 
export function createRewards(rewards) {
  return dispatch => {
    return axios.post(API_URL+"/api/rewards/create", {...rewards})
  }
}
// update
export function updaterewards(rewards) {
  return dispatch => {
    return axios.post(API_URL+"/api/rewards/update", {...rewards})
  }
}
//delete
export function removeReward({ rewardIds }) {
  return dispatch => {
    return Promise.all(
      rewardIds.map(rewardId =>
        axios.post(API_URL + "/api/rewards/delete", { "rewardIds": rewardId })
      )
    ).then(responses => {
      return responses.map(response => response.data);
    });
  };
}
