// App Imports
import deliveriesBoy from './list'
import deliveriesBoyByParent from './listByParent'
import deliveriesBoyParent from './listParent'

const index = {
  deliveriesBoy,
  deliveriesBoyParent,
  deliveriesBoyByParent,
}
export default index;
