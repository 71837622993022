import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Box, Container, Alert, Link } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { subscribeUser } from '../api/action/mutation'

const Subscribe = ({ userId })=>{
    const [status, setStatus] = useState('loading')

    const handleSubscribe = async () => {
        const result = await subscribeUser(userId)
        if (result.success)     setStatus('success')
        else    setStatus('error')
    };

    useEffect(() => {
        document.title = "Subscribe Email"
        handleSubscribe()
    }, [])

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
            {status === 'loading' && (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <CircularProgress />
                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                        Processing your request...
                    </Typography>
                </Box>
            )}
            {status === 'success' && (
                <Alert icon={<CheckCircleIcon fontSize="inherit" />} severity="success" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h6">You have been successfully subscribed. You will receive emails from us.</Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                        If you change your mind, you can unsubscribe at any time using the link below.
                    </Typography>
                    <Link href={`/email/unsubscribe/${userId}`} sx={{ marginTop: 2 }}>
                        Click here to unsubscribe
                    </Link>
                </Alert>
            )}
            {status === 'error' && (
                <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h6">There was an error processing your request. Please try again later.</Typography>
                </Alert>
            )}
        </Container>
    );
};

export default Subscribe