// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs';
// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from "@mui/material/Checkbox"
import { withStyles } from '@material-ui/core'
import styles from './styles'

// App Imports
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import Loading from '../../common/Loading'
import EmptyMessage from '../../common/EmptyMessage'
import Image from '../../common/Image'
import { list } from '../api/actions/query'
import { remove, publishToggle } from '../api/actions/mutation'
import routes, { getImageSource } from '../api/routes'

// Component
class List extends PureComponent {

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    const { list } = this.props

    list()
  }

  onPublishToggle = bannerId => async event => {
    const isPublished = event.target.checked

    let check = window.confirm(`Are you sure you want to ${isPublished ? 'publish' : 'unpublish'} this banner?`)

    if (check) {
      const { publishToggle, messageShow } = this.props

      try {
        const { data } = await publishToggle({ bannerId, isPublished })
        messageShow(data.message)

        if (data.success) {
          this.refresh();
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  onDelete = (bannerId) => async () => {
    let check = window.confirm('Are you sure you want to delete this banner?')

    if (check) {
      const { remove, list, messageShow } = this.props

      try {
        const { data } = await remove({ bannerId })

        messageShow(data.message)

        if (data.success) {
          list()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  render() {
    const { banners: { isLoading, list }, classes } = this.props

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Banners
          </Typography>
          <Tooltip title="Create Banner" arrow>
            <Link to={routes.bannerCreate.path}>
              <Button size='big' color="primary" startIcon={<AddIcon />}></Button>
            </Link>
          </Tooltip>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : list.length === 0
                ? <EmptyMessage message={'You have not added any banners yet.'} />
                : <Table padding={"normal"}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={85}>Image</TableCell>
                      <TableCell width={150}>Name</TableCell>
                      <TableCell width={150}>Type</TableCell>
                      <TableCell width={100}>Sort</TableCell>
                      <TableCell width={200}>Updated</TableCell>
                      <TableCell width={80} align="center">Published</TableCell>
                      <TableCell width={180} align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      list.map(({ _id, image, name, sort, type, updatedAt, isPublished }) =>
                        <TableRow key={_id} onClick={() => window.location.href = `/banner/edit/${_id}`} style={{ cursor: 'pointer' }} hover>
                          <TableCell>
                            <Link to={routes.bannerEdit.path(_id)}>
                              <Image
                                src={getImageSource(`${image}`)}
                                defaultSrc={getImageSource()}
                              />
                            </Link>
                          </TableCell>
                          <TableCell>{name}</TableCell>
                          <TableCell>{type}</TableCell>
                          <TableCell>{sort}</TableCell>
                          <TableCell>{dayjs(updatedAt).format('DD-MM-YYYY, hh:mm A')}</TableCell>
                          <TableCell align="center">
                            <Checkbox
                              onClick={(e) => e.stopPropagation()}
                              checked={isPublished}
                              onChange={this.onPublishToggle(_id)}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Delete" arrow>
                              <Button onClick={(e) => { e.stopPropagation(); this.onDelete(_id)(); }} color="error"><DeleteIcon /></Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  banners: PropTypes.object.isRequired,
  list: PropTypes.func.isRequired,
  publishToggle: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function listState(state) {
  return {
    banners: state.banners
  }
}

export default connect(listState, { list, publishToggle, remove, messageShow })(withStyles(styles)(List))
