import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Create 
export function listGoals() {
    return dispatch => {
    return axios.get(API_URL+"/api/goal")
}
}

export function getGoal({id}) {
    return dispatch => {
    return axios.post(API_URL+"/api/goal",{_id:id})
}
}

export function listCategories() {
    return dispatch => {
      return axios.post(API_URL, {
        operation: 'categoryListAll'
      })
    }
}

export function productlistByCategory(categoryId) {
    return dispatch => {
      return axios.post(API_URL, {
        operation: 'productListByCategory',
        params: {
            categoryId, 
        },
      })
    }
}