// App Imports
import {API_URL,IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import { defaultImage } from '../../common/Sidebar';

// Image
export function imagegoals(image = defaultImage) {
  return `${ API_URL }/${ params.goal.image.path }/${ image }`
}
export function getImageSource(image = defaultImage) {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
// export default {
  
// }
