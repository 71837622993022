// App Imports
import params from '../../../setup/config/params'
import List from '../List'
import Update from '../Update'

// Pages routes
const routes = {
  vendorList: {
    path: (page = ':page') => `/vendors/${page}`,
    activePath: '/vendor',
    component: List,
    auth: true,
    role: params.user.roles.admin.key,
  },

  vendorDetail: {
    path: (vendorId = ':vendorId') => `/vendor/detail/${vendorId}`,
    component: List,
    auth: true,
    role: params.user.roles.admin.key,
  },

  vendorUpdate: {
    path: (vendorId = ':vendorId') => `/vendor/update/${vendorId}`,
    component: Update,
    auth: true,
    role: params.user.roles.admin.key,
  },
};

export default routes;
