// Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import ImageComp from '../../common/Image';
import FileUpload from '@mui/icons-material/FileUpload';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { messageShow, upload } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import routes from '../api/routes';
import styles from './styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from "dayjs";
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid/Grid';
import {
  nullToEmptyString,
} from "../../../setup/helpers";
import PropTypes from "prop-types";
import { createGoals } from "../api/actions/mutation";
import { getImageSource } from "../api/routes";
import { getEvents } from "../../rewards/apiEvents/actions/query";
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Box, Chip } from '@mui/material';

// Component
class Creategoals extends Component {
  constructor(props) {
    super(props);

    this.goals = {
      goalName: "",
      description: "",
      eventName: [],
      operationName:[],
      startDate: dayjs(),
      expiryDate: dayjs(),
      rewardPoints: "",
      minOrderValue: "",
      multiplierEvent: "" || 0,
      image: "default.jpg",
    };

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      goals: this.goals,
      previewImage: null,
      eventOptions: [],
    };
  }

  componentDidMount() {
    this.fetchEvents(); 
  }

  fetchEvents = async () => {
    const { getEvents } = this.props;
    try {
      const events = await getEvents(); 
      const eventOptions = events.data.data.map(event => ({
        eventName: event.eventName,
        operationName: event.newApiEndpoint || event.oldApiOperationName
      }));
      this.setState({ eventOptions });
    } catch (error) {
      console.error("Error fetching events:", error);
      this.props.messageShow("Failed to load events.");
    }
  };

  validateForm = () => {
    const { goals } = this.state;

    // Validate all fields
    const isGoalNameValid = goals.goalName.trim() !== "";
    const isEventNameValid = goals.eventName.length > 0;
    const isMinOrderValueValid = !isNaN(goals.minOrderValue) && goals.minOrderValue.trim() !== "";
    const isMultiplierEventValid = !isNaN(goals.multiplierEvent)
    const isRewardPointsValid = !isNaN(goals.rewardPoints) && goals.rewardPoints.trim() !== "";
    const isStartDateValid = goals.startDate.isValid();
    const isExpiryDateValid = goals.expiryDate.isValid() && !goals.expiryDate.isBefore(goals.startDate);

    return (
      isGoalNameValid &&
      isEventNameValid &&
      isMinOrderValueValid &&
      isMultiplierEventValid &&
      isRewardPointsValid &&
      isStartDateValid &&
      isExpiryDateValid
    );
  }
  getGoals = () => {
    const { listGoals } = this.props;

    listGoals();
  };

  onCreate = async (event) => {
    event.preventDefault();

    const { createGoals, messageShow } = this.props;
    const { goals } = this.state;

    if (goals.expiryDate.isBefore(goals.startDate)) {
      messageShow("Expiry date must be greater than or equal to the start date.");
      return;
    }

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await createGoals(goals);
      this.isLoadingSubmitToggle(false);
      messageShow(data.message);

      if (data.success) {
        this.props.history.push(routes.listAllTabs.path, { activeTab: 'Goals' });
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);
      messageShow("Some error occurred. Please try again.");
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  onDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };

  onType = (event) => {
    const { goals } = this.state;
    const { name, value } = event.target;
    goals[name] = value;
    this.setState({ goals });
  };
  onTypee = (event) => {
    const { goals } = this.state;
    const { name, value } = event.target;

    // Check if the entered value is a number
    if (!isNaN(value)) {
      goals[name] = value;
      this.setState({ goals });
    }
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;
    messageShow("Uploading file, please wait...");
    this.isUploadingFileToggle(true);
    const imgFile = event.target.files[0];
    let file = new FormData();
    file.append("type", "goals");
    file.append("name", this.state.goals.goalName.trim().replace(/\s+/g, '_'));
    file.append("file", imgFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);
    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {

        this.props.messageShow("File uploaded successfully.");
        const { goals } = this.state;
        setTimeout(() => {
          goals.image = data.file;
          this.setState({
            goals,
          });
        }, 1000);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      this.props.history.push(routes.listAllTabs.path, { activeTab: 'Goals' });
    } else {
      history.goBack();
    }
  };
  handleDateChange = (key) => (date) => {
    this.setState((prevState) => ({
      goals: {
        ...prevState.goals,
        [key]: date,
      },
    }));
  };

  
  handleEventChange = (event) => {
    const { eventOptions } = this.state;
    const selectedEventNames = event.target.value; 
    // Find the operationName based on selected event names
    const selectedOperations = eventOptions.filter(option => selectedEventNames.includes(option.eventName)).map(option => option.operationName);
   
    this.setState(prevState => ({
      goals: {
        ...prevState.goals,
        eventName: selectedEventNames, 
        operationName: selectedOperations 
      }
    }));
  };

  render() {
    const { classes } = this.props;
    const { goals, isLoadingSubmit, eventOptions } =
      this.state;
    return (
      <SectionPaper>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" >
            Create Goals
          </Typography>
        </Toolbar>

        <Toolbar className={classes.toolbar}>
          <Grid item xs={12} lg={6}>
            <SectionPaper padding>
              <form onSubmit={this.onCreate}>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"goalName"}
                    value={nullToEmptyString(goals.goalName)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Goal Name"}
                    placeholder={"Enter goal name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"description"}
                    value={nullToEmptyString(goals.description)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Description"}
                    placeholder={"Enter description"}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid container spacing={3}>

                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="event-name-label"  required={true}>Event Name</InputLabel>
                      <Select
                        labelId="event-name-label"
                        multiple
                        name="eventName"
                        value={goals.eventName}
                        onChange={this.handleEventChange}
                        required={true}
                        margin="dense"
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', overflow: 'auto', whiteSpace: 'nowrap', gap: 0.5 }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                          {eventOptions.length > 0 ? (
                          eventOptions.map((event) => (
                            <MenuItem key={event.eventName} value={event.eventName}>
                              <ListItemText primary={event.eventName} /> 
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No events available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name="minOrderValue"
                      value={nullToEmptyString(goals.minOrderValue)}
                      variant="standard"
                      onChange={this.onTypee}
                      label="Minimum Order Value"
                      placeholder={"Enter Minimum Order Value"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"multiplierEvent"}
                      value={nullToEmptyString(goals.multiplierEvent)}
                      variant="standard"
                      onChange={this.onTypee}
                      label={"Multiplier event"}
                      placeholder={"Enter number of multiplier events"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"rewardPoints"}
                      value={nullToEmptyString(goals.rewardPoints)}
                      variant="standard"
                      onChange={this.onTypee}
                      label={"Rewards points"}
                      placeholder={"Enter number of rewards points"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container spacing={2} style={{ marginBottom: 24 }}>
                  <Grid item xs={6} >
                    <DatePicker
                      required
                      label="Start Date"
                      value={dayjs(goals.startDate)}
                      slotProps={{ textField: { variant: "standard" } }}
                      onChange={this.handleDateChange('startDate')}
                      shouldDisableDate={(date) => date.isBefore(dayjs().startOf('day'))}
                    />
                  </Grid>
                  <Grid item xs={6} >
                    <DatePicker
                      required
                      value={dayjs(goals.expiryDate)}
                      label="Expiry Date"
                      slotProps={{ textField: { variant: "standard" } }}
                      onChange={this.handleDateChange('expiryDate')}
                      shouldDisableDate={(date) => {
                        const startDate = dayjs(goals.startDate);
                        return date.isBefore(startDate, 'day'); // Disable dates before the start date
                      }}
                    />

                  </Grid>
                  <Grid
                    container
                    spacing={8}
                    className={classes.buttonUpload}
                    alignItems="center"
                  >
                    <Grid item md={6} >
                      {!this.state.previewImage ? (
                          <ImageComp
                            src={getImageSource(`${goals.image}`)}
                            defaultSrc={getImageSource()}
                            size={"100%"}
                          />
                      ) : (
                        <img
                          height="auto"
                          width="100%"
                          src={this.state.previewImage}
                          alt="preview_goals"
                        ></img>
                      )}
                    </Grid>
                    <Grid item md={6}>
                      <input
                        accept={"image/png,image/jpeg"}
                        style={{ display: "none" }}
                        id={"contained-button-file"}
                        type={"file"}
                        onChange={this.onUpload}
                      />

                      <label htmlFor={"contained-button-file"}>
                        <Button
                          variant={"outlined"}
                          component={"span"}
                          type={"file"}
                          fullWidth
                        >
                          <FileUpload
                            fontSize="16"
                            style={{ marginInlineEnd: 16 }}
                          />
                          Upload Image
                        </Button>
                      </label>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Button
                    type={"button"}
                    aria-label={"Close"}
                    variant="outlined"
                    color="secondary"
                    onClick={this.back}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginInlineStart: 12 }}
                    type={"submit"}
                    aria-label={"Save"}
                    color={"primary"}
                    variant="contained"
                    disabled={isLoadingSubmit || !this.validateForm()} // Disable based on validation

                  >
                    Save
                  </Button>
                </Grid>
              </form>

            </SectionPaper>
          </Grid>
        </Toolbar>

      </SectionPaper>
    )
  }
}
Creategoals.propTypes = {

  createGoals: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
function createOrEditState(state) {
  return {
    categories: state.categories,
  };
}

export default connect(createOrEditState, {
  createGoals,
  messageShow,
  upload,
  getEvents,
})(withStyles(styles)(Creategoals));


