// Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import ImageComp from '../../common/Image';
import FileUpload from '@mui/icons-material/FileUpload';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { messageShow, upload } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import routes from '../api/routes';
import styles from './styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import dayjs from "dayjs";
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid/Grid';
import { InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {
  nullToEmptyString,
} from "../../../setup/helpers";
import PropTypes from "prop-types";
import { createRewards } from "../apiRewards/actions/mutation";
import { getImageSource } from "../apiRewards/routes";
import { getPoints } from "../../rewards/apiPoints/actions/query";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { getActiveProducts } from '../apiRewards/actions/query'
import { listCategories, productlistByCategory } from '../api/actions/query';
import Chip from '@mui/material/Chip';
import { Autocomplete } from "@mui/material";

// Component
class Createrewards extends Component {
  constructor(props) {
    super(props);

    this.rewards = {
      rewardName: "",
      earnedPoints: "",
      earnedValues: "",
      offlineDeals: false,
      onlineDeals: {
        discountPercentage: "" || 0,
        minOrderAmount: "" || 0,
        maxDiscountAmount: "" || 0,
      },
      category: "",
      activeProducts: [],
      voucherCode: "",
      issueVolume: "",
      startDate: dayjs().format('YYYY-MM-DD'), 
      expiryDate: null,
      startTime: '00:00',
      endTime: '23:59',
      image: "default.jpg",
    };

    this.state = {
      dynamicPoints: '',
      dynamicValues: '',
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      isLoadingSubmitToggle: false,
      rewards: this.rewards,
      previewImage: null,
      timeSelection: '24-hour',
      categories: [],
      subCategoryProducts: {},
      selectedSubCategory: null,    
      selectedSubCategoryName: "",
      selectedCategories: [],  
      searchTerm: "",
      isCategorySelected: false, 
      previousCategory: "",  
      activeProductsByCategory: {}, 
      tempSelectedProducts: [], 
      tempSelectedCategories: [],
       
    };
  }
  
  async componentDidMount() {
    this.refresh();
    this.loadActiveProducts();
  }

  loadActiveProducts = async () => {
    const { listCategories } = this.props;
    try {
      const response = await listCategories();
      if (response && response.data) {
        const { data } = response;
        if (data.success && Array.isArray(data.data)) {
          const categories = data.data.map(categoryData => {
            const categoryName = categoryData.category.name;
            const subCategoryNames = categoryData.subCategories.map(subCat => ({
              _id: subCat._id,
              name: subCat.name,
            }));

            return {
              _id: categoryData.category._id,
              name: categoryName,
              subCategories: subCategoryNames,
            };
          });
          this.setState({ categories });
        }
      }
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  handleSubCategorySelect = async (subCategoryIds, subCategoryNames) => {
    if (!Array.isArray(subCategoryNames)) {
      subCategoryNames = [subCategoryNames]; 
    }
  
    const { rewards, subCategoryProducts } = this.state;
  
    const newCategory = [...new Set([...rewards.category.split(", "), ...subCategoryNames])].join(", ");
  
    this.setState({
      previousCategory: rewards.category,
      selectedSubCategory: subCategoryIds,
      selectedSubCategoryName: subCategoryNames.join(", "),
      rewards: {
        ...rewards,
        category: newCategory, 
      },
      searchTerm: "", 
      isCategorySelected: true,
    });
  
    const { productlistByCategory } = this.props;
    const newSubCategoryProducts = { ...this.state.subCategoryProducts };
  
    for (const subCategoryId of subCategoryIds) {
      try {
        const response = await productlistByCategory(subCategoryId);
        if (response && response.data && response.data.success) {
          newSubCategoryProducts[subCategoryId] = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
  
    this.setState({ subCategoryProducts: newSubCategoryProducts });
  };
  
  handleDeleteSubCategory = (subCategoryNames) => {
    const { rewards, subCategoryProducts, categories } = this.state;
  
    const subCategoryIds = subCategoryNames.map(name => {
      const subCategory = categories
        .flatMap(cat => cat.subCategories)
        .find(subCat => subCat.name === name);
      return subCategory ? subCategory._id : null;
    }).filter(Boolean);
  
    const productsToRemove = subCategoryIds.flatMap(id => subCategoryProducts[id] || []);
    const updatedActiveProducts = rewards.activeProducts.filter(
      id => !productsToRemove.some(product => product._id === id)
    );
  
    const updatedCategory = rewards.category
      .split(", ")
      .filter(cat => !subCategoryNames.includes(cat))
      .join(", ");
  
    this.setState(prevState => ({
      rewards: {
        ...prevState.rewards,
        activeProducts: updatedActiveProducts,
        category: updatedCategory,
      },
    }));
  };
    
  handleProductSelect = (selectedProductIds) => {
    const { rewards, tempSelectedProducts, subCategoryProducts, categories } = this.state;
  
    const updatedProductIds = [...new Set([...rewards.activeProducts, ...selectedProductIds])];
  
    const selectedSubCategories = updatedProductIds.reduce((acc, productId) => {
      for (let subCategoryId in subCategoryProducts) {
        const product = subCategoryProducts[subCategoryId].find(p => p._id === productId);
        if (product) {
          const subCategoryName = categories
            .flatMap(cat => cat.subCategories)
            .find(subCat => subCat._id === subCategoryId)?.name;
          
          if (subCategoryName && !acc.includes(subCategoryName)) {
            acc.push(subCategoryName);
          }
        }
      }
      return acc;
    }, []);
  
    this.setState({
      tempSelectedProducts: updatedProductIds,
    }, () => {
      const updatedCategories = [...new Set([...rewards.category.split(", "), ...selectedSubCategories])];
  
      this.setState(prevState => ({
        rewards: {
          ...prevState.rewards,
          activeProducts: updatedProductIds,  
          category: updatedCategories.join(", "), 
        },
      }));
    });
  };
  
  handleActiveProductsDropdownClose = () => {
  const { tempSelectedProducts, subCategoryProducts, categories, rewards } = this.state;

  if (tempSelectedProducts.length === 0) {
    return;
  }

  const selectedSubCategories = tempSelectedProducts.reduce((acc, productId) => {
    for (let subCategoryId in subCategoryProducts) {
      const product = subCategoryProducts[subCategoryId].find(p => p._id === productId);
      if (product) {
        const subCategoryName = categories
          .flatMap(cat => cat.subCategories)
          .find(subCat => subCat._id === subCategoryId)?.name;
        
        if (subCategoryName && !acc.includes(subCategoryName)) {
          acc.push(subCategoryName);
        }
      }
    }
    return acc;
  }, []);

  this.setState({
    rewards: {
      ...rewards,
      activeProducts: tempSelectedProducts, 
      category: selectedSubCategories.join(", "),  
    },
    tempSelectedProducts: [], 
    tempSelectedCategories: [], 
  });
};

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };
  handleDeleteProduct = (removedProductIds) => {
    const { rewards, subCategoryProducts, categories } = this.state;
  
    const updatedActiveProducts = rewards.activeProducts.filter(
      (id) => !removedProductIds.includes(id)
    );
  
    let updatedCategory = rewards.category.split(", ");
  
    removedProductIds.forEach((productId) => {
      let productCategoryName = '';
      let productSubCategoryId = '';
  
      for (let subCategoryId in subCategoryProducts) {
        const product = subCategoryProducts[subCategoryId].find((p) => p._id === productId);
        if (product) {
          const subCategory = categories
            .flatMap((cat) => cat.subCategories)
            .find((subCat) => subCat._id === subCategoryId);
  
          if (subCategory) {
            productCategoryName = subCategory.name;
            productSubCategoryId = subCategoryId;
          }
          break;
        }
      }
  
      const remainingProductsInSubCategory = subCategoryProducts[productSubCategoryId]?.some((product) =>
        updatedActiveProducts.includes(product._id)
      );
  
      if (!remainingProductsInSubCategory) {
        updatedCategory = updatedCategory.filter((category) => category !== productCategoryName);
      }
    });
  
    this.setState({
      rewards: {
        ...rewards,
        activeProducts: updatedActiveProducts,
        category: updatedCategory.join(", "),  
      },
    });
  };
  
  refresh = async () => {
    const { getPoints, messageShow } = this.props;
    this.isLoadingToggle(true);

    try {
      const { data } = await getPoints();
      if (data.success && data.data) {
        this.setState({
          dynamicPoints: data.data[0].points,
          dynamicValues: data.data[0].value
        });

      }
    } catch (error) {
      messageShow("Some error occurred. Please try again.", error);
    } finally {
      this.isLoadingToggle(false);
    }
  };

  onCreate = async (event) => {
    event.preventDefault();

    const { createRewards, messageShow } = this.props;

    const { rewards } = this.state;
    
    if (!rewards.startDate) {
      messageShow("Start Date is required.");
      return;
    }

    if (!rewards.expiryDate) { 
      messageShow("Expiry Date is required.");
      return;
    }

    if (!rewards.startTime) {
      messageShow("Start Time is required.");
      return;
    }

    if (!rewards.endTime) {
      messageShow("End Time is required.");
      return;
    }

    const discountFilled = rewards.onlineDeals.discountPercentage;
    const activeProductsFilled = rewards.activeProducts.length > 0;

    if (!discountFilled && !activeProductsFilled) {
      window.confirm(
        "Please fill in either the discount percentage or active products."
      );
      return;
    }

    if (discountFilled && activeProductsFilled) {
      const proceed = window.confirm(
        "You are giving both product deals and discount percentage. Are you sure you want to continue?"
      );

      if (!proceed) {
        return;
      }
    }

    const { minOrderAmount, maxDiscountAmount } = rewards.onlineDeals;

    if (parseFloat(maxDiscountAmount) > parseFloat(minOrderAmount)) {
      window.alert("Max Discount Amount should be less than Min Order Amount.");
      return;
    }

    // if (maxDiscountAmount && parseFloat(maxDiscountAmount) >= parseFloat(minOrderAmount)) {
    //   window.alert("Max Discount Amount should be less than Min Order Amount.");
    //   return;
    // }

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await createRewards(rewards);
      this.isLoadingSubmitToggle(false);

      messageShow(data.message);

      if (data.success) {
        this.props.history.push(routes.listAllTabs.path, { activeTab: 'Rewards' });
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);

      messageShow("Some error occurred. Please try again.");
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  onDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };
  onType = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [name]: value
      }
    }));
  }
  onTypeIssueVolume = (event) => {
    const { name, value } = event.target;

    // Allow only numbers
    if (!/^\d*$/.test(value)) {
      return; // If value is not a number, do not update the state
    }

    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [name]: value
      }
    }));
  };

  onTypee = (e) => {
    const { name, value } = e.target;
    const [category, field] = name.split('.');

    if (!/^\d*$/.test(value)) {
      return;
    }

    // if (field === 'maxDiscountAmount' && value === "0") {
    //   window.alert("Max Discount Amount cannot be zero.");
    //   return;
    // }

    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [category]: {
          ...prevState.rewards[category],
          [field]: value
        }
      }
    }));
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;
    messageShow("Uploading file, please wait...");
    this.isUploadingFileToggle(true);
    const imgFile = event.target.files[0];
    let file = new FormData();
    file.append("type", "rewards");
    file.append("name", this.state.rewards.rewardName.trim().replace(/\s+/g, '_'));
    file.append("file", imgFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);
    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {

        this.props.messageShow("File uploaded successfully.");
        const { rewards } = this.state;
        setTimeout(() => {
          rewards.image = data.file;
          this.setState({
            rewards,
          });
        }, 1000);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      this.props.history.push(routes.listAllTabs.path, { activeTab: 'Rewards' });
    } else {
      history.goBack();
    }
  };
  handleDateChange = (field) => (newDate) => {
    if (newDate) {
      const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
      this.setState((prevState) => ({
        rewards: {
          ...prevState.rewards,
          [field]: formattedDate,
        },
      }));
    }
  };

  handleTimeChange = (time, key) => {
    const formattedTime = dayjs(time).format('HH:mm');

    if (key === 'startTime' && this.state.timeSelection === 'custom') {
      const endTime = dayjs(time).add(1, 'hour').format('HH:mm');
      this.setState((prevState) => ({
        rewards: {
          ...prevState.rewards,
          startTime: formattedTime,
          endTime,
        },
      }));
    } else {
      this.setState((prevState) => ({
        rewards: {
          ...prevState.rewards,
          [key]: formattedTime,
        },
      }));
    }
  };

  handleTimeSelectionChange = (event) => {
    const { value } = event.target;
    this.setState({ timeSelection: value });

    if (value === '24-hour') {
      this.setState({
        rewards: {
          ...this.state.rewards,
          startTime: '00:00',
          endTime: '23:59',
        },
      });
    } else {
      this.setState({
        rewards: {
          ...this.state.rewards,
          startTime: '',
          endTime: '',
        },
      });
    }
  };

  handleEarnedPointsChange = (event) => {
    const points = parseFloat(event.target.value);
    if (!isNaN(points)) {
      const { dynamicPoints, dynamicValues } = this.state;
      const calculatedValue = (points * dynamicValues) / dynamicPoints;
      this.setState({
        rewards: {
          ...this.state.rewards,
          earnedPoints: points,
          earnedValues: calculatedValue.toFixed(2),
        }
      });
    } else {
      this.setState({
        rewards: {
          ...this.state.rewards,
          earnedPoints: '',
          earnedValues: '',
        }
      });
    }
  };

  onChangeOfflineDeals = (event) => {
    this.setState({
      rewards: {
        ...this.state.rewards,
        offlineDeals: event.target.checked,
      },
    });
  };
  
  render() {
    const { classes } = this.props;
    const { rewards, isLoadingSubmit, timeSelection, categories, subCategoryProducts, searchTerm } =
      this.state;
      const selectedSubCategoryProducts = rewards.category
      .split(", ")
      .flatMap(
        (subCategoryName) =>
          categories
            .flatMap((cat) => cat.subCategories)
            .find((subCat) => subCat.name === subCategoryName)?._id || []
      )
      .flatMap((subCategoryId) => subCategoryProducts[subCategoryId] || []);
    
    const filteredProducts = selectedSubCategoryProducts.filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
      const allAvailableProducts = Object.values(subCategoryProducts).flat();
    
    return (
      <SectionPaper>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" >
            Create Rewards
          </Typography>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
          <Grid item xs={12} lg={6}>
            <SectionPaper padding>
              <form onSubmit={this.onCreate}>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name="rewardName"
                    value={nullToEmptyString(rewards.rewardName)}
                    variant="standard"
                    onChange={this.onType}
                    label="Reward Name"
                    placeholder="Enter reward name"
                    required={true}
                    margin="dense"
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 12 }}>
                    <TextField
                      name="earnedPoints"
                      value={rewards.earnedPoints}
                      variant="standard"
                      onChange={this.handleEarnedPointsChange}
                      label="Earned Points"
                      placeholder="Enter Earned Points"
                      margin="dense"
                      required={true}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 12 }}>
                    <TextField
                      name="earnedValues"
                      value={rewards.earnedValues}
                      variant="standard"
                      label="Event Earned Values"
                      placeholder="Enter Earned Values"
                      margin="dense"
                      required={true}
                      autoComplete="off"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"onlineDeals.discountPercentage"}
                      value={nullToEmptyString(this.state.rewards.onlineDeals.discountPercentage)}
                      variant="standard"
                      onChange={this.onTypee}
                      label={"Discount Percentage"}
                      placeholder={"Enter Discount Percentage"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"voucherCode"}
                      value={nullToEmptyString(rewards.voucherCode)}
                      variant="standard"
                      onChange={this.onType}
                      label={"Voucher Code"}
                      placeholder={"Enter Voucher Code"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>

                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"onlineDeals.minOrderAmount"}
                      value={nullToEmptyString(this.state.rewards.onlineDeals.minOrderAmount)}
                      variant="standard"
                      onChange={this.onTypee}
                      label={"Min Order Amount"}
                      placeholder={"Enter Min Order Amount"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"onlineDeals.maxDiscountAmount"}
                      value={nullToEmptyString(this.state.rewards.onlineDeals.maxDiscountAmount)}
                      variant="standard"
                      onChange={this.onTypee}
                      label={"Max Discount Amount"}
                      placeholder={"Enter Max Discount Amount"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"issueVolume"}
                      value={nullToEmptyString(this.state.rewards.issueVolume)}
                      variant="standard"
                      onChange={this.onTypeIssueVolume}
                      label={"Issue Volume"}
                      placeholder={"Enter Issue Volume"}
                      margin={"dense"}
                      required={true}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.rewards.offlineDeals}
                          onChange={this.onChangeOfflineDeals}
                          name="offlineDeals"
                          color="primary"
                          margin="normal"
                          autoComplete="off"
                        />
                      }
                      label="Offline Deal"
                    />
                  </Grid>

                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 24 }}>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                <FormControl fullWidth variant="standard">
                <Autocomplete
                    options={categories.flatMap((category) => [
                      { label: category.name, id: category._id, isCategory: true },
                      ...category.subCategories.map((subCategory) => ({
                        label: subCategory.name,
                        id: subCategory._id,
                        category: category.name,
                        isCategory: false,
                      })),
                    ])}
                    value={rewards.category.split(", ").map((name) => ({ label: name }))}
                    onChange={(event, newValue) => {
                      const oldCategories = rewards.category.split(", ");
                      const newCategories = newValue.map((item) => item.label);

                      const addedSubCategories = newCategories.filter((cat) => !oldCategories.includes(cat));
                      const removedSubCategories = oldCategories.filter((cat) => !newCategories.includes(cat));

                      if (addedSubCategories.length > 0) {
                        const addedOptions = newValue.filter((option) => addedSubCategories.includes(option.label));
                        const addedSubCategoryIds = addedOptions.map((option) => option.id);
                        const addedSubCategoryNames = addedOptions.map((option) => option.label);

                        this.handleSubCategorySelect(addedSubCategoryIds, addedSubCategoryNames);
                      }

                      if (removedSubCategories.length > 0) {
                        this.handleDeleteSubCategory(removedSubCategories);
                      }
                    }}
                    multiple
                    limitTags={2}
                    disableCloseOnSelect
                    renderInput={(params) => <TextField {...params} label="Category" placeholder="Search Categories" />}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          pointerEvents: option.isCategory ? "none" : "auto",
                          color: option.isCategory ? "#888" : "black",
                          paddingLeft: option.isCategory ? "px" : "24px",
                        }}
                      >
                        {option.label}
                      </li>
                    )}
                />
                </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                <FormControl fullWidth variant="standard">
                  <Autocomplete
                    options={filteredProducts}
                    value={rewards.activeProducts
                      .map((id) => {
                        const product = allAvailableProducts.find((p) => p._id === id);
                        return product ? { _id: product._id, name: product.name } : null;
                      })
                      .filter(Boolean)}
                      onChange={(event, newValue) => {
                        const oldProducts = rewards.activeProducts;
                        const newProductIds = newValue.map((product) => product._id);
                      
                        // Identify newly added products (not in oldProducts)
                        const addedProducts = newProductIds.filter((id) => !oldProducts.includes(id));
                      
                        // Identify removed products (present in oldProducts but not in newProductIds)
                        const removedProducts = oldProducts.filter((id) => !newProductIds.includes(id));
                      
                        // Handle added products (add products to activeProducts)
                        if (addedProducts.length > 0) {
                          this.handleProductSelect(addedProducts);  // Add selected products
                        }
                      
                        // Handle removed products (remove products from activeProducts)
                        if (removedProducts.length > 0) {
                          this.handleDeleteProduct(removedProducts);  // Handle deletion of removed products
                        }
                      }}
                      
                    onClose={this.handleActiveProductsDropdownClose} // Optional: Keep this to handle other logic on close
                    multiple
                    limitTags={2}
                    disableCloseOnSelect  // Prevent dropdown from closing when a product is selected
                    renderInput={(params) => (
                      <TextField {...params} label="Active Products" placeholder="Search products" />
                    )}
                    getOptionLabel={(option) => option.name || ""}
                  />
                </FormControl>
                </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <DatePicker
                      required
                      label="Start Date"
                      value={rewards.startDate ? dayjs(rewards.startDate) : null}
                      onChange={this.handleDateChange('startDate')}
                    />
                  </Grid>
                  <Grid item xs={6} >
                    <DatePicker
                      required
                      value={rewards.expiryDate ? dayjs(rewards.expiryDate) : null}
                      label="Expiry Date"
                      onChange={this.handleDateChange('expiryDate')}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <InputLabel variant="standard">Time Option</InputLabel>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={timeSelection}
                        onChange={this.handleTimeSelectionChange}
                        row
                      >
                        <FormControlLabel
                          control={<Radio value="24-hour" />}
                          label="24-Hour Timing"
                        />
                        <FormControlLabel
                          control={<Radio value="custom" />}
                          label="Custom Timing"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 24, marginTop: 24 }}>
                  <Grid item xs={6}>
                    <TimePicker
                      required
                      value={rewards.startTime ? dayjs(rewards.startTime, 'HH:mm') : null}
                      label="Start Time"
                      inputVariant="standard"
                      onChange={(time) => this.handleTimeChange(time, 'startTime')}
                      ampm
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimePicker
                      required
                      value={rewards.endTime ? dayjs(rewards.endTime, 'HH:mm') : null}
                      label="End Time"
                      inputVariant="standard"
                      onChange={(time) => this.handleTimeChange(time, 'endTime')}
                      ampm
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={3} style={{ marginBottom: 24, marginTop: 24 }}>
                  <Grid
                    container
                    spacing={8}
                    className={classes.buttonUpload}
                    alignItems="center"
                  >
                    <Grid item md={6} >
                      {!this.state.previewImage ? (
                        <ImageComp
                          src={getImageSource(`${rewards.image}`)}
                          defaultSrc={getImageSource()}
                          size={"100%"}
                        />
                      ) : (
                        <img
                          height="auto"
                          width="100%"
                          src={this.state.previewImage}
                          alt="preview_rewards"
                        ></img>
                      )}
                    </Grid>
                    <Grid item md={6}>
                      <input
                        accept={"image/png,image/jpeg"}
                        style={{ display: "none" }}
                        id={"contained-button-file"}
                        type={"file"}
                        onChange={this.onUpload}
                      />

                      <label htmlFor={"contained-button-file"}>
                        <Button
                          variant={"outlined"}
                          component={"span"}
                          type={"file"}
                        >
                          <FileUpload
                            fontSize="16"
                            style={{ marginInlineEnd: 16 }}
                          />
                          Upload Image
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Button
                    type={"button"}
                    aria-label={"Close"}
                    variant="outlined"
                    color="secondary"
                    onClick={this.back}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginInlineStart: 12 }}
                    type={"submit"}
                    aria-label={"Save"}
                    color={"primary"}
                    variant="contained"
                    disabled={isLoadingSubmit}
                  >
                    Save
                  </Button>
                </Grid>
              </form>

            </SectionPaper>
          </Grid>
        </Toolbar>

      </SectionPaper >
    )
  }
}
Createrewards.propTypes = {

  createRewards: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  getPoints: PropTypes.func,
  listCategories: PropTypes.func.isRequired,
  productlistByCategory: PropTypes.func.isRequired
};
function createOrEditState(state) {
  return {
    rewards: state.rewards,
  };
}

export default connect(createOrEditState, {
  createRewards,
  getPoints,
  messageShow,
  upload,
  getActiveProducts,
  listCategories,
  productlistByCategory
})(withStyles(styles)(Createrewards));



