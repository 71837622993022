// App Imports
import categories from './list'
import categoriesParent from './listParent'
import categoriesByParent from './listByParent'

const index = {
  categories,
  categoriesParent,
  categoriesByParent,
}
export default index;
