// App Imports
import { messageShow } from '../modules/common/api/actions';
import { API_WEBSOCKET_URL } from './config/env';
import io from "socket.io-client";

const AUTO_RECONNECT_INTERVAL = 1000; // 1 second
const ORDER_CREATED = 'order.created';
let store = null;
let socket = null;

export const connectToWebSocket = reduxStore => {
    store = reduxStore;
    connect();
};

const connect = () => {
    const token = window.localStorage.getItem('token');
    if (!token) return;

    const socketOptions = {
        path : '/ws/dashboard',
        query: { token },
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: AUTO_RECONNECT_INTERVAL,
    };

    socket = io(API_WEBSOCKET_URL, socketOptions);

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('error', onError);
    socket.on('message', onMessage);
};

const onConnect = () => {
    console.log('Connection established.');
};

const onDisconnect = () => {
    console.log('Disconnected from server.');
};

const onError = error => {
    console.error('WebSocket error:', error);
};

const onMessage = message => {
    try {
        eventHandler(JSON.parse(message));
    } catch (err) {
        console.error('Error parsing message:', err);
    }
};

// const showNotification = (title, body, requireInteraction = false) => {
//     let msg = new Notification(title, {
//         body: body,
//         tag: 'dashboard',
//         requireInteraction: requireInteraction
//     });

//     /* msg.addEventListener('click', event => {
//         parent.focus();
//         event.target.close();
//     }); */
// };

const eventHandler = ({ event, payload }) => {
    switch (event) {
        case ORDER_CREATED:
            store.dispatch(messageShow({ text: 'You have New Orders...!', duration: null }));
            document.getElementById('notify')?.click();
            document.getElementById('refreshOrder')?.click();
            // document.getElementById('sound').setAttribute('loop', true);
            //store.dispatch(fetchOrders());
            // play with a callback
            // showNotification(
            //     `${order?.number}`,
            //     `${order?.shipping_address?.full_name}, ${order?.shipping_address?.city}`,
            //     true
            // );
            break;
        default:
            break;
    }
};

export {socket}