// Imports
import { Table, withStyles, Tooltip } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { messageShow } from "../../../common/api/actions";
import SectionPaper from "../../../common/SectionPaper";
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import Loading from "../../../common/Loading";
import BarChart from "./barChart";
import PieChart from "./pieChart";
import routes from "../../api/routes";
import PropTypes from "prop-types";
import { getAllEarnedPoints } from "../api/actions/query";
import { getTotalRewardsAndGoals } from "../api/actions/query";
import IconButton from "@material-ui/core/IconButton";
import { Send } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Pagination } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import { Grid, Card, CardContent } from "@mui/material";
import Section from '../../../common/Section';
import CreateEdit from "../../../marketing/CreateEdit";

// Component
class Leaderboard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      leaderBoardPoints: [],
      activeRewards: 0,
      activeGoals: 0,
      totalRewardsUsed: 0,
      totalGoalsUsed: 0,
      count: 0,
      openDialog: false,
      openChartModal: false,
      chartType: '',
      Data: null,
      currentPage: 1,
      leaderBoardPointsPerPage: 5,
      sortDirection: null,
      sortedField: null,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { getAllEarnedPoints,  getTotalRewardsAndGoals, messageShow } = this.props;
    try {
      const { data } = await getAllEarnedPoints();
      if (data.success) {
        this.setState({ leaderBoardPoints: data.data, isLoading: false });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    }
    try {
      const { data } = await getTotalRewardsAndGoals();
      if (data.success) {
        const { activeRewards, activeGoals, totalRewardsUsed, totalGoalsUsed } = data.data;
        this.setState({ activeRewards, activeGoals, totalRewardsUsed, totalGoalsUsed });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    }

  };

  handlePageChange = (event, pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleSort = (field) => {
    const { sortDirection, leaderBoardPoints } = this.state;
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';

    const sortedPoints = [...leaderBoardPoints].sort((a, b) => {
      if (newSortDirection === 'asc') {
        return a[field] - b[field];
      } else {
        return b[field] - a[field];
      }
    });

    this.setState({
      leaderBoardPoints: sortedPoints,
      sortDirection: newSortDirection,
      sortedField: field,
    });
  };

  renderSortArrow = (field) => {
    const { sortDirection, sortedField } = this.state;
    if (sortedField !== field) return null;

    return (
      <span style={{ color: '#007BFF' }}>
        {sortDirection === 'asc' ? '▲' : '▼'}
      </span>
    );
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };
  
  handleOpenChartModal = (type) => {
    this.setState({ openChartModal: true, chartType: type });
  }

  handleCloseChartModal = () => {
    this.setState({ openChartModal: false, chartType: '' });
  }

  handleOpenDialog = (userData, event) => {
    event.stopPropagation()
    this.setState({ openDialog: true, Data: { ...userData, type: "leaderboard" } })
  }

  handleCloseDialog=()=>this.setState({ openDialog: false,Data: null })


  render() {
    const { leaderBoardPoints, isLoading, openDialog, currentPage, leaderBoardPointsPerPage, chartType, openChartModal, activeRewards, activeGoals, totalRewardsUsed, totalGoalsUsed } = this.state;
    const list = leaderBoardPoints;
    const columnCount = 8; // Number of columns in the table

    const indexOfLastleaderBoardPoints = currentPage * leaderBoardPointsPerPage;
    const indexOfFirstleaderBoardPoints = indexOfLastleaderBoardPoints - leaderBoardPointsPerPage;
    const currentleaderBoardPoints = leaderBoardPoints.slice(indexOfFirstleaderBoardPoints, indexOfLastleaderBoardPoints);

    const totalPages = Math.ceil(leaderBoardPoints.length / leaderBoardPointsPerPage);
    return (
      <>
        <SectionPaper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  User Name
                </TableCell>
                <TableCell>
                  RANK
                </TableCell>
                <TableCell>
                  Mobile
                </TableCell>
                <TableCell onClick={() => this.handleSort('totalEarnedPoints')} style={{ cursor: 'pointer' }}>
                  Total Earned Points  {this.renderSortArrow('totalEarnedPoints')}
                </TableCell>
                <TableCell onClick={() => this.handleSort('walletBalance')} style={{ cursor: 'pointer' }}>
                  Wallet Balance {this.renderSortArrow('walletBalance')}
                </TableCell>
                <TableCell>
                  Redeemed Points
                </TableCell>
                <TableCell onClick={() => this.handleSort('totalRewardsUsed')} style={{ cursor: 'pointer' }}>
                  No Of Rewards Used {this.renderSortArrow('totalRewardsUsed')}
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <Loading />
            ) : list.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={columnCount} style={{
                    textAlign: 'center',
                    padding: '25px',
                    fontSize: '20px',
                    variant: 'h6',
                  }}>
                    No Data Found
                  </TableCell>
                  {/* <EmptyMessage message={" No products found"} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {currentleaderBoardPoints.map(
                  (
                    {
                      _id,
                      walletBalance,
                      totalEarnedPoints,
                      totalRedeemedPoints,
                      totalRewardsUsed,
                      user,
                    },
                    index
                  ) => (
                    <TableRow key={_id} onClick={() => window.location.href = routes.LeaderBoardDetails.path(user._id)} style={{ cursor: 'pointer' }} hover>
                      <TableCell>
                        {user.name}
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      {/* Rank starts from 1 */}
                      <TableCell>{user.mobile}</TableCell>
                      <TableCell>{totalEarnedPoints}</TableCell>
                      <TableCell>{walletBalance}</TableCell>
                      <TableCell>
                        {totalRedeemedPoints}
                      </TableCell>
                      <TableCell>{totalRewardsUsed}</TableCell>
                      <TableCell>
                        <Tooltip title="Create notification">
                          <IconButton onClick={(event) => { this.handleOpenDialog({ user, walletBalance, totalEarnedPoints, totalRedeemedPoints, totalRewardsUsed }, event) }}>
                            <Send color="primary" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            )}
          </Table>
          {leaderBoardPoints.length > leaderBoardPointsPerPage && (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={this.handlePageChange}
                color="primary"
                siblingCount={1}
                boundaryCount={1}
              />
            </div>
          )}
        </SectionPaper>

      <Section>
        <Grid container spacing={3} style={{ marginTop: '16px'}}>
        <Grid item>
            <Card style={{ width: 150 }}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Active Count of Rewards
                </Typography>
                <Typography variant="h2">
                  {activeRewards}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card style={{ width: 150 }}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Active Count of Goals
                </Typography>
                <Typography variant="h2">
                  {activeGoals}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              style={{
                width: 150,
                cursor: totalRewardsUsed === 0 ? 'default' : 'pointer',
                borderBottom: totalRewardsUsed === 0 ? 'none' : '4px solid #2894f4',
              }}
              onClick={() => totalRewardsUsed !== 0 && this.handleOpenChartModal('rewards')}
            >
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Total Redeemed Coupons
                </Typography>
                <Typography variant="h2">
                  {totalRewardsUsed}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              style={{
                width: 150,
                cursor: totalGoalsUsed === 0 ? 'default' : 'pointer',
                borderBottom: totalGoalsUsed === 0 ? 'none' : '4px solid #2894f4',
              }}
              onClick={() => totalGoalsUsed !== 0 && this.handleOpenChartModal('goals')}
            >
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Total Earned Points
                </Typography>
                <Typography variant="h2">
                  {totalGoalsUsed}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </Section>
          <Dialog open={openChartModal} onClose={this.handleCloseChartModal} maxWidth={chartType === 'rewards' ? 'sm' : 'md'} fullWidth>
          <DialogTitle  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative'}}>
           {chartType === 'rewards' ?  'Top Purchasers by Reward': 'Top Earners by Goals'}
           <IconButton
            aria-label="close"
            onClick={this.handleCloseChartModal}
          >
            <CloseIcon />
          </IconButton>
          </DialogTitle>
          <DialogContent>
            {chartType === 'rewards' ? <PieChart data={leaderBoardPoints} /> : <BarChart data={leaderBoardPoints} />}
          </DialogContent>
        </Dialog>

        <Dialog open={openDialog} onClose={this.handleCloseDialog}>
          <DialogContent>
            <CreateEdit
              rewardData={this.state.Data}
              onClose={this.handleCloseDialog}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

Leaderboard.propTypes = {
  getAllEarnedPoints: PropTypes.func.isRequired,
  getTotalRewardsAndGoals: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(null, { messageShow, getAllEarnedPoints, getTotalRewardsAndGoals })(
  withStyles(styles)(Leaderboard)
);


