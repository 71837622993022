import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Create 
export function createGoals(goals) {
  return dispatch => {
    return axios.post(API_URL+"/api/goal/create", {...goals})
  }
}

// update
export function updateGoal(goals) {
  return dispatch => {
    return axios.post(API_URL+"/api/goal/update", {...goals})
  }
}
//delete
export function remove({ goalIds }) {
  return dispatch => {
    return Promise.all(
      goalIds.map(goalId =>
        axios.post(API_URL + "/api/goal/delete", { "goalIds": goalId })
      )
    ).then(responses => {
      return responses.map(response => response.data);
    });
  };
}