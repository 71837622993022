import React, { useState, useRef, useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Grid, TextField, Select, MenuItem, FormControl, FormLabel, InputLabel, Radio, RadioGroup, FormControlLabel, Button } from "@material-ui/core"
import { Box, Typography, Divider, List, ListItem, ListItemText, Chip } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { messageShow, uploadSingle } from "../../common/api/actions";
import { useDispatch } from 'react-redux'
import { createEvent, updateEmail, updateNotification, updatePost } from '../api/action/mutation'
import dayjs from 'dayjs'
import { getImageSource } from '../api/routes'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const CreateEdit = ({ refreshList, updateExistingEvent, onClose,rewardData }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { id, type } = useParams()
    const location = useLocation()
    const [eventData, setEventData] = useState(() => {
        if (rewardData?.type === "goal") {
            const goalName = rewardData.goalName.charAt(0).toUpperCase() + rewardData.goalName.slice(1);
            
            return {
                name: `${goalName} (Goal)`,
                date: dayjs(),
                message: `🍽️ Calling all food lovers! ${goalName} and savor ${rewardData.rewardPoints} delicious points! 🏆 This tasty offer expires on ${dayjs(rewardData.expiryDate).format('D MMMM, YYYY')}. Don't let this flavor-packed opportunity go cold – dig in now!\n\nBon appétit!`,
                subject: `🍕 Earn ${rewardData.rewardPoints} points by ${goalName}`,
                greeting: 'Hello',
                eventImg: rewardData.image || "",
            };
        }
        if(rewardData?.type === "leaderboard"){
            return {
                name: `${rewardData.user.name} (Leaderboard)`,
                date: dayjs(),
                message: `🎉 Bravo, ${rewardData.user.name}! Your taste for rewards is exquisite!\n${rewardData.totalEarnedPoints > 0 ? `\n🍽️ You've savored ${rewardData.totalEarnedPoints} mouth-watering points\n` : ''}${rewardData.totalRedeemedPoints > 0 ? `\n🥂 Indulged in ${rewardData.totalRedeemedPoints} points of culinary delights\n` : ''}${rewardData.totalRewardsUsed > 0 ? `\n🍰 Enjoyed ${rewardData.totalRewardsUsed} delectable rewards\n` : ''}${rewardData.walletBalance > 0 ? `\n\nYour current menu of points: ${rewardData.walletBalance} scrumptious morsels! 😋\n` : ''}\nKeep dining with us and climbing our taste-tastic leaderboard!\n\nHappy dining!`,
                subject: `🍳 ${rewardData.user.name}, you're cooking up a storm! Check your tasty stats!`,
                greeting: 'Hello',
                eventImg: rewardData.image || '',
                for: 'custom',
                customEmails: [rewardData?.user?.email.toLowerCase()],
            };
        }
        if(rewardData?.type === "reward"){
            return {
                name: `${rewardData.rewardName} (Reward)`,
                date: dayjs(),
                message: `🎉 ${rewardData.rewardName} Rewards at Our Restaurant! 🍽️\n\n• ${rewardData.earnedPoints} points + ${rewardData.earnedValues} bonus points\n\n• Offline Deals: ${rewardData.offlineDeals ? "✔️" : "❌"}\n\n• Online: ${rewardData.onlineDeals.discountPercentage}% off (Min. ₹${rewardData.onlineDeals.minOrderAmount})\n\n• Code: ${rewardData.voucherCode}\n\n• Limited to first ${rewardData.issueVolume} orders\n\n• Valid: ${rewardData.startDate} - ${rewardData.expiryDate}\n\nOrder now for rewards!\n\nEnjoy your meal!`,
                subject: `🍳 ${rewardData.rewardName} Rewards: Your Taste for Rewards is Exquisite! 🍽️`,
                greeting: 'Hello',
                eventImg: rewardData.image || '',
            };
        }
        const data = location.state?.eventData || { name: '', date: dayjs(), message: '', subject: '', greeting: 'Hello', eventImg: '' };
        if (data.date) {
            const parsedDate = dayjs(data.date);
            data.date = parsedDate.isValid() ? parsedDate : dayjs();
        }
        return data;
    })
    const [notificationType, setNotificationType] = useState(type || "all")
    const fileInputRef = useRef(null)
    const [minDate] = useState(dayjs())
    const [emails, setEmails] = useState(eventData?.customEmails?.join(',').toLowerCase() || '')
    const [forValue, setForValue] = useState(eventData?.for || "all")
    const [imagePreview, setImagePreview] = useState(null)
    const [isFormValid, setIsFormValid] = useState(false)
    const isEditMode = !!id
    const [customerJoinDays, setCustomerJoinDays] = useState(location.state?.eventData?.customerJoinDays || '');

    useEffect(() => {
        if (location.state && location.state.eventData) {
            setEventData(location.state.eventData);
            setNotificationType(location.state.eventData.notificationType || type);
            setForValue(location.state.eventData.for || "all");
            setEmails(location.state.eventData.customEmails ? location.state.eventData.customEmails.join(",").toLowerCase() : "");
            setCustomerJoinDays(location.state.eventData.customerJoinDays || '');
        }
    }, [isEditMode, location.state, id, type]);

    useEffect(() => {
        checkFormValidity();
    }, [eventData, notificationType, forValue, emails, customerJoinDays]);

    const checkFormValidity = () => {
        let isValid = eventData.name && eventData.date && eventData.message;
        if (notificationType === 'all' || notificationType === 'email') {
            isValid = isValid && eventData.subject && eventData.greeting;
        }
        if (notificationType !== 'fb-post') {
            isValid = isValid && (forValue === 'all' || forValue === 'new' || forValue === 'old' || 
                (forValue === 'custom' && emails.trim() !== '') ||
                (forValue === 'age' && parseInt(customerJoinDays, 10) > 0));
        }
        setIsFormValid(isValid);
    }

    const onUpload = async (event) => {
        dispatch(messageShow("Uploading file, please wait..."));
        const file = new FormData();
        file.append("type", "event");
        file.append("name", Date.now()+eventData.name || "event");
        file.append("file", event.target.files[0]);
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const fileResult = fileReader.result;
            let dataAsImg = new Image();
            dataAsImg.src = fileResult;
            setImagePreview(dataAsImg.src);
        };
        try {
            const response = await uploadSingle(file);
            if (response.data.success) {
                dispatch(messageShow("File uploaded successfully."));
                setEventData((prevData) => ({
                    ...prevData,
                    eventImg:response.data.file,
                }));
            } else {
                console.log("Error");
                dispatch(messageShow("There was some error. Please try again."));
            }
        } catch (error) {
            console.log(error);
            dispatch(messageShow("There was some error. Please try again."));
        }
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'eventImg') {
            onUpload(event);
        } else {
            setEventData({ ...eventData, [name]: value });
        }
    }

    const handleDateChange = (date) => {
        if (date && date.isValid()) {
            setEventData(prevData => ({ ...prevData, date }));
        } else {
            setEventData(prevData => ({ ...prevData, date: null }));
            dispatch(messageShow("Invalid date selected. Please choose a valid date."));
        }
    };

    const handleNotificationChange = (e) => {
        setNotificationType(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmails(e.target.value.toLowerCase());
        if (e.target.value.trim() !== '') {
            setForValue('custom');
        } else {
            setForValue(null);
        }
    }

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setForValue(value);
        if (value !== 'custom' && value !== 'age') {
            setEmails('');
            setCustomerJoinDays('');
        }
    }

    const handleCustomerJoinDaysChange = (e) => {
        const value = e.target.value;
        if (value === '' || (parseInt(value, 10) > 0)) {
            setCustomerJoinDays(value);
        }
    }

    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }
    const renderEmailList = () => {
        if (!eventData.customEmails || eventData.customEmails.length === 0) {
            return null;
        }

        return (
            <Grid item xs={10}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: '100px', overflowY: 'auto' }}>
                    {eventData.customEmails.slice(0, 2).map((email, index) => (
                        <Chip key={index} label={email} size="small" style={{ margin: '2px' }} />
                    ))}
                    {eventData.customEmails.length > 2 && (
                        <Chip label="..see more" onClick={() => history.push(`/messaging/${type}/view/${id}/custom-emails`, { eventData })} style={{ margin: '2px', cursor: 'pointer' }}size="small"/>
                    )}
                </Box>
            </Grid>
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let eventPayload = {
            name: eventData.name,
            message: eventData.message,
            notificationType: notificationType
        };
        
        if (eventData.date && (eventData.date.isSame(dayjs(), 'day') || eventData.date.isAfter(dayjs()))) {
            eventPayload.date = eventData.date.toISOString();
        } else {
            dispatch(messageShow("Enter a valid date"));
            return;
        }
        
        if (notificationType !== "fb-post") {
            if (forValue === "custom" && emails) {
                const emailArray = emails.split(",").map((email) => email.trim().toLowerCase());
                const validEmails = emailArray.filter((email) => isValidEmail(email));
                if (validEmails.length > 0) {
                    eventPayload.for = "custom";
                    eventPayload.emails = validEmails;
                } else {
                    dispatch(messageShow("All email IDs you provided are invalid. Please provide valid email IDs"));
                }
            } else if (forValue === "age" && customerJoinDays && parseInt(customerJoinDays, 10) > 0) {
                eventPayload.for = "age";
                eventPayload.customerJoinDays = parseInt(customerJoinDays, 10);
            } else if (forValue) {
                eventPayload.for = forValue;
            }
        }
        
        if (notificationType === "email" || notificationType === "all") {
            eventPayload.subject = eventData.subject;
            eventPayload.greeting = eventData.greeting;
        }

        if (notificationType !== 'push-notification') {
            if (eventData.eventImg) {
                eventPayload.eventImg = eventData.eventImg;
            }
        }

        if(rewardData?.type === "leaderboard"){
            eventPayload.variant = "leaderboard"
        }

        try {
            let response;
            if (isEditMode) {
                switch (type) {
                    case 'email':
                        response = await updateEmail(id, eventPayload);
                        break;
                    case 'push-notification':
                        response = await updateNotification(id, eventPayload);
                        break;
                    case 'fb-post':
                        response = await updatePost(id, eventPayload);
                        break;
                    default:
                        throw new Error('Invalid event type');
                }
            } else {
                response = await createEvent(eventPayload);
            }
            
            if (response && response.error) {
                throw new Error(response.error);
            } else if (response && response.message) {
                dispatch(messageShow(`Event ${isEditMode? 'Updated':'Created' } successfully`))
                if (isEditMode) {
                    updateExistingEvent(response.data);
                }
                if(refreshList) refreshList()
                setEventData({name: '',date: dayjs(),message: '',subject: '',greeting: 'Hello',eventImg: null})
                setEmails('')
                setForValue("all")
                setImagePreview(null)
                setCustomerJoinDays('');

            }
        } catch (err) {
            console.error("Error submitting form:", err);
            dispatch(messageShow(err.message || "An error occurred while processing your request"));
        }
        finally{
            if(isEditMode)  history.push("/messaging")
        }
    }


    const handleCancel = () => {
        if (isEditMode) {
            history.goBack()
        }
        onClose()
    }

    const renderCommonFields = () => (
        <>
            <Grid item xs={isEditMode?5:6}>
                <TextField fullWidth name="name" value={eventData.name} onChange={handleInputChange} placeholder="Event Name*" style={{marginTop:'25px'}} required/>
            </Grid>
            <Grid item xs={isEditMode?5:6}>
                <DatePicker label="Event Date*" value={eventData.date} onChange={handleDateChange} renderInput={(params) => <TextField {...params} fullWidth required />} minDate={minDate}
                    onError={(error) => {
                        if (error !== "invalidDate") {
                            console.log("DatePicker error:", error);
                        }
                    }}
                />
            </Grid>
            {(notificationType === 'email' || notificationType === 'all') && (
                <>
                    <Grid item xs={isEditMode ? 5 : 6}>
                        <TextField fullWidth name="subject" value={eventData.subject} onChange={handleInputChange} placeholder="Subject*" required/>
                    </Grid>
                    <Grid item xs={isEditMode ? 5 : 6}>
                        <FormControl fullWidth required>
                            <Select
                                name="greeting"
                                value={eventData.greeting}
                                onChange={handleInputChange}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Greeting*</MenuItem>
                                <MenuItem value="Hello">Hello</MenuItem>
                                <MenuItem value="Hi">Hi</MenuItem>
                                <MenuItem value="Dear">Dear</MenuItem>
                                {/* Add more greeting options as needed */}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid item xs={isEditMode?10:12}>
                <TextField fullWidth multiline minRows={3} maxRows={15} name="message" value={eventData.message} onChange={handleInputChange} placeholder="Message*" required/>
            </Grid>
        </>
    )

    const renderImageUpload = () => (
        <>
            <Grid item xs={isEditMode?5:6}>
                <input 
                    type="file" 
                    name="eventImg" 
                    ref={fileInputRef} 
                    onChange={handleInputChange} 
                    accept="image/*" 
                    style={{ display: 'none' }} 
                    id="event-image-upload" 
                />
                <label htmlFor="event-image-upload">
                    <Button variant="contained" component="span">
                        {isEditMode ? 'Change Image' : 'Upload Image'}
                    </Button>
                </label>
            </Grid>
            <Grid item xs={isEditMode?5:6}>
                <div style={{ width: '200px', height: '150px', overflow: 'hidden' }}>
                    {(imagePreview || eventData.eventImg) ? (
                        <img 
                            src={getImageSource(`${eventData.eventImg}`)}
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                            alt='Event'
                        />
                    ) : (
                        <img
                            src={getImageSource()}
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                            alt="Preview"
                        />
                    )}
                </div>
            </Grid>
        </>
    )

    const renderCustomerTypeFields = () => (
        <>
            <Grid item xs={isEditMode ? 10 : 12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Send to Customers</FormLabel>
                    <RadioGroup aria-label="customer-type" name="customer-type" value={forValue} onChange={handleRadioChange} style={{display:"flex", flexDirection:"row"}}>
                        {rewardData?.type !== "leaderboard" && (
                            <>
                                <FormControlLabel value="all" control={<Radio color='primary'/>} label="All" />
                                <FormControlLabel value="new" control={<Radio color='primary'/>} label="New (<30 days)" />
                                <FormControlLabel value="old" control={<Radio color='primary'/>} label="Old (>30 days)" />
                                <FormControlLabel value="age" control={<Radio color='primary'/>} label="Customer Join Days" />
                            </>
                        )}
                        <FormControlLabel value="custom" control={<Radio color='primary'/>} label="Custom" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {forValue === 'custom' && 
            <Grid item xs={isEditMode ? 10 : 12}>
                <TextField fullWidth name="emails" value={emails} onChange={handleEmailChange} placeholder="Custom Emails (use comma as separator)" disabled={forValue !== 'custom'}/>
            </Grid>}
            {forValue === 'age' && 
            <Grid item xs={isEditMode ? 10 : 12}>
                <TextField
                    fullWidth
                    type="number"
                    name="customerJoinDays"
                    value={customerJoinDays}
                    onChange={handleCustomerJoinDaysChange}
                    placeholder="Enter number of days (greater than 0)"
                    disabled={forValue !== 'age'}
                    inputProps={{ min: 1 }}
                    error={customerJoinDays !== '' && parseInt(customerJoinDays, 10) <= 0}
                    helperText={customerJoinDays !== '' && parseInt(customerJoinDays, 10) <= 0 ? "Value must be greater than 0" : ""}
                />
            </Grid>}
        </>
    )

    const getEventTypeHeading = () => {
        switch (type) {
            case 'email':
                return "Email";
            case 'push-notification':
                return "Push Notification";
            case 'fb-post':
                return "Facebook Post";
            default:
                return "Event";
        }
    };

    const BackButton = () => (
        <IconButton color="primary" onClick={handleCancel} aria-label="Go back">
            <ArrowBackIcon/>
        </IconButton>
    );

    return (
        <>
            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height:'auto',
                marginTop:'20px'
            }}>
                {isEditMode &&
                    <>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                            <BackButton />
                            <Typography variant="subtitle2">
                                {`${getEventTypeHeading()}: ${eventData.name}`}
                            </Typography>
                        </Box>
                        <Divider sx={{ mb: 2 }} />
                    </>
                }
                <Box sx={{ 
                    display: 'flex',
                    width: '100%',
                    height:'auto',
                }}>
                    <Box sx={{ 
                        width: isEditMode ? '65%' : '100%',
                        paddingRight: isEditMode ? '20px' : '0',
                        height:'auto',
                        margin:'10px'
                    }}>
                        <form onSubmit={handleSubmit} style={isEditMode?{width:"700px", margin:"auto auto"}:undefined}>
                            <Grid container spacing={isEditMode?5:3}>
                                {!isEditMode && (
                                    <Grid item xs={isEditMode?10:12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Notification Type</InputLabel>
                                            <Select value={notificationType} onChange={handleNotificationChange} disabled={isEditMode} >
                                                <MenuItem value="all">All</MenuItem>
                                                <MenuItem value="email">Email</MenuItem>
                                                {rewardData?.type !== "leaderboard" && (<MenuItem value="fb-post">FB Post</MenuItem>)}
                                                <MenuItem value="push-notification">Push Notification</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {renderCommonFields()}
                                {notificationType !== 'push-notification' && renderImageUpload()}
                                {(notificationType === 'email' || notificationType === 'push-notification' || notificationType === 'all') && renderCustomerTypeFields()}
                                <Grid item xs={isEditMode?10:12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                                    <Button variant='contained' color='primary' type='submit' disabled={!isFormValid} style={{ marginRight: '10px' }}>
                                        {isEditMode ? 'Update' : 'Create Event'}
                                    </Button>
                                    <Button variant='outlined' onClick={handleCancel} sx={{ backgroundColor: 'red' }}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    
                    {isEditMode && (
                        <Box sx={{ 
                            width: '35%',
                            overflow: 'hidden',
                            p:4
                        }}>
                            <Typography variant="subtitle2" gutterBottom>
                                More Details
                            </Typography>
                            <Divider />
                            <List>
                                {eventData.status && <ListItem>
                                    <ListItemText primary="Status" secondary={eventData.status} />
                                </ListItem>}
                                {eventData.for !== "age" && typeof eventData.for === 'string' && (
                                    <ListItem>
                                        <ListItemText 
                                            primary="To" 
                                            secondary={`${eventData.for.charAt(0).toUpperCase() + eventData.for.slice(1)} customers`}
                                        />
                                    </ListItem>
                                )}
                                {eventData.for === "age" && eventData.customerJoinDays && (
                                    <ListItem>
                                        <ListItemText 
                                            primary="To" 
                                            secondary={`Customers joined in: ${eventData.customerJoinDays} Days`}
                                        />
                                    </ListItem>
                                )}
                                {eventData.for==='custom' && <ListItem>
                                    <ListItemText 
                                        primary="Custom Emails" 
                                        secondary={isEditMode && (notificationType === 'email' || notificationType === 'push-notification') && renderEmailList()}
                                    />
                                </ListItem>}
                                {eventData.totalCustomer !== undefined && (
                                    <ListItem>
                                        <ListItemText primary="Total Customers" secondary={eventData.totalCustomer} />
                                    </ListItem>
                                )}
                                {eventData.sendCount !== undefined && (
                                    <ListItem>
                                        <ListItemText primary="Sent" secondary={eventData.sendCount} />
                                    </ListItem>
                                )}
                                {eventData.createdAt && <ListItem>
                                    <ListItemText primary="Created At" secondary={dayjs(eventData.createdAt).format('MMMM D, YYYY h:mm A')}/>
                                </ListItem>}
                            </List>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default CreateEdit