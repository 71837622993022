// Imports
import { withStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete';
import React, { Component } from "react";
import { connect } from "react-redux";
import { messageShow } from "../../common/api/actions";
import SectionPaper from "../../common/SectionPaper";
import styles from "./styles";
import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { nullToEmptyString } from "../../../setup/helpers";
import { getPoints } from "../../rewards/apiPoints/actions/query";
import { updatePoints } from "../../rewards/apiPoints/actions/mutation";
import { getEvents } from "../../rewards/apiEvents/actions/query";
import { removeEvents } from '../apiEvents/actions/mutation'

// Component
class Save extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSubmitting: false,
      PointsData: {},
      eventsData: [],
      _id: "",
    };
  }

  async componentDidMount() {
    this.refresh();
    this.fetchEventsData();
  }


  refresh = async () => {
    const { getPoints, messageShow } = this.props;

    this.isLoadingToggle(true);

    try {
      const { data } = await getPoints();
      if (data.success && data.data) {
        this.setState({
          PointsData: data.data[0],
        });
      } else {
        console.error("data.success is false or data.data is missing");
      }
    } catch (error) {
      messageShow("Some error occurred. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  fetchEventsData = async () => {
    const { getEvents, messageShow } = this.props;

    this.isLoadingToggle(true);

    try {
      const { data } = await getEvents();
      if (data.success && data.data) {
        this.setState({
          eventsData: data.data,
        });
        this.setState({
          _id: data._id,
        });
      }
    } catch (error) {
      messageShow("Some error occurred while fetching events. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  onDelete = (eventId) => async () => {
    let check = window.confirm('Are you sure you want to delete this event?')

    if (check) {
      const { removeEvents, messageShow } = this.props

      try {
        const { data } = await removeEvents({ eventId })

        messageShow(data.message)
        this.fetchEventsData();
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isSubmittingToggle = (isSubmitting) => {
    this.setState({
      isSubmitting,
    });
  };

  onChange = ({ target: { name, value } }) => {
    this.setState((prevState) => ({
      PointsData: {
        ...prevState.PointsData,
        [name]: value,
      },
    }));
  };


  savePoints = async () => {
    const { points, value } = this.state.PointsData;
    const { updatePoints, messageShow } = this.props;

    let errorMessage = ""; 

    if (!points && !value) {
      errorMessage = "Points and Dollar Value are required."; 
    } else if (!points) {
      errorMessage = "Points are required."; 
    } else if (!value) {
      errorMessage = "Dollar Value is required."; 
    }
  
    if (errorMessage) {
      messageShow(errorMessage); 
      return;
    }

    const { _id } = this.state.PointsData;
    const { data } = await updatePoints({ _id, points, value });
    if (data.success) {
      this.refresh();
    }
    this.isSubmittingToggle(false);
    messageShow(data.message);
  };


  render() {
    const { isSubmitting, PointsData, eventsData } = this.state;

    return (
      <div>
        <div style={{ padding: "18px" }}>
          <Grid item xs={12}>
            <SectionPaper>
              <Table padding={"normal"}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 50 }}>Points</TableCell>
                    <TableCell style={{ width: 100 }}>
                      <InputBase
                        placeholder={"Points"}
                        type="number"
                        variant="standard"
                        name={"points"}
                        value={nullToEmptyString(PointsData?.points) ?? ''}
                        onChange={this.onChange}
                        label={"points"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                        inputProps={{
                          pattern: "[0-9]*", // Allows only numeric input
                          inputMode: "numeric", // Specifies the numeric input mode
                          min: "0", // Ensures only positive numbers are allowed
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ width: 140 }}>= Dollar Value</TableCell>
                    {/* <TableCell style={{  width: 10 }}>$</TableCell> */}
                    {/* <TableCell style={{  width: 10 }}>Value </TableCell> */}
                    <TableCell style={{ width: 100 }}>
                      <InputBase
                        variant="standard"
                        name={"value"}
                        value={nullToEmptyString(PointsData?.value) ?? ''}
                        onChange={this.onChange}
                        label={"value"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                        placeholder={"value"}
                        type="number"
                        inputProps={{
                          pattern: "[0-9]*", // Allows only numeric input
                          inputMode: "numeric", // Specifies the numeric input mode
                          min: "0", // Ensures only positive numbers are allowed
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        aria-label={"Save"}
                        size="medium"
                        color={"primary"}
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={this.savePoints}
                      >
                        Save
                      </Button>
                      <br />
                      {/* <Button>Reset</Button> */}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </SectionPaper>
          </Grid>

          {/* New Table for Gamified Events */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="subtitle1" gutterBottom>
              Gamified Events
            </Typography>
            <SectionPaper>
              <Table padding={"normal"}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 290 }}>Event Name</TableCell>
                    <TableCell style={{ width: 290 }}>New API Endpoint</TableCell>
                    <TableCell style={{ width: 290 }}>Old API Operation Name</TableCell>
                    <TableCell style={{ width: 150 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventsData.length > 0 ? (
                    eventsData.map((event, index) => (
                      <TableRow key={event._id} onClick={() => window.location.href = `/events/update/${event._id}`} style={{ cursor: 'pointer' }} hover>
                        <TableCell >{event.eventName}</TableCell>
                        <TableCell >{event.newApiEndpoint}</TableCell>
                        <TableCell >{event.oldApiOperationName}</TableCell>
                        <TableCell >
                          <Tooltip title="Delete Event" arrow>
                            <Button color='error'
                              onClick={(e) => {
                                e.stopPropagation(); 
                                this.onDelete(event._id)(); 
                              }}>
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} style={{ textAlign: "center" }}>
                        No events found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </SectionPaper>
          </Grid>
        </div>
      </div>
    );
  }
}

Save.propTypes = {
  PointsData: PropTypes.object,
  eventsData: PropTypes.object,
  save: PropTypes.func,
  updatePoints: PropTypes.func,
  getPoints: PropTypes.func,
  messageShow: PropTypes.func,
  classes: PropTypes.object,
  removeEvents: PropTypes.func.isRequired,
};

// Component State
function createOrEditState(state) {
  return {
    PointsDataParent: state.PointsDataParent,
  };
}

export default connect(createOrEditState, {
  getPoints,
  messageShow,
  updatePoints,
  getEvents,
  removeEvents,
})(withStyles(styles)(Save));
