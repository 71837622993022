// Imports
import { withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import routes from "../api/routes";
import { withRouter, Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PropTypes from "prop-types";
import styles from "./styles";
import Points from "../Points";
import Goals from "../listGoals";
import Rewards from "../listRewards";
import Leaderboard from "../Leaderboard/List/index";
import Loading from "../../common/Loading"
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Delete } from "@mui/icons-material";

import { listGoals } from "../api/actions/query";
import { messageShow } from "../../common/api/actions";
import { remove } from "../api/actions/mutation";
import { listrewards } from "../apiRewards/actions/query";
import { removeReward } from "../apiRewards/actions/mutation";

// Component
class List extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.getActiveTabFromPath(
        props.location.pathname,
        props.location.state
      ),
      dataFetched: {
        Goals: false,
        Points: false,
        Rewards: false,
        LeaderBoard: false,
      },
      goals: [],
      selectedGoals: {},
      isLoading: false,
      popUp: false,
      selectedGoalId: null,
      rewards: [],
      messageDialogOpen: false,
      selectedRewards: {},
      selectedRewardId: null,
    };
  }

  componentDidMount() {
    this.fetchData(this.state.activeTab);
    this.refresh();
    this.refreshReward();
  }

  openPopUp = (goalId) => this.setState({ popUp: true, selectedGoalId: goalId });

  closePopUp = () => this.setState({ popUp: false, selectedGoalId: null });

  refresh = async () => {
    const { listGoals } = this.props;
    this.isLoadingToggle(true);
    try {
      const { data } = await listGoals();
      if (data.success) {
        this.setState({ goals: data.data });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  openMessagingDialog = (rewardId) => {
    this.setState({ messageDialogOpen: true, selectedRewardId: rewardId });
  };

  closeMessagingDialog = () => {
    this.setState({ messageDialogOpen: false, selectedRewardId: null });
  };

  refreshReward = async () => {
    const { listrewards } = this.props;
    this.isLoadingToggle(true)
    try {
      const { data } = await listrewards()
      if (data.success) {
        this.setState({
          rewards: data.data,
        })
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  handleSelectGoal = (goalId) => {
    this.setState((prevState) => {
      const newSelectedGoals = { ...prevState.selectedGoals };
      if (newSelectedGoals[goalId]) {
        delete newSelectedGoals[goalId];
      } else {
        newSelectedGoals[goalId] = true;
      }
      return { selectedGoals: newSelectedGoals };
    });
  };
  
  isGoalExpired = (goal) => {
    return goal.isExpired;
  };
  
  handleSelectAllGoals = () => {
    const { goals, selectedGoals } = this.state;
    const newSelectedGoals = {};
     
    const enabledGoals = goals.filter(goal => !this.isGoalExpired(goal));

    if (Object.keys(selectedGoals).length !== enabledGoals.length) {
      enabledGoals.forEach(goal => {
        newSelectedGoals[goal._id] = true;
      });
    }

    this.setState({ selectedGoals: newSelectedGoals });
  };

  handleSelectReward = (rewardId) => {
    this.setState((prevState) => {
      const newSelectedRewards = { ...prevState.selectedRewards };
      if (newSelectedRewards[rewardId]) {
        delete newSelectedRewards[rewardId];
      } else {
        newSelectedRewards[rewardId] = true;
      }
      return { selectedRewards: newSelectedRewards };
    });
  };
  
  isRewardExpired = (reward) => {
    return reward.isExpired;
  };

  handleSelectAllRewards = () => {
    const { rewards, selectedRewards } = this.state;
    const newSelectedRewards = {};
  
    const enabledRewards = rewards.filter(reward => !this.isRewardExpired(reward));
  
    if (Object.keys(selectedRewards).length !== enabledRewards.length) {
      enabledRewards.forEach(reward => {
        newSelectedRewards[reward._id] = true;
      });
    }
  
    this.setState({ selectedRewards: newSelectedRewards });
  };

  onDelete = async () => {
    const { selectedGoals } = this.state;

    const goalIdsToDelete = Object.keys(selectedGoals).filter(goalId => selectedGoals[goalId]);

    const check = window.confirm(
      `Are you sure you want to delete ${goalIdsToDelete.length} selected goal(s)?`
    );

    if (check) {
      const { remove, messageShow } = this.props;

      try {
        const removedData = await remove({ goalIds: goalIdsToDelete });

        if (removedData.some(res => res.success)) {
          const successfulDeletes = removedData.filter(res => res.success).length;
          messageShow(`${successfulDeletes} goal(s) deleted successfully.`);
          this.refresh();
          this.setState({ selectedGoals: {} });
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  onDeleteReward = async () => {
    const { selectedRewards } = this.state;

    const rewardIdsToDelete = Object.keys(selectedRewards).filter(rewardId => selectedRewards[rewardId]);

    const check = window.confirm(
      `Are you sure you want to delete ${rewardIdsToDelete.length} selected reward(s)?`
    );

    if (check) {
      const { removeReward, messageShow } = this.props;

      try {
        const removedData = await removeReward({ rewardIds: rewardIdsToDelete });

        if (removedData.some(res => res.success)) {
          const successfulDeletes = removedData.filter(res => res.success).length;
          messageShow(`${successfulDeletes} reward(s) deleted successfully.`);
          this.refreshReward();
          this.setState({ selectedRewards: {} });
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  // Define getActiveTabFromPath as an arrow function
  getActiveTabFromPath = (path, state) => {
    if (state && state.activeTab) {
      return state.activeTab;
    }
    if (path.includes(routes.listGoals.path)) {
      return "Goals";
    } else if (path.includes(routes.listPoints.path)) {
      return "Points";
    } else if (path.includes(routes.listRewards.path)) {
      return "Rewards";
    } else if (path.includes(routes.listLeaderboard.path)) {
      return "LeaderBoard";
    }
    return "Goals";
  };

  handleSelect = (key) => {
    this.setState({ activeTab: key });
    let path;
    switch (key) {
      case "Goals":
        path = routes.listAllTabs.path; // Assuming this is the correct path for Goals
        break;
      case "Points":
        path = routes.listPoints.path;
        break;
      case "Rewards":
        path = routes.listRewards.path;
        break;
      case "LeaderBoard":
        path = routes.listLeaderboard.path; // Correct path for LeaderBoard
        break;
      default:
        path = routes.listAllTabs.path; // Fallback to Goals or any default
    }
    this.props.history.push(path, { activeTab: key });
  };

  // Fetch data based on tab
  fetchData = (tab) => {
    if (!this.state.dataFetched[tab]) {
      console.log(`Fetching ${tab} API...`); // Ensure this logs only once per tab
      // Simulate API call
      setTimeout(() => {
        this.setState((prevState) => ({
          dataFetched: {
            ...prevState.dataFetched,
            [tab]: true,
          },
        }));
      }, 1000);
    }
    else {
      console.log(`${tab} data already fetched.`);
    }
  };


  // Control the fetchData call after tab change
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.fetchData(this.state.activeTab);
    }
  }

  renderCreateButton = () => {
    const { activeTab, selectedGoals, selectedRewards } = this.state
    let currentPath = ""
    let tilteText = ""
    let isDisabled = true

    switch (activeTab) {
      case 'Goals':
        currentPath = routes.creategoals.path;
        tilteText = 'Create Goal';
        isDisabled = false;
        break;
      case 'Rewards':
        currentPath = routes.createRewards.path;
        tilteText = 'Create Reward';
        isDisabled = false;
        break;
      case 'Points':
        currentPath = routes.createEvents.path;
        tilteText = 'Create Event';
        isDisabled = false;
        break;
      default:
        tilteText = '';
        isDisabled = true;
        currentPath = '#';
    }

    const hasSelectedGoals = Object.keys(selectedGoals).length > 0;
    const hasselectedRewards = Object.keys(selectedRewards).length > 0;

    return (
      <div>
        {hasSelectedGoals ? ( 
          <Tooltip title="Delete Selected Goals">
            <IconButton color="error" onClick={this.onDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        ) : hasselectedRewards ? (
          <Tooltip title="Delete Selected Rewards">
            <IconButton color="error" onClick={this.onDeleteReward}>
              <Delete />
            </IconButton>
          </Tooltip>
        ) : (
          <Link to={currentPath}>
            <Tooltip title={tilteText}>
              <IconButton color="primary" disabled={isDisabled}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Link>
        )}
      </div>
    );
  };

  render() {
    const { activeTab, dataFetched, goals, selectedGoals, popUp, selectedGoalId, rewards, selectedRewards, messageDialogOpen, selectedRewardId } = this.state;
    return (
      <Container fluid>
        <Row className="mb-10 mt-3 justify-content-center">
          <Col>
            <div className="d-flex">
              <Tabs
                activeKey={activeTab}
                onSelect={this.handleSelect}
                transition={false}
                className="flex-grow-1"
              >
                <Tab eventKey="Goals" title="Goals">
                </Tab>
                <Tab eventKey="Points" title="Points">
                </Tab>
                <Tab eventKey="Rewards" title="Rewards">
                </Tab>
                <Tab eventKey="LeaderBoard" title="LeaderBoard">
                </Tab>
              </Tabs>
              {this.renderCreateButton()}
            </div>
            {activeTab === "Goals" && (dataFetched.Goals ? <Goals
              key={this.state.goals.length}
              goals={goals}
              popUp={popUp}
              refresh={this.refresh}
              openPopUp={this.openPopUp}
              closePopUp={this.closePopUp}
              selectedGoalId={selectedGoalId}
              selectedGoals={selectedGoals}
              onSelectGoal={this.handleSelectGoal}
              onSelectAllGoals={() => this.handleSelectAllGoals(this.state.goals)} /> : <Loading />)}
            {activeTab === "Points" && (dataFetched.Points ? <Points /> : <Loading />)}
            {activeTab === "Rewards" && (dataFetched.Rewards ? <Rewards
              key={this.state.rewards.length}
              rewards={rewards}
              messageDialogOpen={ messageDialogOpen}
              refresh={this.refreshReward}
              openMessagingDialog={this.openMessagingDialog}
              closeMessagingDialog={this.closeMessagingDialog}
              selectedRewardId={selectedRewardId}
              selectedRewards={selectedRewards}
              onSelectReward={this.handleSelectReward}
              onSelectAllRewards={() => this.handleSelectAllRewards(this.state.rewards)} /> : <Loading />)}
            {activeTab === "LeaderBoard" && (dataFetched.LeaderBoard ? <Leaderboard /> : <Loading />)}
          </Col>
        </Row> 
      </Container>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  listGoals: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  listrewards: PropTypes.func.isRequired,
  removeReward: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { listGoals, messageShow, remove, listrewards, removeReward})(withStyles(styles)(List)));