// Imports
import Button from '@mui/material/Button';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { messageShow, upload } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import PropTypes from 'prop-types';
import { nullToEmptyString } from '../../../setup/helpers';
import routes from '../api/routes';
import styles from './styles';
import { getEventsById } from '../apiEvents/actions/query';
import { updateEvents } from '../apiEvents/actions/mutation';

// UI Imports
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconArrowBack from '@material-ui/icons/ArrowBack';

// Component
class UpdateEvents extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoadingSubmit: false,
            isUploadingFile: false,
            events: {
                eventName: "",
                newApiEndpoint: "",
                oldApiOperationName: ""
            },
        };
    }

    isFormValid = () => {
        const { events } = this.state;

        // Validate all fields
        const isEventNameValid = events.eventName.length > 0;
        const isAtLeastOneApiFieldValid = events.newApiEndpoint.length > 0 || events.oldApiOperationName.length > 0;

        return isEventNameValid && isAtLeastOneApiFieldValid;
    };

    componentDidMount() {
        this.refresh();
    }

    refresh = async () => {
        const {getEventsById, isLoading, match: { params: { eventId } } } = this.props;
        this.isLoadingToggle(isLoading);

        try {
            const { data } = await getEventsById({ id: eventId });
            if (data.success) {
                this.setState({
                    events: {
                        ...data.data,
                    },
                });
            } else {
                messageShow(data.message);
            }
        } catch (error) {
            messageShow('There was some error. Please try again.', error);
        } finally {
            this.isLoadingToggle(false);
        }
    };

    isLoadingSubmitToggle = (isLoadingSubmit) => {
        this.setState({ isLoadingSubmit });
    };

    back = () => {
        const { history } = this.props;

        if (history.length > 2) {
            this.props.history.push(routes.listAllTabs.path, { activeTab: 'Points' });
        } else {
            history.goBack();
        }
    }

    isLoadingToggle = (isLoading) => {
        this.setState({ isLoading });
    }

    onUpdate = async (event) => {
        event.preventDefault();

        const { updateEvents, messageShow } = this.props;
        const { events } = this.state;

        this.isLoadingSubmitToggle(true);

        try {
            const { data } = await updateEvents(events);
            this.isLoadingSubmitToggle(false);
            messageShow(data.message);

            if (data.success) {
                this.props.history.push(routes.listAllTabs.path, { activeTab: 'Points' });
            }
        } catch (error) {
            this.isLoadingSubmitToggle(false);
            messageShow("Some error occurred. Please try again in update.", error);
        }
    };

    onType = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            events: {
                ...prevState.events,
                [name]: value,
            },
        }));
    };

    render() {
        const { classes } = this.props;
        const { events } = this.state;

        return (
            <SectionPaper>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        onClick={this.back}
                    >
                        <IconArrowBack />
                    </IconButton>
                    <Typography variant="h6" color="inherit">
                        Update Events
                    </Typography>
                </Toolbar>

                <Toolbar className={classes.toolbar}>
                    <Grid item xs={12} lg={6}>
                        <SectionPaper padding>
                            <form onSubmit={this.onUpdate}>
                                <Grid item xs={12} style={{ marginBottom: 24 }}>
                                    <TextField
                                        name={"eventName"}
                                        value={nullToEmptyString(events.eventName)}
                                        variant="standard"
                                        onChange={this.onType}
                                        label={"Event Name"}
                                        placeholder={"Enter Event Name"}
                                        required={true}
                                        margin={"dense"}
                                        autoComplete={"off"}
                                        fullWidth
                                        autoFocus
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ marginBottom: 24 }}>
                                    <TextField
                                        name={"newApiEndpoint"}
                                        value={nullToEmptyString(events.newApiEndpoint)}
                                        variant="standard"
                                        onChange={this.onType}
                                        label={"New API Endpoint"}
                                        placeholder={"Enter New API Endpoint"}
                                        margin={"dense"}
                                        autoComplete={"off"}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} style={{ marginBottom: 24 }}>
                                        <TextField
                                            name={"oldApiOperationName"}
                                            value={nullToEmptyString(events.oldApiOperationName)}
                                            variant="standard"
                                            onChange={this.onType}
                                            label={"Old API Operation Name"}
                                            placeholder={"Enter Old API Operation Name"}
                                            margin={"dense"}
                                            autoComplete={"off"}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.buttonsContainer}>
                                    <Button
                                        type={"button"}
                                        aria-label={"Close"}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={this.back}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        style={{ marginInlineStart: 12 }}
                                        type={"submit"}
                                        aria-label={"Save"}
                                        color={"primary"}
                                        variant="contained"
                                        disabled={!this.isFormValid()} // Disable if form is not valid
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </form>

                        </SectionPaper>
                    </Grid>
                </Toolbar>
            </SectionPaper>
        );
    }
}

UpdateEvents.propTypes = {
    updateEvents: PropTypes.func.isRequired,
    getEventsById: PropTypes.func.isRequired,
    messageShow: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

function listState(state) {
    return {
        categoriesParent: state.categoriesParent,
    };
}

export default connect(listState, {
    getEventsById,
    updateEvents,
    upload,
    messageShow,
})(withStyles(styles)(UpdateEvents));


