// Imports
// UI Imports
import Grid from '@material-ui/core/Grid/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconArrowBack from '@material-ui/icons/ArrowBack'
import CheckIcon from '@mui/icons-material/Check';
import IconClose from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import { capitalizeFirstLetter, nullToEmptyString, slug } from '../../../setup/helpers'
import { messageShow } from '../../common/api/actions'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { createOrUpdateArea } from '../api/actions/mutation'
import { areaDetail } from '../api/actions/query'
import routes from '../api/routes'
import styles from './styles'



// Component
class CreateOrUpdateArea extends Component {

    constructor(props) {
        super(props)

        this.area = {
            _id: '',
            pincodeId: '',
            name: '',
            slug: '',
        }

        this.state = {
            isLoading: false,
            isLoadingSubmit: false,
            isUploadingFile: false,

            area: this.area
        }
    }

    componentDidMount() {
        const { match: { params: { pincodeId } } } = this.props
        const { match: { params: { areaId } } } = this.props

        if (pincodeId) {
            this.setState({
                area: { pincodeId }
            })
        }

        if (areaId) {
            this.getArea(areaId)
        }
    }

    getArea = async (areaId) => {
        const { areaDetail } = this.props

        this.isLoadingToggle(true)

        try {
            const { data } = await areaDetail({ areaId })

            if (data.success) {
                this.setState({
                    area: data.data
                })
            }
        } catch (error) {
            messageShow('There was some error. Please try again.')
        } finally {
            this.isLoadingToggle(false)
        }
    }

    onCreateOrUpdate = async event => {
        event.preventDefault()

        const { createOrUpdateArea, messageShow, history } = this.props
        const { area } = this.state

        this.isLoadingSubmitToggle(true)

        try {
            const { data } = await createOrUpdateArea({ area })


            this.isLoadingSubmitToggle(false)

            messageShow(data.message)

            if (data.success) {
                // history.push(routes.pincodeManage.path)
                history.goBack()
            }
        } catch (error) {
            this.isLoadingSubmitToggle(false)

            messageShow('Some error occurred. Please try again.')
        }
    }

    isLoadingToggle = isLoading => {
        this.setState({
            isLoading
        })
    }

    isLoadingSubmitToggle = isLoadingSubmit => {
        this.setState({
            isLoadingSubmit
        })
    }

    isUploadingFileToggle = isUploadingFile => {
        this.setState({
            isUploadingFile
        })
    }

    onType = ({ target: { name, value } }) => {
        const { area } = this.state

        if (name === 'name') {
            area.slug = slug(value)
        }

        area[name] = (name === 'slug') ? value : capitalizeFirstLetter(value)

        this.setState({
            area
        })
    }

    /* onUpload = async event => {
        const { upload, messageShow } = this.props

        messageShow('Uploading file, please wait...')

        this.isUploadingFileToggle(true)

        let file = new FormData()
        file.append('type', 'pincode')
        file.append('file', event.target.files[0])

        // Upload image
        try {
            const { data } = await upload(file)

            if (data.success) {
                this.props.messageShow('File uploaded successfully.')

                const { pincode } = this.state
                setTimeout(() => {
                    pincode.image = data.file
                    this.setState({
                        pincode
                    })
                }, 1000)
            } else {
                messageShow('There was some error. Please try again.')
            }
        } catch (error) {
            messageShow('There was some error. Please try again.')
        } finally {
            this.isUploadingFileToggle(false)
        }
    } */

    render() {
        const { match: { params: { areaId } }, classes } = this.props
        const { area, isLoading, isLoadingSubmit } = this.state

        return (
            <div>
                <Toolbar className={classes.toolbar}>
                    <Link to={routes.pincodeList.path}>
                        <IconButton className={classes.menuButton} color="inherit">
                            <IconArrowBack />
                        </IconButton>
                    </Link>

                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        {areaId ? 'Edit' : 'Create'} Area
          </Typography>
                </Toolbar>

                <Grid item xs={12} lg={6}>
                    <SectionPaper padding>
                        {
                            isLoading
                                ? <Loading />
                                : <form onSubmit={this.onCreateOrUpdate}>
                                    {/* Input - Name */}
                                    <Grid item xs={12}>
                                        <TextField
                                            name={'name'}
                                            value={nullToEmptyString(area.name)}
                                            onChange={this.onType}
                                            label={'Name'}
                                            placeholder={'Enter area name'}
                                            required={true}
                                            margin={'dense'}
                                            autoComplete={'off'}
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>

                                    {/* Input - Slug */}
                                    <Grid item xs={12}>
                                        <TextField
                                            name={'slug'}
                                            value={nullToEmptyString(area.slug)}
                                            onChange={this.onType}
                                            label={'Slug'}
                                            placeholder={'Enter slug'}
                                            required={true}
                                            margin={'dense'}
                                            autoComplete={'off'}
                                            fullWidth
                                        />
                                    </Grid>

                                    {/* Button - Save */}
                                    <Grid item xs={12} className={classes.buttonsContainer}>
                                        <Link to={routes.pincodeManage.path}>
                                            <IconButton
                                                type={'button'}
                                                aria-label={'Close'}
                                            >
                                                <IconClose />
                                            </IconButton>
                                        </Link>

                                        <IconButton
                                            type={'submit'}
                                            aria-label={'Save'}
                                            color={'primary'}
                                            disabled={isLoadingSubmit}
                                        >
                                            <CheckIcon/>
                                        </IconButton>
                                    </Grid>
                                </form>
                        }
                    </SectionPaper>
                </Grid>
            </div>
        )
    }
}

// Component Properties
CreateOrUpdateArea.propTypes = {
    areaDetail: PropTypes.func.isRequired,
    createOrUpdateArea: PropTypes.func.isRequired,
    messageShow: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}

export default connect(null, { areaDetail, createOrUpdateArea, messageShow })(withStyles(styles)(CreateOrUpdateArea))
