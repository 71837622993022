// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'


export function getEvents() {
    return dispatch => {
    return axios.get(API_URL+"/api/events")
}
}

export function getEventsById({id}) {
    return dispatch => {
    return axios.post(API_URL+"/api/events",{_id:id})
}
}