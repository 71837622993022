// Imports
import Button from "@mui/material/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconCloudDownload from "@material-ui/icons/CloudDownload";
// UI Imports
// import { DatePicker } from "material-ui-pickers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// App Imports
import params from "../../../setup/config/params";
import { list as listCategory } from "../../category/api/actions/query";
import { messageShow } from "../../common/api/actions";
import Section from "../../common/Section";
import SectionPaper from "../../common/SectionPaper";
import { list as listPincodes } from "../../pincode/api/actions/query";
import {
  barChartCalculation,
  pieChartCalculation,
  productReportStockCollection,
  reportOrderCollection,
  reportOrderPrepaidDeliveriesToday,
  customerWalletReport,
  productPurchaseReport,
} from "../api/actions/query";
// import BarChart from '../Charts/BarChart'
// import PieChart from '../Charts/PieChart'
import styles from "./styles";

// ---- Latest UI Library imports
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

// Component
class Reports extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      // startDateStock: new Date(),
      // endDateStock: new Date(),
      startDateForWallet: new Date(),
      endDateForWallet: new Date(),
      startDateForPurchase: new Date(),
      endDateForPurchase: new Date(),
      pincodeId: "all",
      categoryId: "all",
      paymentType: "all",
      pieData: [],
      barData: [],
    };
  }

  componentDidMount() {
    const { listPincodes, listCategory } = this.props;

    listPincodes();
    listCategory();
    this.pieChartLoad();
    this.barChartLoad();
  }

  barChartLoad = async () => {
    const { barChartCalculation } = this.props;
    const { startDate, endDate, pincodeId, paymentType } = this.state;

    try {
      const { data } = await barChartCalculation({
        startDate,
        endDate,
        pincodeId,
        paymentType,
      });

      if (data.success) {
        this.setState({
          barData: data.data,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  pieChartLoad = async () => {
    const { pieChartCalculation } = this.props;
    const { startDate, endDate, pincodeId, paymentType } = this.state;

    try {
      const { data } = await pieChartCalculation({
        startDate,
        endDate,
        pincodeId,
        paymentType,
      });
      if (data.success) {
        this.setState({
          pieData: data,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleDateChange = (date) => (value) => {
    this.setState({
      [date]: new Date(value),
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onOrderCollectionStatement = async () => {
    const { reportOrderCollection, messageShow } = this.props;
    const { startDate, endDate, pincodeId, paymentType } = this.state;
    try {
      const { data } = await reportOrderCollection({
        startDate,
        endDate,
        pincodeId,
        paymentType,
      });
      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  onStockReportStatement = async () => {
    const { productReportStockCollection, messageShow } = this.props;
    const { categoryId } = this.state;
    try {
      const { data } = await productReportStockCollection({
        categoryId,
      });

      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  onWalletReportStatement = async () => {
    const { customerWalletReport, messageShow } = this.props;
    const { startDateForWallet, endDateForWallet } = this.state;

    try {
      const { data } = await customerWalletReport({
        startDate: startDateForWallet,
        endDate: endDateForWallet,
      });

      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  onPurchaseReportStatement = async () => {
    const { productPurchaseReport, messageShow } = this.props;
    const { startDateForPurchase, endDateForPurchase } = this.state;

    try {
      const { data } = await productPurchaseReport({
        startDate: startDateForPurchase,
        endDate: endDateForPurchase,
      });

      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  onOrderPrepaidDeliveriesToday = async () => {
    const { reportOrderPrepaidDeliveriesToday, messageShow } = this.props;
    const { pincodeId } = this.state;

    try {
      const { data } = await reportOrderPrepaidDeliveriesToday({ pincodeId });

      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  render() {
    const { pincodes, categories, classes } = this.props;
    const {
      startDate,
      endDate,
      pincodeId,
      categoryId,
      paymentType
    } = this.state;
    return (
      <div>
        {/* Header */}
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Reports
          </Typography>
        </Toolbar>

        {/* Filter */}
        <SectionPaper padding>
          <Grid container spacing={10}>
            {/* Date start */}
            <Grid item>
              <DatePicker
                label="Start Date"
                value={dayjs(startDate)}
                slotProps={{ textField: { variant: "standard" } }}
                onChange={this.handleDateChange("startDate")}
              />
            </Grid>
            {/* Date end */}
            <Grid item>
              <DatePicker
                label="End Date"
                value={dayjs(endDate)}
                slotProps={{ textField: { variant: "standard" } }}
                minDate={dayjs(startDate)}
                onChange={this.handleDateChange("endDate")}
              />
            </Grid>

            {/* Pincode */}
            <Grid item>
              <FormControl style={{ minWidth: 150 }}>
                <InputLabel shrink htmlFor="pincode">
                  Pincode
                </InputLabel>
                <Select
                  value={pincodeId}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "pincodeId",
                    id: "pincode",
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {pincodes.list.map((pincode) => (
                    <MenuItem key={pincode._id} value={pincode._id}>
                      {pincode.pincode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Payment Mode */}
            <Grid item>
              <FormControl style={{ minWidth: 150 }}>
                <InputLabel shrink htmlFor="paymentType">
                  Payment Mode
                </InputLabel>
                <Select
                  value={paymentType}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "paymentType",
                    id: "paymentType",
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {Object.values(params.payment.types).map((paymentType) => (
                    <MenuItem key={paymentType.key} value={paymentType.key}>
                      {paymentType.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* Download buttons */}
          <Section style={{ padding: 0, paddingTop: 38 }}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.onOrderCollectionStatement}
                >
                  <IconCloudDownload style={{ marginRight: 10 }} />
                  Order Collection Statement
                </Button>
              </Grid>
              {/* <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.onOrderPrepaidDeliveriesToday}
                >
                  <IconCloudDownload style={{ marginRight: 10 }} />
                  Deliveries for Today
                </Button>
              </Grid> */}
            </Grid>
          </Section>
        </SectionPaper>

        <SectionPaper padding>
          <Grid container spacing={10}>
            {/* Date start */}
            {/* <Grid item>
              <DatePicker
                label="Start Date"
                value={dayjs(startDateStock)}
                slotProps={{ textField: { variant: "standard" } }}
                onChange={this.handleDateChange("startDateStock")}
              />
            </Grid> */}

            {/* Date end */}
            {/* <Grid item>
              <DatePicker
                label="End Date"
                value={dayjs(endDateStock)}
                minDate={dayjs(startDateStock)}
                slotProps={{ textField: { variant: "standard" } }}
                onChange={this.handleDateChange("endDateStock")}
              />
            </Grid> */}

            {/* Category */}
            <Grid item>
              <FormControl style={{ minWidth: 150 }}>
                <InputLabel shrink htmlFor="category">
                  Category
                </InputLabel>
                <Select
                  value={categoryId}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "categoryId",
                    id: "category",
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {categories.list.map((category) => 
                    category.subCategories.map((subCategory) => (
                    <MenuItem
                      key={subCategory._id}
                      value={subCategory._id}
                    >
                      {subCategory.name}
                    </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* Download buttons */}
          <Section style={{ padding: 0, paddingTop: 38 }}>
            <Grid container spacing={10}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.onStockReportStatement}
                >
                  <IconCloudDownload style={{ marginRight: 10 }} />
                  Stock Report
                </Button>
              </Grid>
            </Grid>
          </Section>
        </SectionPaper>

        {/* <SectionPaper padding>
          <Grid container spacing={10}>
            <Grid item>
              <DatePicker
                label="Start Date"
                value={dayjs(startDateForWallet)}
                slotProps={{ textField: { variant: "standard" } }}
                onChange={this.handleDateChange("startDateForWallet")}
              />
            </Grid>

            <Grid item>
              <DatePicker
                label="End Date"
                value={dayjs(endDateForWallet)}
                minDate={dayjs(startDateForWallet)}
                slotProps={{ textField: { variant: "standard" } }}
                onChange={this.handleDateChange("endDateForWallet")}
              />
            </Grid>
          </Grid>
          <Section style={{ padding: 0, paddingTop: 38 }}>
            <Grid container spacing={10}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.onWalletReportStatement}
                >
                  <IconCloudDownload style={{ marginRight: 10 }} />
                  Customer Wallet Report
                </Button>
              </Grid>
            </Grid>
          </Section>
        </SectionPaper> */}

        {/* <SectionPaper padding>
          <Grid container spacing={10}>
            <Grid item>
              <DatePicker
                label="Start Date"
                value={dayjs(startDateForPurchase)}
                slotProps={{ textField: { variant: "standard" } }}
                onChange={this.handleDateChange("startDateForPurchase")}
              />
            </Grid>

            <Grid item>
              <DatePicker
                label="End Date"
                value={dayjs(endDateForPurchase)}
                minDate={dayjs(startDateForPurchase)}
                slotProps={{ textField: { variant: "standard" } }}
                onChange={this.handleDateChange("endDateForPurchase")}
              />
            </Grid>
          </Grid>
          <Section style={{ padding: 0, paddingTop: 38 }}>
            <Grid container spacing={10}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.onPurchaseReportStatement}
                >
                  <IconCloudDownload style={{ marginRight: 10 }} />
                  Product Purchase Report
                </Button>
              </Grid>
            </Grid>
          </Section>
        </SectionPaper> */}

        {/* <Grid container jstify="space-around">
          <Grid item xs={6}>
            <PieChart pincodes={pincodes.list} pieData={pieData} />
          </Grid>
          <Grid item xs={6}>
            <BarChart barData={barData} />
          </Grid>
        </Grid> */}
      </div>
    );
  }
}

// Component Properties
Reports.propTypes = {
  pincodes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  listPincodes: PropTypes.func.isRequired,
  listCategory: PropTypes.func.isRequired,
  reportOrderCollection: PropTypes.func.isRequired,
  productReportStockCollection: PropTypes.func.isRequired,
  customerWalletReport: PropTypes.func.isRequired,
  productPurchaseReport: PropTypes.func.isRequired,
  pieChartCalculation: PropTypes.func.isRequired,
  barChartCalculation: PropTypes.func.isRequired,
  reportOrderPrepaidDeliveriesToday: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// Component State
function reportsState(state) {
  return {
    pincodes: state.pincodes,
    categories: state.categories,
  };
}

export default connect(reportsState, {
  listPincodes,
  listCategory,
  reportOrderCollection,
  productReportStockCollection,
  reportOrderPrepaidDeliveriesToday,
  pieChartCalculation,
  barChartCalculation,
  messageShow,
  customerWalletReport,
  productPurchaseReport,
})(withStyles(styles)(Reports));
