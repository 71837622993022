// Imports
import Button from '@mui/material/Button';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { messageShow, upload } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import PropTypes from 'prop-types';
import { getImageSource } from '../api/routes';
import ImageComp from '../../common/Image';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { nullToEmptyString } from '../../../setup/helpers';
import { remove } from '../api/actions/mutation';
import routes from '../api/routes';
import styles from './styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getGoal } from '../api/actions/query';
import { updateGoal } from '../api/actions/mutation';
import { getEvents } from "../../rewards/apiEvents/actions/query";

// UI Imports
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@mui/material/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import FileUpload from '@mui/icons-material/FileUpload';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import { Box, Chip } from '@mui/material';

// Component
class UpdateGoals extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      eventOptions: [],
      goal: {
        goalName: '',
        description: '',
        eventName: [],
        operationName: [],
        minOrderValue: '',
        startDate: dayjs(),
        expiryDate: dayjs(),
        startTime: dayjs().hour(0).minute(0),
        endTime: dayjs().hour(0).minute(0),
        rewardPoints: '',
        multiplierEvent: '',
        image: 'default.jpg',
      },
      startDateError: false,
    };
  }

  isFormValid = () => {
    const { goal } = this.state;

    // Check required fields
    const requiredFieldsFilled =
      goal.goalName.trim() &&
      goal.eventName.length > 0 &&
      goal.minOrderValue &&
      goal.rewardPoints;

    // Check date validity
    const validDates =
      goal.expiryDate.isValid() && !goal.expiryDate.isBefore(goal.startDate);

    return requiredFieldsFilled && validDates;
  };

  componentDidMount() {
    this.refresh();
    this.fetchEvents();
  }

  fetchEvents = async () => {
    const { getEvents } = this.props;
    try {
      const events = await getEvents();
      const eventOptions = events.data.data.map(event => ({
        eventName: event.eventName,
        operationName: event.newApiEndpoint || event.oldApiOperationName
      }));

      this.setState({ eventOptions });
    } catch (error) {
      this.props.messageShow("Failed to load events.");
    }
  };

  refresh = async () => {
    const { getGoal, isLoading, match: { params: { goalId } } } = this.props;
    this.isLoadingToggle(isLoading);

    try {
      const { data } = await getGoal({ id: goalId });
      if (data.success) {
        this.setState({
          goal: {
            ...data.data,
            startDate: dayjs(data.data.startDate),
            expiryDate: dayjs(data.data.expiryDate),
          },
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow('There was some error. Please try again.', error);
    } finally {
      this.isLoadingToggle(false);
    }
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({ isLoadingSubmit });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({ isUploadingFile });
  };

  handleDateChange = (key) => (date) => {
    const selectedDate = dayjs(date);
    const isError = selectedDate.isBefore(dayjs(), 'day');
    this.setState((prevState) => ({
      goal: {
        ...prevState.goal,
        [key]: dayjs(date),
      },
      startDateError: isError,
    }));
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle(true);

    const imgFile = event.target.files[0];
    const file = new FormData();
    file.append("type", "goals");
    file.append("name", this.state.goal.goalName.trim().replace(/\s+/g, '_'));
    file.append("file", imgFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);

    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        messageShow("File uploaded successfully.");

        const { goal } = this.state;

        setTimeout(() => {
          goal.image = data.file;
          this.setState({ goal });
        }, 1500);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      this.props.history.push(routes.listAllTabs.path, { activeTab: 'Goals' });
    } else {
      history.goBack();
    }
  }

  isLoadingToggle = (isLoading) => {
    this.setState({ isLoading });
  }

  onUpdate = async (event) => {
    event.preventDefault();

    const { updateGoal, messageShow } = this.props;
    const { goal } = this.state;

    const today = dayjs().startOf('day');
    if (goal.expiryDate.isBefore(today)) {
      messageShow("Expiry date must be today or a future date.");
      return;
    }
    // Date validation
    if (goal.expiryDate.isBefore(goal.startDate)) {
      messageShow("Expiry date must be equal to or greater than start date.");
      return;
    }

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await updateGoal(goal);
      this.isLoadingSubmitToggle(false);
      messageShow(data.message);

      if (data.success) {
        this.props.history.push(routes.listAllTabs.path, { activeTab: 'Goals' });
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);
      messageShow("Some error occurred. Please try again in update.", error);
    }
  };

  onType = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      goal: {
        ...prevState.goal,
        [name]: value,
      },
    }));
  };

  onTypeNumber = (event) => {
    const { name, value } = event.target;

    // Check if the entered value is a number
    if (!isNaN(value) && /^\d*$/.test(value)) {
      this.setState((prevState) => ({
        goal: {
          ...prevState.goal,
          [name]: value,
        },
      }));
    }
  };

  handleEventChange = (event) => {
    const { eventOptions } = this.state;
    const selectedEventNames = event.target.value;

    const selectedOperations = eventOptions.filter(option => selectedEventNames.includes(option.eventName)).map(option => option.operationName);
    this.setState(prevState => ({
      goal: {
        ...prevState.goal,
        eventName: selectedEventNames,
        operationName: selectedOperations
      }
    }));
  };


  render() {
    const { classes } = this.props;
    const { goal, startDateError, eventOptions } = this.state;
    const isDisabled = goal.isExpired;
    return (
      <SectionPaper>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit">
            Update Goals
          </Typography>
        </Toolbar>
        {goal.isExpired && (
          <SectionPaper padding style={{ color: 'red', marginLeft:'24px' }}>
          This goal is expired
          </SectionPaper>
        )}
        <Toolbar className={classes.toolbar}>
          <Grid item xs={12} lg={6}>
            <SectionPaper padding>
              <form onSubmit={this.onUpdate}>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"goalName"}
                    value={nullToEmptyString(goal.goalName)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Goal Name"}
                    placeholder={"Enter goal name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    autoFocus
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"description"}
                    value={nullToEmptyString(goal.description)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Description"}
                    placeholder={"Enter description"}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="event-name-label" required={true}>Event Name</InputLabel>
                      <Select
                        labelId="event-name-label"
                        multiple
                        name="eventName"
                        value={goal.eventName}
                        onChange={this.handleEventChange}
                        required={true}
                        margin="dense"
                        disabled={isDisabled}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', overflow: 'auto', whiteSpace: 'nowrap', gap: 0.5 }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                        {eventOptions.length > 0 ? (
                          eventOptions.map((event) => (
                            <MenuItem key={event.eventName} value={event.eventName}>
                              <ListItemText primary={event.eventName} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No events available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name="minOrderValue"
                      value={nullToEmptyString(goal.minOrderValue)}
                      variant="standard"
                      onChange={this.onTypeNumber}
                      label="Minimum Order Value"
                      placeholder={"Enter Minimum Order Value"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"multiplierEvent"}
                      value={nullToEmptyString(goal.multiplierEvent)}
                      variant="standard"
                      onChange={this.onTypeNumber}
                      label={"Multiplier event"}
                      placeholder={"Enter number of multiplier events"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"rewardPoints"}
                      value={nullToEmptyString(goal.rewardPoints)}
                      variant="standard"
                      onChange={this.onTypeNumber}
                      label={"Rewards points"}
                      placeholder={"Enter number of rewards points"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container spacing={2} style={{ marginBottom: 24 }}>
                  <Grid item xs={6}>
                    <DatePicker
                      required
                      label="Start Date"
                      value={dayjs(goal.startDate)}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          error: startDateError,
                          style: {
                            color: startDateError ? 'red' : 'inherit',
                          },
                        },
                      }}
                      onChange={this.handleDateChange('startDate')}
                      shouldDisableDate={(date) => date.isBefore(dayjs().startOf('day'))}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      required
                      value={dayjs(goal.expiryDate)}
                      label="Expiry Date"
                      slotProps={{ textField: { variant: "standard" } }}
                      onChange={this.handleDateChange('expiryDate')}
                      disabled={isDisabled}
                      // shouldDisableDate={(date) => {
                      //   const startDate = dayjs(goal.startDate);
                      //   return date.isBefore(dayjs().startOf('day')) || date.isBefore(startDate, 'day');
                      // }}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={10}
                    className={classes.buttonUpload}
                    alignItems="center"
                  >
                    <Grid item md={6}>
                      {!this.state.previewImage ? (
                          <ImageComp
                            src={getImageSource(`${goal.image}`)}
                            defaultSrc={getImageSource()}
                            size={"100%"}
                          />
                      ) : (
                        <img
                          height="auto"
                          width="100%"
                          src={this.state.previewImage}
                          alt="preview_goals"
                        />
                      )}
                    </Grid>
                    {!goal.isExpired && (
                    <Grid item md={6}>
                      <input
                        accept={"image/png,image/jpeg"}
                        style={{ display: "none" }}
                        id={"contained-button-file"}
                        type={"file"}
                        onChange={this.onUpload}
                      />

                      <label htmlFor={"contained-button-file"}>
                        <Button
                          variant={"outlined"}
                          component={"span"}
                          type={"file"}
                          fullWidth
                        >
                          <FileUpload
                            fontSize="16"
                            style={{ marginInlineEnd: 16 }}
                          />
                          Upload Image
                        </Button>
                      </label>
                    </Grid>)}
                  </Grid>

                </Grid>
                {!goal.isExpired && (
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Button
                    type={"button"}
                    aria-label={"Close"}
                    variant="outlined"
                    color="secondary"
                    onClick={this.back}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginInlineStart: 12 }}
                    type={"submit"}
                    aria-label={"Save"}
                    color={"primary"}
                    variant="contained"
                    disabled={!this.isFormValid()} // Disable if form is not valid
                  >
                    Update
                  </Button>
                </Grid>)}
              </form>

            </SectionPaper>
          </Grid>
        </Toolbar>
      </SectionPaper>
    );
  }
}

UpdateGoals.propTypes = {
  updateGoal: PropTypes.func.isRequired,
  getGoal: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

function listState(state) {
  return {
    categoriesParent: state.categoriesParent,
  };
}

export default connect(listState, {
  getGoal,
  remove,
  updateGoal,
  upload,
  messageShow,
  getEvents,
})(withStyles(styles)(UpdateGoals));


