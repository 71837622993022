import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllRatingFeedbacks, detail } from "./api/actions/query";
import { withStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import styles from "./styles";
import routes from './api/routes';
import { feedbackReply } from "./api/actions/mutation";
import SectionPaper from '../common/SectionPaper';
import Pagination from '../common/Pagination';
import EmptyMessage from '../common/EmptyMessage';
import ReplyIcon from '@mui/icons-material/Reply';
import Tooltip from '@material-ui/core/Tooltip';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import IconButton from "@material-ui/core/Icon";
import dayjs from 'dayjs';

class RatingFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbacks: [],
      count: 0,
      page: props.match.params.page || 1,
      open: false,
      selectedFeedback: null,
      message: "",
      sendOption: "notification",
      filter: "notReplied",
    };
  }

  componentDidMount() {
    this.fetchFeedbacks(this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.fetchFeedbacks(this.props.match.params.page);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = nextProps;
    this.setState(
      {
        page: match.params.page,
      },
      this.refresh
    );
  }
  fetchFeedbacks = async (page = 1) => {
    const { getAllRatingFeedbacks } = this.props;
    const { filter } = this.state; // Use filter state

    try {
      const response = await getAllRatingFeedbacks({ page, filter }); // Pass filter to API if applicable
      const { data } = response;
      if (data && Array.isArray(data.feedbacks)) {
        const sortedFeedbacks = data.feedbacks.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        this.setState({ feedbacks: sortedFeedbacks, count: data.count, page: data.page });
      } else {
        console.error("Data is not in expected format:", data);
      }
    } catch (error) {
      console.error("Error fetching feedbacks:", error);
    }
  };



  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.fetchFeedbacks();
    });
  };

  handleClickOpen = (feedback) => {
    this.setState({
      open: true,
      selectedFeedback: feedback,
      sendOption: feedback.email ? "email" : "notification",
    });
  };

  handleClose = () => {
    this.setState({ open: false, message: "", sendOption: "notification" });
  };

  handleSend = async () => {
    const { selectedFeedback, message, sendOption } = this.state;
    const payload = {
      feedbackId: selectedFeedback._id,
      message,
      sendOption,
    };

    if (selectedFeedback.userId) {
      payload.userId = selectedFeedback.userId._id;
    }

    if (sendOption === "email") {
      payload.email = selectedFeedback.email || null;
    }

    try {
      const response = await feedbackReply(payload);
      if (response.success) {
        alert("Reply sent successfully.");
        this.fetchFeedbacks(this.state.page);
      } else {
        alert(`Error: ${response.message}`);
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      alert("An error occurred while sending the reply.");
    }

    this.handleClose();
  };

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleSendOptionChange = (event) => {
    this.setState({ sendOption: event.target.value });
  };

  handleFilterChange = (filter) => {
    this.setState({ filter }, () => {
      this.fetchFeedbacks(this.state.page); // Fetch feedbacks with the new filter
    });
  };

  filterFeedbacks = () => {
    const { feedbacks, filter } = this.state;
    if (filter === "replied") {
      return feedbacks.filter((feedback) => feedback.reply === true);
    } else if (filter === "notReplied") {
      return feedbacks.filter((feedback) => feedback.reply === false);
    } else {
      return feedbacks;
    }
  };
  handlePageChange = (page) => {
    this.props.history.push(routes.ratingFeedback.path(page));
    this.fetchFeedbacks(page);
  };

  render() {
    const { classes } = this.props;
    const { open, message, sendOption, selectedFeedback, filter, count, page } = this.state;
    const filteredFeedbacks = this.filterFeedbacks();

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Rating Feedback
          </Typography>
          <div className={classes.filterIcons}>
            <Tooltip title="All" arrow>
              <IconButton
                color={filter === "all" ? "primary" : "default"}
                onClick={() => this.handleFilterChange("all")}
              >
                <AllInboxIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Replied" arrow>
              <IconButton
                color={filter === "replied" ? "primary" : "default"}
                onClick={() => this.handleFilterChange("replied")}
              >
                <MarkEmailReadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Not Replied" arrow>
              <IconButton
                color={filter === "notReplied" ? "primary" : "default"}
                onClick={() => this.handleFilterChange("notReplied")}
              >
                <MarkEmailUnreadIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <SectionPaper>
          {filteredFeedbacks.length === 0 ? (
            <EmptyMessage message={'No feedbacks to display'} />
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Rating</TableCell>
                    <TableCell>Feedback</TableCell>
                    <TableCell>Time Sent</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredFeedbacks.map((feedback) => (
                    <TableRow key={feedback._id}>
                      <TableCell>
                        {feedback.userId ? feedback.userId.name : "Unknown User"}
                      </TableCell>
                      <TableCell>
                        {feedback.email || "N/A"}
                      </TableCell>
                      <TableCell>{feedback.rating}</TableCell>
                      <TableCell>{feedback.feedback}</TableCell>
                      <TableCell>
                        {dayjs(feedback.createdAt).format('DD-MM-YYYY, hh:mm A')}
                      </TableCell>
                      <TableCell>
                        {(feedback.email ||
                          (feedback.userId && feedback.userId.name !== "Anonymous")) && (
                          <Tooltip title="Reply" arrow>
                            <Button
                              color="primary"
                              onClick={() => this.handleClickOpen(feedback)}
                              
                            >
                              <ReplyIcon />
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                count={count}
                route={routes.ratingFeedback}
                onPageChange={this.handlePageChange}
                currentPage={page}
              />
            </>
          
          )}
        </SectionPaper>
        <Dialog open={open} onClose={this.handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Reply to Feedback</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Message"
              type="text"
              fullWidth
              multiline
              minRows={4}
              value={message}
              onChange={this.handleMessageChange}
            />
            {selectedFeedback && !selectedFeedback.email && (
              <FormControl component="fieldset" style={{ marginTop: "16px" }}>
                <FormLabel component="legend">Send As</FormLabel>
                <RadioGroup
                  aria-label="sendOption"
                  name="sendOption"
                  value={sendOption}
                  onChange={this.handleSendOptionChange}
                >
                  <FormControlLabel
                    value="notification"
                    control={<Radio />}
                    label="In-App Notification"
                  />
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSend} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

RatingFeedback.propTypes = {
  getAllRatingFeedbacks: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  getAllRatingFeedbacks,
  detail,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(RatingFeedback));
