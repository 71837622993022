export const calculateForecastData = (data, forecast, currentPage = 1, itemsPerPage = 10) => {
  const dates = data.map(d => new Date(d.ds).getTime());
  const actuals = data.map(d => parseFloat(d.Actuals));
  const forecasts = data.map(d => parseFloat(d.Forecast));

  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedDates = dates.slice(startIndex, endIndex);
  const paginatedActuals = actuals.slice(startIndex, endIndex);
  const paginatedForecasts = forecasts.slice(startIndex, endIndex);

  const chartOptions = {
    chart: {
      type: 'line',
      height: 600
    },
    xaxis: {
      type: 'datetime',
      categories: paginatedDates,
      labels: {
        format: 'MMM dd yyyy'
      },
      tickAmount: 'dataPoints',
      scrollable: true
    },
    yaxis: {
      title: {
        text: (forecast === "Sales Forecast" ? "Sales Amount" : "Order")
      },
      labels: {
        formatter: function(val) {return Math.round(val)}
      },
      min: 0,
      tickAmount: Math.min(10, Math.ceil(Math.max(...data.map(d => parseFloat(d.Actuals))) / 10)),
      max: Math.ceil(Math.max(...data.map(d => parseFloat(d.Actuals))) / 10) * 10,
    },
    title: {
      text: forecast,
      align: 'center'
    },
    legend: {
      position: 'top'
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
        endingShape: 'rounded'
      },
    },
  };

  const chartSeries = [
    {
      name: 'Actuals',
      data: paginatedActuals
    },
    {
      name: 'Forecast',
      data: paginatedForecasts
    }
  ];

  const tableData = data.slice(startIndex, endIndex).map(d => ({
    date: new Date(d.ds).toLocaleDateString(),
    actuals: parseFloat(d.Actuals).toFixed(2),
    forecast: parseFloat(d.Forecast).toFixed(2),
    predictionType: d.PredictionType
  }));

  return { chartOptions, chartSeries, tableData, totalPages };
};