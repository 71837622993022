// App Imports
import { API_URL, IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import List from '../List'
import CreateOrUpdate from '../CreateOrUpdate'
import { defaultImage } from '../../common/Sidebar';
// Image
export function imageBanner(image = defaultImage) {
  return `${ API_URL }/${ params.banner.image.path }/${ image }`
}

export function getImageSource(image = defaultImage) {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
const routes = {
  bannerList: {
    path: '/banner',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },

  bannerCreate: {
    path: '/banner/create',
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  },

  bannerEdit: {
    path: (bannerId = ':bannerId') => (`/banner/edit/${ bannerId }`),
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  }
}
export default routes;