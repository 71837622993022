// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import dayjs from 'dayjs';
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
// import params from '../../../setup/config/params'
import { messageShow } from '../../common/api/actions'
import EmptyMessage from '../../common/EmptyMessage'
import Loading from '../../common/Loading'
import Pagination from '../../common/Pagination'
import SectionPaper from '../../common/SectionPaper'
import { remove } from '../api/actions/mutation'
import { getFeedbackList } from '../api/actions/query'
import routes from '../api/routes'
import styles from './styles'



// Component
class List extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      feedbacks: [],
      count: 0
    }
  }

  componentDidMount() {
    const { match } = this.props

    this.refresh(match.params.page)()
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps

    this.refresh(match.params.page)()
  }

  refresh = (page = 1, isLoading = true) => async () => {
    const { getFeedbackList, messageShow } = this.props

    this.isLoadingToggle(isLoading)

    try {
      const { data } = await getFeedbackList({ page })

      if (data.success) {
        this.setState({
          feedbacks: data.data,
          // count: data.data.count
        })
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  onDelete = (feedbackId) => async () => {
    let check = window.confirm('Are you sure you want to delete this feedback message?')

    if (check) {
      const { remove, messageShow, match } = this.props

      try {
        const { data } = await remove({ feedbackId })

        messageShow(data.message)

        if (data.success) {
          this.refresh(match.params.page)()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  render() {
    const { classes } = this.props
    const { isLoading, feedbacks, count } = this.state

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Feedback Message
          </Typography>

          <Link to={routes.createFeedback.path}>
            <Button color="inherit">Create</Button>
          </Link>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : feedbacks.length === 0
                ? <EmptyMessage message={'No feedbacks to show.'} />
                : <>
                  <Table padding={"normal"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Feedback</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                        feedbacks.map(({ _id, title, feedback, createdAt }) =>
                          <TableRow key={_id}>
                            <TableCell>{title}</TableCell>
                            <TableCell>{feedback}</TableCell>
                            <TableCell>{dayjs(createdAt).format('DD-MM-YYYY, hh:mm A')}</TableCell>
                            <TableCell align="left">
                              <Button component={Link} to={routes.feedbackEdit.path(_id)}>Reply</Button>
                              <Button onClick={this.onDelete(_id)}>Delete</Button>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>

                  <Pagination
                    count={count}
                    route={routes.feedbacks}
                  />
                </>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  getFeedbackList: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default connect(null, { getFeedbackList, messageShow, remove })(withStyles(styles)(List))
