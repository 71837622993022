// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Get detail
export function detail({vendorId}) {
  return dispatch => {
    return axios.post(`${API_URL}/api/setting`,{
      "vendorId": vendorId
    })
  }
}

// Get All Country Codes
export function getAllCountryCodes() {
  return dispatch => {
    return axios.get(`${API_URL}/api/countryCode`)
  }
}

// Get All Currencies
export function getAllCurrencies() {
  return dispatch => {
    return axios.get(`${API_URL}/api/currency`)
  }
}


export function settingDetail() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'settingDetail'
    })
  }
}