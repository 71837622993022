// App Imports
import { LIST_BY_PARENT_DONE, LIST_BY_PARENT_REQUEST, LIST_BY_PARENT_RESET, LIST_BY_PARENT_RESPONSE } from '../actions/types'

// List

// Initial State
const categoriesByParentInitialState = {
  isLoading: false,
  list: []
}

// State
const listByParent = (state = categoriesByParentInitialState, action) => {
  switch (action.type) {
    case LIST_BY_PARENT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      }

    case LIST_BY_PARENT_RESPONSE:
      return {
        ...state,
        list: action.list
      }

    case LIST_BY_PARENT_DONE:
      return {
        ...state,
        isLoading: false
      }

    case LIST_BY_PARENT_RESET:
      return { ...categoriesByParentInitialState }

    default:
      return state
  }
}
export default listByParent;