// UI Imports
import {
  Grid,
  IconButton,
  Checkbox,
  TextField,
  Toolbar,
  Typography,
  withStyles
} from '@material-ui/core'
import { Autocomplete } from "@material-ui/lab";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  ArrowBack as IconArrowBack,
  Check as IconCheck,
  Close as IconClose
} from '@material-ui/icons';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import { nullToEmptyString } from '../../../setup/helpers'
import { messageShow, upload } from '../../common/api/actions'
import Loading from '../../common/Loading'
import { list as listPincodes } from '../../pincode/api/actions/query'
import SectionPaper from '../../common/SectionPaper'
import { createOrUpdate } from '../api/actions/mutation'
import { detail, listParent } from '../api/actions/query'
import routes from '../api/routes'
import styles from './styles'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



// Component
class CreateOrUpdate extends Component {

  constructor(props) {
    super(props)

    this.deliveryBoy = {
      name: '',
      number: '',
      pincodeIdList: []
    }

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      pincodeId: 'all',

      deliveryBoy: this.deliveryBoy
    }
  }


  componentDidMount() {
    const { match: { params: { deliveryBoyId } }, listPincodes } = this.props

    listPincodes()

    if (deliveryBoyId) {
      this.getDeliveryBoy(deliveryBoyId)
    }
  }

  getDeliveryBoy = async (deliveryBoyId) => {
    const { detail } = this.props

    this.isLoadingToggle(true)

    try {
      const { data } = await detail({ deliveryBoyId })

      if (data.success) {
        this.setState({
          deliveryBoy: data.data
        })
      }
    } catch (error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  onCreateOrUpdate = async event => {
    event.preventDefault()

    const { createOrUpdate, messageShow, history } = this.props

    const { deliveryBoy } = this.state

    this.isLoadingSubmitToggle(true)

    try {
      const { data } = await createOrUpdate({ deliveryBoy })

      this.isLoadingSubmitToggle(false)

      messageShow(data.message)

      if (data.success) {
        history.push(deliveryBoy.parentId ? routes.deliveryBoyManage.path(deliveryBoy.parentId) : routes.deliveryBoyList.path)
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false)

      messageShow('Some error occurred. Please try again.')
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  isLoadingSubmitToggle = isLoadingSubmit => {
    this.setState({
      isLoadingSubmit
    })
  }

  isUploadingFileToggle = isUploadingFile => {
    this.setState({
      isUploadingFile
    })
  }

  onType = ({ target: { name, value } }) => {
    this.setState({
      deliveryBoy: {
        ...this.state.deliveryBoy,
        [name]: value
      }
    })
  }

  onSelect = (e, newValues) => {
    let pincodeList = [];
    if (newValues && newValues.length) {
      newValues.forEach(newValue => {
        pincodeList.push(newValue._id);
      });
    }
    this.setState({
      deliveryBoy: {
        ...this.state.deliveryBoy,
        pincodeIdList: pincodeList
      },
    })
  }

  back = () => {
    const { history } = this.props

    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(routes.deliveryBoyList.path)
    }
  }

  getDefaultList = () => {
    let defaultList = [];
    let { pincodes } = this.props;
    let { deliveryBoy } = this.state;
    debugger;
    defaultList = pincodes ? pincodes.list.filter((pincode) => {
      return deliveryBoy.pincodeIdList.find(id => id === pincode._id);
    }) : [];
    return defaultList;
  }

  render() {
    const { match: { params: { deliveryBoyId } }, pincodes, classes } = this.props
    const { deliveryBoy, isLoading, isLoadingSubmit } = this.state

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            {deliveryBoyId ? 'Edit' : 'Create'} Delivery Boy
          </Typography>
        </Toolbar>

        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            {
              isLoading
                ? <Loading />
                : <form onSubmit={this.onCreateOrUpdate}>
                  {/* Input - Name */}
                  <Grid item xs={12}>
                    <TextField
                      name={'name'}
                      value={nullToEmptyString(deliveryBoy.name)}
                      onChange={this.onType}
                      label={'Name'}
                      placeholder={'Enter Delivery Boy name'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  {/* Input - Phone Number */}
                  <Grid item xs={12}>
                    <TextField
                      name={'number'}
                      value={nullToEmptyString(deliveryBoy.number)}
                      onChange={this.onType}
                      label={'Number'}
                      type="number"
                      placeholder={'Enter Delivery Boy Contact Number'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                    />
                  </Grid>
                  {/* Pincode */}
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={pincodes.list}
                      onChange={this.onSelect}
                      disableCloseOnSelect
                      defaultValue={this.getDefaultList()}
                      getOptionLabel={(option) => option.pincode}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.pincode}
                        </React.Fragment>
                      )}
                      // style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} required={deliveryBoy.pincodeIdList.length === 0} label="Pincode" placeholder="Pincode" />
                      )}
                    />
                  </Grid>

                  {/* Button - Save */}
                  <Grid item xs={12} className={classes.buttonsContainer}>
                    <Link to={routes.deliveryBoyList.path}>
                      <IconButton
                        type={'button'}
                        aria-label={'Close'}
                      >
                        <IconClose />
                      </IconButton>
                    </Link>

                    <IconButton
                      type={'submit'}
                      aria-label={'Save'}
                      color={'primary'}
                      disabled={isLoadingSubmit}
                    >
                      <IconCheck />
                    </IconButton>
                  </Grid>
                </form>
            }
          </SectionPaper>
        </Grid>
      </div >
    )
  }
}

// Component Properties
CreateOrUpdate.propTypes = {
  pincodes: PropTypes.object.isRequired,
  listPincodes: PropTypes.func.isRequired,
  deliveriesBoyParent: PropTypes.object.isRequired,
  listParent: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function createOrEditState(state) {
  return {
    pincodes: state.pincodes,
    deliveriesBoyParent: state.deliveriesBoyParent
  }
}

export default connect(createOrEditState, { listPincodes, listParent, detail, createOrUpdate, upload, messageShow })(withStyles(styles)(CreateOrUpdate))
