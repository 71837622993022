import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Box } from "@mui/material";
import { Delete, DeleteSweep, ArrowBack } from "@mui/icons-material";
import { getBouncedEmails, getSpamReport } from "../api/action/query";
import { deleteSpamByEmail, deleteBouncedByEmail, deleteAllSpam, deleteAllBounced } from "../api/action/mutation";
import EmptyMessage from "../../common/EmptyMessage";
import Loading from "../../common/Loading";
import { ReasonTableCell, EmailTableCell, ActionTableCell } from "./tableCells";
import { useDispatch } from "react-redux";
import { messageShow } from "../../common/api/actions";
import { useHistory } from "react-router-dom";

const EmailReportTemplate = ({ type }) => {
    const [emailData, setEmailData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const fetchEmails = async () => {
        try {
            setLoading(true);
            const response = type === 'bounced' ? await getBouncedEmails() : await getSpamReport();
            setEmailData(response);
        } catch (error) {
            dispatch(messageShow(error.toString()))
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmails();
    }, [type]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString();
    };

    const handleDelete = async (email) => {
        try {
            if (type === 'bounced') {
                await deleteBouncedByEmail(email);
            } else {
                await deleteSpamByEmail(email);
            }
            setEmailData(prevData => prevData.filter(item => item.email !== email));
            dispatch(messageShow(`${type === 'bounced' ? 'Bounced' : 'Spam'} email deleted successfully`));
        } catch (error) {
            console.error(`Error deleting ${type} email:`, error);
            dispatch(messageShow(error.toString()));
        }
    };

    const handleDeleteAll = async () => {
        try {
            if (window.confirm(`Are you sure you want to delete all ${type} emails?`)) {
                if (type === 'bounced') {
                    await deleteAllBounced();
                } else {
                    await deleteAllSpam();
                }
                setEmailData([]);
                dispatch(messageShow(`All ${type} emails deleted successfully`));
            }
        } catch (error) {
            console.error(`Error deleting all ${type} emails:`, error);
            dispatch(messageShow(error.toString()));
        }
    };

    const tableHead = useMemo(() => (
        <TableHead>
            <TableRow>
                <TableCell>Date</TableCell>
                <EmailTableCell>Email</EmailTableCell>
                {type === "bounced" && <ReasonTableCell>Reason</ReasonTableCell>}
                <ActionTableCell>Action</ActionTableCell>
            </TableRow>
        </TableHead>
    ), [type]);

    if (isLoading) return <Loading />;

    return (
        <>  
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center">
                    <IconButton
                        color="primary"
                        onClick={() => history.push("/messaging")}
                        aria-label="back to messaging"
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" style={{ marginLeft: '10px' }}>
                        {type === 'bounced' ? 'Bounced Emails' : 'Spam Reports'}
                    </Typography>
                </Box>
                {emailData && Array.isArray(emailData) && emailData.length > 0 && (
                    <Tooltip title="Delete All">
                        <IconButton
                            aria-label="delete all"
                            size="large"
                            onClick={handleDeleteAll}
                            style={{ marginRight: '10px' }}
                        >
                            <DeleteSweep color="error" size="large"/>
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            {!emailData || !Array.isArray(emailData) || emailData.length === 0 ? (
                <EmptyMessage message={`No ${type} emails to show.`} />
            ) : (
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label={`${type} emails table`}>
                        {tableHead}
                        <TableBody>
                            {emailData.map((email, index) => (
                                <TableRow key={index}>
                                    <TableCell>{formatTimestamp(email.created)}</TableCell>
                                    <EmailTableCell component="th" scope="row">
                                        {email.email}
                                    </EmailTableCell>
                                    {type === "bounced" && <ReasonTableCell>{email.reason}</ReasonTableCell>}
                                    <ActionTableCell>
                                        <Tooltip title="Delete">
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                onClick={() => handleDelete(email.email)}
                                            >
                                                <Delete color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </ActionTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default EmailReportTemplate;
