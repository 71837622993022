// App Imports
import { API_URL, IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import Login from '../Login'
import Signup from '../Signup'
import Detail from '../Profile/Detail'
import Dashboard from '../Dashboard'
import Reports from '../Reports'
import Update from '../Profile/Update'
import ForgotPassword from '../ForgotPassword'
import ResetPassword from '../ResetPassword.js'

import { defaultImage } from '../../common/Sidebar';
/*// Image
export function imageUser(image = defaultImage) {
  return `${ API_URL }/${ params.user.image.path }/${ image }`
}

export function getImageSource(image = defaultImage) {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}*/
// Image
export function imageUser(image = defaultImage) {
  // Return a path only if an image is provided, otherwise return an empty string or null
  return image ? `${API_URL}/${params.user.image.path}/${image}` : '';
}

// export function getImageSource(image) {
//   // Same here: only return a path if an image is provided
//   return image ? `${IMAGE_SOURCE_PATH}${image}` : '';
// }

export function getImageSource(image = defaultImage) {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
const routes = {
  userLogin: {
    path: '/login',
    component: Login,
  },
  userSignup: {
    path: '/signup',
    component: Signup,
  },
  forgotPassword: {
    path: '/forgotpassword',
    component: ForgotPassword,
  },
  resetPassword: {
    path: '/resetpassword',
    component: ResetPassword,
  },
  userProfile: {
    path: '/profile',
    component: Detail,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key],
  },
  userDashboard: {
    path: '/dashboard',
    component: Dashboard,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key],
  },
  userReports: {
    path: '/reports',
    component: Reports,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key],
  },
  profileUpdate: {
    path: '/update',
    component: Update,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key],
  },
};

export default routes;
