import params from "../../../setup/config/params";
import Marketing from "../index";
import CreateEdit from "../CreateEdit";
import ViewEvent from '../components/ViewEvent.js'
import { IMAGE_SOURCE_PATH } from "../../../setup/config/env";
import { defaultImage } from '../../common/Sidebar';

export function getImageSource(image = defaultImage) {
    return `${IMAGE_SOURCE_PATH}${image}`;
}

const routes = {
    messaging: {
        path: "/messaging",
        component: Marketing,
        auth: true,
        role: params.user.roles.admin.key,
    },
    editEvent: {
        path: (type, id) => `/messaging/${type}/edit/${id}`,
        component: CreateEdit,
        auth: true,
        role: params.user.roles.admin.key,
    },
    viewEvent: {
        path: (type, id) => `/messaging/${type}/view/${id}`,
        component: ViewEvent,
        auth: true,
        role: params.user.roles.admin.key,
    }
};

export default routes;