import React, { useEffect, useState } from 'react'
import { fetchAnalyticsData } from '../api/action/query'
import Loading from "../../common/Loading"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Collapse, Pagination, Tooltip, IconButton } from '@mui/material'
import { ExpandMore, ExpandLess, PlayCircleOutline } from '@mui/icons-material'
import { messageShow } from '../../common/api/actions'
import { useDispatch } from 'react-redux'

const Recommendation = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        three_item_combinations: [],
        two_item_recommendations: []
    })
    const [isLoading, setIsLoading] = useState(true)
    const [showTwo, setShowTwo] = useState(false)
    const [pageThree, setPageThree] = useState(1)
    const [pageTwo, setPageTwo] = useState(1)
    const rowsPerPage = 8

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await fetchAnalyticsData(4)
                setData(response.data||{three_item_combinations: [],two_item_recommendations: []})
            } catch (error) {
                console.error("Error fetching recommendation data:", error)
                setData({three_item_combinations: [],two_item_recommendations: []})
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [])

    if (isLoading) return <Loading />

    const handleChangePageThree = (event, newPage) => setPageThree(newPage)
    const handleChangePageTwo = (event, newPage) => setPageTwo(newPage)
    const startThree = (pageThree-1)*rowsPerPage
    const endThree = startThree+rowsPerPage
    const startTwo = (pageTwo-1)*rowsPerPage
    const endTwo = startTwo+rowsPerPage

    const handleClick = async () => {
        try {
            // const response = await itemRecommendation();
            // if (response.status === "success") {
            //     dispatch(messageShow("Item recommendation run completed"));
            // } else {
            //     dispatch(messageShow("Error in item recommendation"));
            // }
            dispatch(messageShow("Item recommendation yet to be implemented"));
        } catch (error) {
            console.error("Error executing item recommendation:", error);
        }
    }

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography 
                    variant="h6" 
                    color="primary" 
                    onClick={() => setShowTwo(!showTwo)}
                    style={{ marginBottom: '20px', cursor: 'pointer' }}
                >
                    {showTwo ? 'Show Three items combo':'Show Two items combo'}
                    {showTwo ? <ExpandLess /> : <ExpandMore />}
                </Typography>
                <Tooltip title="Run">
                    <IconButton
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        <PlayCircleOutline fontSize="medium"/>
                    </IconButton>
                </Tooltip>
            </div>

            <Collapse in={!showTwo}>
                <Typography variant="h6" gutterBottom>Triples</Typography>
                {data.three_item_combinations.length > 0 ? (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Item 1</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Item 2</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Item 3</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Predicted Count</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.three_item_combinations.slice(startThree, endThree).map((combination, index) => {
                                    const items = combination[0]
                                    const times = combination[1]

                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{items[0]}</TableCell>
                                            <TableCell>{items[1]}</TableCell>
                                            <TableCell>{items[2]}</TableCell>
                                            <TableCell>{times}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        <Pagination
                            count={Math.ceil(data.three_item_combinations.length / rowsPerPage)}
                            page={pageThree}
                            onChange={handleChangePageThree}
                            color="primary"
                            style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' , paddingBottom: '20px'}}
                        />
                    </>
                ):<>No data available for Triples</>}
            </Collapse>

            <Collapse in={showTwo}>
                <Typography variant="h6" gutterBottom>Combos</Typography>
                {data.two_item_recommendations.length>0?(
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Item 1</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Item 2</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Predicted Count</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.two_item_recommendations.slice(startTwo, endTwo).map((recommendation, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{recommendation[0]}</TableCell>
                                        <TableCell>{recommendation[1]}</TableCell>
                                        <TableCell>{recommendation[2]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Pagination
                            count={Math.ceil(data.two_item_recommendations.length / rowsPerPage)}
                            page={pageTwo}
                            onChange={handleChangePageTwo}
                            color="primary"
                            style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' , paddingBottom: '20px'}}
                        />
                    </>
                ):<>No data available for Combos</>}
            </Collapse>
        </div>
    )
}

export default Recommendation