// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Get list
export function list({ page, filter, listAll }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productList',
      params: { page, filter, listAll }
    })
  }
}

// Get list featured
export function listFeatured() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productListFeatured'
    })
  }
}

// Get detail
export function details({ productId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productDetail',
      params: { productId }
    })
  }
}

// Report Order Collection Statement
export function productCollection({ products }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productReportCollection',
      params: { products }
    })
  }
}

// Report Order Collection Statement
export function productCollectionAll({ productsAll }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productReportCollectionAll',
      params: { productsAll }
    })
  }
}

// Get count
export function getCount() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productCount'
    })
  }
}
