// App Imports
import { IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import List from '../List'
import CreateOrUpdate from '../CreateOrUpdate'
import BulkCreate from '../BulkCreate';
import { defaultImage } from '../../common/Sidebar';

// Image

export function getImageSource(image = defaultImage) {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
const routes = {
  productList: {
    path: (page = ':page') => `/products/${ page }`,
    activePath: '/product',
    component: List,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key]
  },

  productCreate: {
    path: '/product/create',
    component: CreateOrUpdate,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key]
  },
  productBulkCreate: {
    path: '/product/bulkcreate',
    component: BulkCreate,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key]
  },

  productEdit: {
    path: (productId = ':productId') => (`/product/edit/${ productId }`),
    component: CreateOrUpdate,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key]
  }
}

export default routes;