import React, { useState } from 'react';
import useEvents from '../hooks/useEvents';
import EventCard from './EventCard';
import Loading from "../../common/Loading";
import EmptyMessage from '../../common/EmptyMessage';
import { Table,TableBody,TableHead,TableRow,TableCell, Checkbox} from "@mui/material";
import { NameTableCell, MessageTableCell, DateTableCell, StatusTableCell, ActionTableCell } from "./tableCells";

const Events = ({ eventType, refresh, newEvent, inProgressEventId, setInProgressEventId, selectedEvents, setSelectedEvents }) => {
    const { events, isLoading, handleDeleteEvent, refreshFunction, sendFunction, handelCancel, fetchEvents, updateEvent } = useEvents(eventType, refresh, newEvent, inProgressEventId, setInProgressEventId);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        setSelectedEvents(isChecked ? events.map(event => event._id) : []);
    };

    const sortedEvents = events.sort((a, b) => {
        if (a.status==='Yet to Send' && b.status!=='Yet to Send') return -1;
        if (a.status!=='Yet to Send' && b.status==='Yet to Send') return 1;
        return new Date(b.date) - new Date(a.date);
    });

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : events.length === 0 ? (
                <EmptyMessage message={`No ${eventType} to show.`} />
            ) : (
                <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox checked={selectAll} onChange={handleSelectAll}/>
                                </TableCell>
                                <NameTableCell>Name</NameTableCell>
                                <MessageTableCell>Message</MessageTableCell>
                                <DateTableCell>Date</DateTableCell>
                                <StatusTableCell>Status</StatusTableCell>
                                <ActionTableCell>Actions</ActionTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedEvents.map((event) => (
                                <EventCard
                                    key={event._id}
                                    event={event}
                                    handleDelete={handleDeleteEvent}
                                    inProgressEventId={inProgressEventId}
                                    setInProgressEventId={setInProgressEventId}
                                    sendFunction={sendFunction}
                                    refreshFunction={refreshFunction}
                                    handelCancel={handelCancel}
                                    eventType={eventType}
                                    fetchEvents={fetchEvents}
                                    updateEvent={updateEvent}
                                    selectedEvents={selectedEvents}
                                    setSelectedEvents={setSelectedEvents}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default Events;