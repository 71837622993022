// App Imports
import { API_URL, IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import Save from '../Save'

import { defaultImage } from '../../common/Sidebar';
// Image
export function imageSettings(image = defaultImage) {
  return `${ API_URL }/${ params.settings.image.path }/${ image }`
}

export function getImageSource(image = defaultImage) {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
const routes = {
  settings: {
    path: (vendorId = ':vendorId') => `/settings/${vendorId}`,
    component: Save,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key,
  },
};

export default routes;
